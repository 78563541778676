import React from 'react';
import { useWordData } from 'src/hook/data/useWordData';
import { useWordTranslate } from 'src/hook/data/useWordTranslate';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import Modal from '../../Features/Modal/Modal';
import ImageSection from '../ImageSection/ImageSection';
import VolumeSection from '../VolumeSection/Volumesection';
import styles from './WordModal.module.scss';


interface WordModalProps {
    isOpen: boolean;
    onClose: () => void;
    word: Word;
    hasButton: boolean;
}

export const WordModal: React.FC<WordModalProps> = ({ isOpen, onClose, word, hasButton }) => {
    const { wordData } = useWordData(word);
    const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);
    const { getTranslateBySenseId } = useWordTranslate();
    const { open } = useComponentModal();


    if (!word) return null;
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.modalContent}>
                <div className={styles.imageSection}>
                    <ImageSection word={word} hasClose={true} hasRank={false} close={onClose} />
                </div>
                <div className={styles.title}>
                    <h1>{word.Text}</h1>
                    <span>{primarySense?.ty}</span>
                </div>
                <VolumeSection word={word} />
                <p className={styles.translate}>{getTranslateBySenseId(word.PrimarySenseId)}</p>
                <p className={styles.meaning}>{word.PrimaryMeaning}</p>
                {hasButton && <div className={styles.buttonSection}>
                    <button className='secondaryButton'
                        onClick={() => {
                            open('Definition', { word }, AnimationType.SlideUp);
                            onClose();
                        }}
                    >
                        Look up
                    </button>
                    <button onClick={onClose}>
                        Got it!
                    </button>
                </div>
                }
            </div>
        </Modal >
    );
};