import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWordList } from 'src/hook/data/useWordList';
import usePopUp from 'src/hook/modal/usePopUp';
import { WordList } from 'src/model/WordList';
import styles from './CustomListForm.module.scss';

export const CustomListForm = () => {
    const [listName, setListName] = useState('');
    const [currentList, setCurrentList] = useState<WordList | null>(null);
    const navigate = useNavigate();
    const { createCustomList, learningInfo, removeCustomList, editCustomList } = useWordList();
    const [searchParams] = useSearchParams();
    const listId = searchParams.get('id');
    const isEdit = listId !== null;
    const popup = usePopUp();


    const handleSubmit = () => {

        // if (learningInfo?.WordLists.find(list => list.Title === listName.trim())) {
        //     show({
        //         title: "Name?",
        //         message: "Please provide a proper name for your word list.",
        //     });
        // } else {
        //     const newList = createNewWordList(listName.trim());
        //     navigate(`/wordlists/add-words?id=${encodeURIComponent(newList?.ID || '')}`);
        // }

        if (isEdit) {
            if (!currentList) return;
            editCustomList(currentList, listName);
            navigate(`/wordlists/wordlist?id=${encodeURIComponent(currentList?.ID || '')}`);
        } else {
            const newList = createCustomList(listName.trim());
            navigate(`/wordlists/add-words?id=${encodeURIComponent(newList?.ID || '')}`);
        }

    };

    const handleRemove = () => {
        if (!currentList) return;
        removeCustomList(currentList);
        navigate('/wordlists');
    }


    const handleRemoveList = () => {
        if (!currentList) return;
        popup.show({
            title: 'Please confirm',
            message: 'Are you sure you want to remove this list?',
            primaryButtonText: 'Confirm',
            primaryButtonClick: () => handleRemove(),
            showSecondaryButton: true
        });




    }

    useEffect(() => {
        if (isEdit) {
            const list = learningInfo?.WordLists.find(list => list.ID === listId);
            if (list) {
                setCurrentList(list);
                setListName(list.Title);
            }
        }

    }, [isEdit, learningInfo?.WordLists]);

    return (
        <div className={styles.customListForm}>
            <div className={styles.inputContainer}>
                <label htmlFor="listName" className={styles.label}>
                    List Name
                </label>
                <input
                    id="listName"
                    type="text"
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    className={styles.input}
                    placeholder="Enter list name"
                    required
                />
            </div>
            <div className={styles.buttonContainer}>
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className={styles.continueButton}
                    disabled={!listName.trim()}

                >
                    {isEdit ? 'Save' : 'Continue'}
                </button>
                <button onClick={handleRemoveList}
                    className={`redButton ${isEdit ? '' : styles.hidden}`}>
                    Remove List</button>
            </div>
        </div>

    );
};
