import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWordData } from 'src/hook/data/useWordData';
import { useWordList } from 'src/hook/data/useWordList';
import { useWordTranslate } from 'src/hook/data/useWordTranslate';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import { WordList } from 'src/model/WordList';
import { DirectIconLocation, SenseImageLocation } from 'src/utils/URLUtils';
import { ButtonSection } from './ButtonSection/ButtonSection';
import styles from './WordCard.module.scss';

interface WordCardProps {
    word: Word;
    senseId?: string;
    removeMode?: boolean;
    wordList?: WordList;
    showTranslate?: boolean;
}

export const WordCard = ({ word, senseId, removeMode, wordList, showTranslate }: WordCardProps) => {
    const { open } = useComponentModal();
    const { fetchWordData } = useWordData(word, false);
    const [primaryMeaning, setPrimaryMeaning] = useState<string>("");
    const [translateDefinition, setTranslateDefinition] = useState<string>("");
    const { removeWordFromCustomList, removeCustomList } = useWordList();
    const navigate = useNavigate();
    const { getTranslateBySenseId } = useWordTranslate();


    const openDefinition = () => {
        open('Definition', { word }, AnimationType.SlideUp);
    };

    const removeWord = () => {
        if (!wordList) return;
        if (wordList.CustomListData.length > 1) {
            removeWordFromCustomList(wordList, word, senseId);
        }
        else {
            removeCustomList(wordList);
            navigate('/wordlists');
        }
    }


    useEffect(() => {
        const FetchData = async () => {
            if (senseId) {
                const wordData = await fetchWordData(word);
                setPrimaryMeaning(wordData?.Senses.find(sense => sense.id === senseId)?.de || "");
            }
            else {
                setPrimaryMeaning(word.PrimaryMeaning);
            }
            setTranslateDefinition(getTranslateBySenseId(senseId || word.PrimarySenseId));
        }
        FetchData();
    }, [senseId, word, fetchWordData, getTranslateBySenseId]);

    return (
        <div className={styles.wordCard} onDoubleClick={openDefinition}>
            <img
                className={styles.coverImage}
                src={SenseImageLocation(senseId ? senseId : word.PrimarySenseId)}
                alt={word.Text}
            />
            <div className={styles.content}>
                <h3 className={styles.word}>{word.Text}</h3>
                <p className={`${styles.definition} overFlowDots`}>
                    {showTranslate ? translateDefinition
                        : primaryMeaning}
                    {/* {primaryMeaning.length > 56
                        ? `${primaryMeaning.slice(0, 56)}...`
                        : primaryMeaning} */}
                </p>
                <ButtonSection word={word} />
            </div>
            {removeMode &&
                <img src={DirectIconLocation('minus')} onClick={removeWord}
                    className={styles.removeButton} alt='minus' />
            }
        </div>


    );
}; 