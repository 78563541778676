import { useCallback, useEffect, useRef, useState } from 'react';
import Slide from 'react-slick';
import Spinner from 'src/components/ui/Features/Spinner/Spinner';
import { useLearning } from 'src/hook/data/useLearning';
import { useScenario } from 'src/hook/data/useScenario';
import { AnswerDone, Question } from 'src/model/Scenario';
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { SliderSettings } from 'src/utils/SliderSetting';
import Answer from './Answer/Answer';
import ImageSection from './Answer/ImageSection/ImageSection';
import styles from './Answers.module.scss';


interface Props {
    question: Question;
    isWaiting?: boolean;
}

const Answers = ({ question, isWaiting = false }: Props) => {
    const { learningInfo } = useLearning();
    const [answersDone, setAnswersDone] = useState<AnswerDone[]>([]);
    const [initialSlide, setInitialSlide] = useState(0);
    const previousAnswers = useRef(learningInfo?.Lessons.Answers);
    const sliderRef = useRef<Slide>(null);
    const [personNames, setPersonNames] = useState<string[]>([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const { scenarios, isLoading } = useScenario();


    const fetchData = useCallback(async () => {
        const answerDone = question.a.map(async x => ({
            answer: x,
            isDone: (learningInfo?.Lessons.CompletedContext.includes(question.r)
                || learningInfo?.Lessons.Answers?.includes(await ToIOSafeHash(`${question.q}|${x}`))) ?? false
        }));

        const completedAnswer = await Promise.all(answerDone);
        setAnswersDone(completedAnswer);

        const firstIncompleteIndex = completedAnswer.findIndex(q => !q.isDone);
        setInitialSlide(firstIncompleteIndex === -1 ? 0 : firstIncompleteIndex);
        setCurrentSlide(firstIncompleteIndex === -1 ? 0 : firstIncompleteIndex);

        const filtered = scenarios?.people.filter(x => x.r === 'your friend');
        if (filtered && filtered.length > 0) {
            const names = question.a.map(() =>
                filtered[Math.floor(Math.random() * filtered.length)].n
            );
            setPersonNames(names);
        }

    }, [question, learningInfo?.Lessons, scenarios?.people]);


    const goToNextSlide = useCallback(() => {
        setTimeout(() => {
            sliderRef.current?.slickNext();
        }, 1000);
    }, []);

    const updateAnswerDone = useCallback((index: number, isDone: boolean) => {
        setAnswersDone(prev => prev.map((answer, i) =>
            i === index ? { ...answer, isDone } : answer
        ));
    }, []);

    useEffect(() => {
        if (answersDone.length === 0) {
            fetchData();
        }
    }, [fetchData, answersDone, initialSlide]);



    useEffect(() => {
        const currentAnswers = learningInfo?.Lessons.Answers;
        if (previousAnswers.current?.length !== currentAnswers?.length) {
            updateAnswerDone(currentSlide, true);
            goToNextSlide();
        }
        previousAnswers.current = currentAnswers;
    }, [learningInfo?.Lessons.Answers, currentSlide, goToNextSlide, updateAnswerDone]);


    // useEffect(() => {
    //     if (isWaiting && initialSlide > 0) {
    //         sliderRef.current?.slickGoTo(initialSlide - 1);
    //         setTimeout(() => {
    //             sliderRef.current?.slickGoTo(initialSlide);
    //         }, 1000);
    //     }
    // }, [isWaiting, initialSlide]);


    if (isLoading || answersDone?.length === 0) return <Spinner fullPage={true} />

    return (
        <div className={styles.answers}>
            <ImageSection question={question} />
            <div className={styles.slideContainer}>
                <Slide ref={sliderRef} {...SliderSettings()} speed={800}
                    cssEase="ease-in-out"
                    initialSlide={initialSlide}
                    beforeChange={(_, next) => setCurrentSlide(next)}>
                    {answersDone.map((answerDone, index) => (
                        <Answer key={index}
                            question={question}
                            answerDone={answerDone}
                            currentIndex={index}
                            totalItems={question.a.length}
                            personName={personNames[index] || ''} />
                    ))}
                </Slide>
            </div>
        </div>
    );
};

export default Answers;
