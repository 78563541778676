import { useEffect, useRef, useState } from 'react';
import { useWordData } from 'src/hook/data/useWordData';
import useAudioPlayer from 'src/hook/global/useAudioPlayer';
import { DefinitionSource } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import { Sense } from 'src/model/WordData';
import Spinner from '../ui/Features/Spinner/Spinner';
import ProTips from '../ui/GlobalComponents/ProTips/ProTips';
import Compare from './Compare/Compare';
import { DataView } from './DataView/DataView';
import styles from './Definition.module.scss';
import { DefinitionFooter } from './DefinitionFooter/DefinitionFooter';
import ImageSection from './ImageSection/ImageSection';
import Senses from './Senses/Senses';
interface DefinitionProps {
    word: Word;
    moveNext: () => void;
    source?: DefinitionSource;
}

const Definition = ({ word, moveNext, source }: DefinitionProps) => {
    const { wordData } = useWordData(word);
    const [currentSense, setCurrentSense] = useState<Sense>({} as Sense);
    const { play } = useAudioPlayer();
    const isFirstRender = useRef(true);


    useEffect(() => {
        if (isFirstRender.current && word.Text) {
            play(word.Text, '', true);
            isFirstRender.current = false;
        }
    }, [play, word.Text]);

    if (!word || !wordData) return <Spinner />

    return (
        <article className={styles.definition}>
            <ImageSection word={word} sense={currentSense} />
            <Senses word={word} wordData={wordData} setCurrentSense={setCurrentSense} />
            <ProTips word={word} sense={currentSense} isFromDefinition={true} />
            <Compare word={word} wordData={wordData} />
            <DataView wordData={wordData} word={word} />
            <DefinitionFooter word={word} moveNext={moveNext} source={source} />
        </article>
    );
};

export default Definition;