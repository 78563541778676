import React from 'react';
import Flag from 'src/components/ui/GlobalComponents/Flag/Flag';
import { useSmartWord } from 'src/hook/global/useSmartWord';
import { Word } from 'src/model/Word';
import { WisdomData } from '../../../../model/WordData';
import styles from './Wisdom.module.scss';

interface WisdomProps {
    data: WisdomData;
    word: Word;
}

export const Wisdom: React.FC<WisdomProps> = ({ data, word }) => {
    const { HighlightWord } = useSmartWord(true);

    return (
        <div className={styles.wisdom}>
            <div
                className={styles.wisdomContainer}
                style={{ backgroundImage: `url(${data.ImageUrl})` }}
            >
                <div className={styles.wisdomContent}>
                    <p className={styles.wisdomDescription}>
                        {HighlightWord(data.Description, word.Text)}
                    </p>
                    <Flag text={data.Description} isDark={true} />
                </div>
            </div>
        </div>
    );
};

export default Wisdom;