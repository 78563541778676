
import { useMemo } from "react";
import AudioPlayer from "src/components/ui/GlobalComponents/AudioPlayer/AudioPlayer";
import VolumeSection from "src/components/ui/GlobalComponents/VolumeSection/Volumesection";
import { useNote } from "src/hook/components/useNote";
import useUser from "src/hook/data/useUser";
import { useWordTranslate } from "src/hook/data/useWordTranslate";
import { useSmartWord } from "src/hook/global/useSmartWord";
import { Word } from "../../../../model/Word";
import { Sense } from "../../../../model/WordData";
import styles from './SenseItem.module.scss';

interface SenseProps {
    word: Word;
    sense: Sense;
}

const SenseItem = ({ word, sense }: SenseProps) => {
    const { userInfo } = useUser();
    const { getTranslateBySenseId } = useWordTranslate();
    const { HighlightWord } = useSmartWord(true);
    const { getNotesBySense } = useNote();
    const notes = useMemo(() => getNotesBySense(word?.ID.toString(), sense?.id.toString()), [word?.ID, sense?.id, getNotesBySense]);

    return (
        <div className={styles.senseItem}>
            <h1>{word.Text}</h1>
            <div className={styles.volumeSection}>
                <VolumeSection word={word} />
                <span className={styles.type}>{sense?.ty}</span>
            </div>
            <hr />
            <div className={styles.definition}>{sense?.de}</div>
            <div className={styles.example}>
                {HighlightWord(sense?.ex, word.Text)}
            </div>

            <div className={styles.audioPlayer}>
                <AudioPlayer text={`${sense?.de}. for example. ${sense?.ex}`} />
                <div className={styles.translate}>{getTranslateBySenseId(sense?.id)}</div>
            </div>
            {notes.length > 0 &&
                <div className={styles.notes}>
                    <hr />
                    {notes.map((note, index) => <p key={index}>{note}</p>)}
                </div>}
        </div>
    );
};

export default SenseItem;