import { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom'; // Add this import
import styles from './Modal.module.scss';
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    width?: string;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children, width = '90%' }) => {
    const [shouldRender, setShouldRender] = useState(false);
    useEffect(() => {
        if (isOpen) {
            setShouldRender(true);
        } else {
            const timer = setTimeout(() => {
                setShouldRender(false);
            }, 200);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);
    if (!shouldRender) return null;
    // Use createPortal to render the modal at the document root
    return createPortal(
        <div className={`${styles.modalWrapper} ${isOpen ? styles.open : ''}`}>
            <div
                className={styles.overlay}
                onClick={onClose}
            />
            <div className={styles.modal} style={{ width }}>
                {children}
            </div>
        </div>,
        document.body  // Render directly to body
    );
};
export default Modal;