import { FC } from 'react';
import Modal from 'src/components/ui/Features/Modal/Modal';
import { ImageLocation } from 'src/utils/URLUtils';
import styles from './LexiModal.module.scss';
interface LexiModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

const LexiModal: FC<LexiModalProps> = ({ isModalOpen, setIsModalOpen }) => {

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <div className={styles.lexiModal}>
                <img src={ImageLocation('Lexi/Lexi-Great-Job.png')}
                    className={styles.lexiImage}
                    alt='lexi' onClick={() => setIsModalOpen(false)} />
                <h2>Great Job!</h2>
                <p>You've just learned your first lesson</p>
                <button onClick={() => setIsModalOpen(false)}
                    className={styles.okButton}>Keep Going</button>
            </div>
        </Modal>
    );
};

export default LexiModal;