import { FC } from 'react';
import { useDiscover } from 'src/hook/components/useDiscover';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { useLearnWizard } from '../../../../../hook/components/useLearnWizard';
import { AnimationType, DefinitionSource } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import { WordData } from '../../../../../model/WordData';
import styles from './ButtonSection.module.scss';

interface ButtonSectionProps {
    word: Word;
    wordData: WordData;
}

const ButtonSection: FC<ButtonSectionProps> = ({ word, wordData }) => {
    const { close } = useLearnWizard();
    const { open } = useComponentModal();
    const { moveNext } = useDiscover();

    const handleLearnMore = () => {
        open('Definition', { word: word, source: DefinitionSource.Learn }, AnimationType.SlideUp);
    };

    const handleNextWord = () => {
        close();
        moveNext?.();
    };


    return (
        <>
            <button className={styles.learnMoreButton} onClick={() => handleLearnMore()}>Learn More</button>
            <button className={styles.nextWordButton} onClick={() => handleNextWord()}>Next Word</button>
        </>
    );
};

export default ButtonSection;