import { useState } from "react";
import { Voice } from "src/model/enum/enums";
import { GetVoiceUrl } from "src/utils/URLUtils";
import useUser from "../data/useUser";

export const useAudio = () => {
    const [male, setMale] = useState(true);
    const { userInfo } = useUser();

    const playAudio = async (url: string, checkPlaySound: boolean = true) => {
        if (checkPlaySound && userInfo?.PlaySound === 'off') return;
        try {
            const audio = new Audio(url);
            await audio.play();
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };

    const playWord = async (wordId: number) => {
        const accent = userInfo?.Voice || Voice.gb;
        const link = GetVoiceUrl(accent, male ? 'm' : 'f', wordId + '.mp3');
        await playAudio(link, false);
        setMale(prev => !prev);
    };

    return { playAudio, playWord };
};