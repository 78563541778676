import { useCallback, useEffect, useState } from 'react';
import Slide from "react-slick";
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { Word } from '../../../../model/Word';
import { ProTipData, Sense } from '../../../../model/WordData';
import { SliderSettings } from '../../../../utils/SliderSetting';
import { DirectIconLocation, GetTipsImageUrl } from "../../../../utils/URLUtils";
import ProTip from './ProTip/ProTip';
import styles from "./ProTips.module.scss";


interface ProTipsProps {
    word: Word;
    sense: Sense | undefined;
    isFromDefinition?: boolean;
}

const ProTips = ({ word, sense, isFromDefinition }: ProTipsProps) => {
    const [tips, setTips] = useState<ProTipData[]>([]);
    const [isStop, setIsStop] = useState(false);

    const fetchData = useCallback(async () => {

        const tp = sense?.tp?.split('\n');
        const isPrimarySense = sense?.id === word.PrimarySenseId;
        const tipsPromises = tp?.map(async (tip, index) => {
            const tipSections = tip.split('|');
            const title = tipSections[0] ?? '';
            const description = tipSections[1] ?? '';
            const example = tipSections[2] ?? '';
            let imageUrl = '';
            if (isPrimarySense) {
                imageUrl = GetTipsImageUrl(await ToIOSafeHash(example.trim()));
            }
            return { Title: title, Description: description, Example: example, ImageUrl: imageUrl };
        });

        const tips = await Promise.all(tipsPromises ?? []);
        setTips(tips);
    }, [sense, word]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (tips?.length === 0) return null;

    return (
        <section className={styles.proTips}>
            <div className={styles.title}>
                <img src={DirectIconLocation('bulb')} alt="Pro Tips" />
                <h1>Pro Tips</h1>
            </div>
            <div className="slider-container">
                <Slide {...SliderSettings()}
                    beforeChange={() => setIsStop(true)}
                    afterChange={() => setIsStop(false)}>
                    {tips?.map((item, index) => (
                        <ProTip key={index} isStop={isStop}
                            word={word} tip={item} isFromDefinition={isFromDefinition} />
                    ))}
                </Slide>
            </div>
        </section>
    );
}

export default ProTips;