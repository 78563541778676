import { useCallback, useEffect, useRef, useState } from 'react';
import Spinner from 'src/components/ui/Features/Spinner/Spinner';
import { useScenario } from 'src/hook/data/useScenario';
import { useTranslate } from 'src/hook/data/useTranslate';
import useUser from 'src/hook/data/useUser';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { GetLessonsVideoUrl } from 'src/utils/URLUtils';
import { Question } from '../../../model/Scenario';
import styles from './LessonWizard.module.scss';
import ProgressBar from './ProgressBar/ProgressBar';
import { VideoSection } from './VideoSection/VideoSection';
import WizardStep from './WizardStep/WizardStep';

interface Props {
    question: Question;
    translateText?: string;
    answer?: string;
    voice?: string;
}

export const LessonWizard = ({ question, translateText, answer, voice }: Props) => {
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [currentStep, setCurrentStep] = useState(1);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [lessonQuestions, setLessonQuestions] = useState<string[]>([]);
    const { isNonEnglishNativeLanguage } = useUser();
    const [translatedText, setTranslatedText] = useState<string | undefined>(translateText);
    const { open } = useComponentModal();
    const title = answer ? answer : question.q;
    const { translate } = useTranslate();
    const { scenarioQAs, isLoading } = useScenario();

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const fetchData = useCallback(async () => {
        const url = GetLessonsVideoUrl(await ToIOSafeHash(question.q));
        setVideoUrl(url);

        if (!translateText) {
            const response = await translate(title);
            setTranslatedText(response);
        }
        if (answer)
            setLessonQuestions(scenarioQAs?.find(x => x.q === question.q)?.a ?? []);
        else
            setLessonQuestions(scenarioQAs?.filter(x => x.r === question.r).map(x => x.q) ?? []);

    }, [question, translateText, answer, title, translate, scenarioQAs]);

    useEffect(() => {
        if (!videoUrl)
            fetchData()
    }, [fetchData, videoUrl]);

    const handleNextStep = () => {

        if (currentStep === 5) {
            open(answer ? 'Answers' : 'WellDone', { question, answer }, AnimationType.SlideRight)
            return;
        }

        if (isNonEnglishNativeLanguage()) {
            setCurrentStep(prev => Math.min(prev + 1, 6));
        } else {
            // Skip step 2 and limit to 4 steps
            setCurrentStep(prev => {
                const nextStep = prev + 1;
                return nextStep === 2 ? 3 : Math.min(nextStep, 6);
            });
        }
    };


    if (isLoading) return <Spinner />;


    return (
        <section className={styles.LessonWizard}>
            <VideoSection question={question}
                videoUrl={videoUrl} videoRef={videoRef} answer={answer} />
            <div className={styles.wizardContainer}>
                <ProgressBar currentStep={currentStep} answer={answer} />
                <WizardStep
                    lessonQuestions={lessonQuestions}
                    currentStep={currentStep}
                    question={question}
                    onNextStep={handleNextStep}
                    onPlay={handlePlay}
                    translateText={translatedText}
                    answer={answer}
                    voice={voice}
                />
            </div>
        </section>
    );
};
