import { Word } from '../model/Word';

export const parseWordLine = (line: string): Word => {
    const [id, rank, text, otherForms, primaryMeaning, primarySenseId, type, britishPhonetic, americanPhonetic] = line.split('|');

    return {
        ID: parseInt(id),
        Rank: parseInt(rank),
        CustomRank: 0,
        Text: text,
        OtherForms: otherForms,
        PrimaryMeaning: primaryMeaning,
        PrimarySenseId: primarySenseId,
        Type: parseInt(type),
        BritishPhonetic: britishPhonetic,
        AmericanPhonetic: americanPhonetic,
        AllForms: otherForms ? otherForms.split(',') : []
    };
};

export const applyCustomRankings = (words: Word[], rankingsData: string): Word[] => {
    // Parse the rankings response into an array of numbers
    const rankings = rankingsData.split(',').map(id => parseInt(id.trim()));

    // Create a map of word ID to custom rank
    const rankMap = new Map<number, number>();
    rankings.forEach((wordId, index) => {
        rankMap.set(wordId, index + 1);
    });

    // Return new array with updated CustomRank values
    return words.map(word => ({
        ...word,
        CustomRank: rankMap.get(word.ID) || word.Rank || 0
    }));
};

export const parseWordsFile = (content: string, rankingsData?: string | null): Word[] => {
    const words = content
        .split('\n')
        .filter(line => line.trim())
        .map(parseWordLine);
    return rankingsData ? applyCustomRankings(words, rankingsData) : words;
};

