import { createSlice } from '@reduxjs/toolkit';

interface DiscoverState {
    moveNext: (() => void) | null;
}

const initialState: DiscoverState = {
    moveNext: null,
};

const discoverSlice = createSlice({
    name: 'discover',
    initialState,
    reducers: {
        setMoveNext: (state, action) => {
            state.moveNext = action.payload;
        },
    },
});

export const { setMoveNext } = discoverSlice.actions;
export default discoverSlice.reducer;
