import { useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'src/hook/data/useTranslate';
import { getDirection } from 'src/utils/DirectionUtils';
import useUser from "../../../../../hook/data/useUser";
import { useSmartWord } from '../../../../../hook/global/useSmartWord';
import { Word } from "../../../../../model/Word";
import { ProTipData } from "../../../../../model/WordData";
import { IconLocation } from '../../../../../utils/URLUtils';
import PlaySection from "../../PlaySection/PlaySection";
import styles from "./ProTip.module.scss";
interface ProTipProps {
    word: Word;
    tip: ProTipData;
    isFromDefinition?: boolean;
    isStop: boolean;
}

const ProTip = ({ word, tip, isFromDefinition, isStop }: ProTipProps) => {

    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const [showFlag, setShowFlag] = useState(isNonEnglishNativeLanguage());
    const [translateDefinition, setTranslateDefinition] = useState<string | null>(null);
    const [translateExample, setTranslateExample] = useState<string | null>(null);
    const { translateMultiple, isLoading } = useTranslate();
    const { HighlightWord } = useSmartWord(isFromDefinition);

    useEffect(() => {
        setTranslateDefinition(null);
        setTranslateExample(null);
        setShowFlag(isNonEnglishNativeLanguage());
    }, [tip.Description, tip.Example, isNonEnglishNativeLanguage]);

    const handleFlagClick = useCallback(async () => {
        const results = await translateMultiple([tip?.Description, tip?.Example]);
        if (results && results.length > 0) {
            setShowFlag(false);
            setTranslateDefinition(results[0]);
            setTranslateExample(results[1]);
        }
    }, [translateMultiple, tip]);


    return (
        <div className={styles.proTip}>
            <div className={styles.container + (tip.ImageUrl ? ' ' + styles.withImage : '')}>
                <div className={styles.header}>
                    <PlaySection
                        type={tip?.Title}
                        typeClass={styles.title}
                        audioText={`${tip?.Description}. for example. ${tip?.Example}`}
                        showFlag={showFlag}
                        nativeLanguageCode={userInfo?.NativeLanguageCode}
                        onFlagClick={handleFlagClick}
                        isLoading={isLoading}
                        isStop={isStop}
                    />
                </div>
                <span className={styles.description}>
                    {HighlightWord(tip?.Description, word.Text)}
                    <div className={styles.translate} dir={getDirection(translateDefinition)}>
                        {translateDefinition}
                    </div>
                </span>

                <div className={styles.imageContainer}>
                    {tip.ImageUrl && <img src={tip.ImageUrl} alt='SlideImage' className={styles.coverImage} />}
                    <div className={styles.example + (tip.ImageUrl ? ' ' + styles.withImage : '')}>
                        {!tip.ImageUrl && <img src={IconLocation('quote')} alt='Quote'
                            className={styles.quote} />}
                        {HighlightWord(tip?.Example, word.Text)}
                        <div className={styles.translate} dir={getDirection(translateExample)}>
                            {translateExample}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ProTip;
