import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWordList } from 'src/hook/data/useWordList';
import { useWords } from 'src/hook/data/useWords';
import { Word } from 'src/model/Word';
import { WordList } from 'src/model/WordList';
import styles from './ImportWordsForm.module.scss';

interface ImportWordsFormProps {
    currentList: WordList | undefined;
}

export const ImportWordsForm = ({ currentList }: ImportWordsFormProps) => {
    const navigate = useNavigate();
    const { addSomeWordsToCustomList } = useWordList();
    const [words, setWords] = useState('');
    const { getWordByText } = useWords();

    const save = async () => {
        if (!currentList || !words.trim()) return;

        // Split by comma or newline and clean up each word
        const wordsList = words
            .split(/[,\n]/)
            .map(word => word.trim())
            .filter(word => word.length > 0);

        // Get unique words to avoid duplicate API calls
        const uniqueWords = Array.from(new Set(wordsList));

        const foundWords = await Promise.all(
            uniqueWords.map(word => getWordByText(word))
        );

        // Filter out any null results and flatten the array
        const validWords = foundWords
            .filter((result): result is Word => result !== undefined)
            .map(word => ({
                Word: word,
                SenseId: word.PrimarySenseId
            }));

        if (validWords.length > 0) {
            addSomeWordsToCustomList(currentList, validWords);
        }

        navigate(`/wordlists/wordlist?id=${currentList.ID}`);

    };

    return (
        <div className={styles.importWordsForm}>
            <h2>Add Words</h2>
            <p>Separated by comma or line.</p>
            <textarea
                className={styles.textarea}
                placeholder='Copy your words here...'
                onChange={(e) => setWords(e.target.value)}
            />
            <button onClick={save}>Save</button>
        </div>
    );
};
