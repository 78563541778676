import { useEffect, useState } from 'react';
import useProgress from 'src/hook/data/useProgress';
import useUser from 'src/hook/data/useUser';
import { UserFields } from 'src/model/enum/dataEnums';
import { IconLocation } from '../../../../utils/URLUtils';
import Divider from '../../../ui/GlobalComponents/Divider/Divider';
import styles from './PronounceSection.module.scss';

interface PronounceSectionProps {
  thick?: boolean;
}

const PronounceSection = ({ thick }: PronounceSectionProps) => {
  const [pronounce, setPronounce] = useState<boolean | null>(null);
  const { userInfo } = useUser();
  const { updateUserInfoField } = useProgress();


  const handlePronounce = (value: boolean) => {
    setPronounce(value);
    updateUserInfoField(UserFields.AutoPronounce, value);
  };

  useEffect(() => {
    if (pronounce === null) {
      setPronounce(userInfo?.AutoPronounce ?? true);
    }
  }, [userInfo, pronounce]);

  return (
    <>
      <div className={styles.section}>
        <div className={styles.row}>
          <img className={styles.icon} src={IconLocation('volume-1')} alt="Pronounce" />
          <span className={styles.text}>Pronounce</span>
        </div>
        <div className={styles.switch}>
          <div
            className={!pronounce ? styles.activeGray : styles.notActive}
            onClick={() => handlePronounce(false)}>
            <span>Off</span>
          </div>
          <div
            className={pronounce ? styles.active : styles.notActive}
            onClick={() => handlePronounce(true)}>
            <span>On</span>
          </div>
        </div>
      </div>
      <Divider thick={thick} />
    </>
  );
};

export default PronounceSection;