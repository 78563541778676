import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../model/enum/enums";
import { User } from "../../model/User";
import { userStorage } from "../../utils/StorageUtils";

interface UserState {
    userInfo: User | null;
    isUserLoaded: boolean;
    status: LoadingStatus;
    error: string | null;
}

const initialState: UserState = {
    userInfo: null,
    isUserLoaded: false,
    status: LoadingStatus.IDLE,
    error: null
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state) => {
            state.userInfo = userStorage.get();
            state.isUserLoaded = true;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            userStorage.set(action.payload);
            state.userInfo = action.payload;
            state.isUserLoaded = true;
        },
        updateUser: (state, action: PayloadAction<Partial<User>>) => {
            state.userInfo = userStorage.update(action.payload);
        },
        logout: (state) => {
            Object.assign(state, initialState);
            userStorage.set(null);
        },
    },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;