import { ComponentType } from 'react';
import Definition from '../../components/Definition/Definition';
import Answers from '../../components/Lessons/Answers/Answers';
import { LessonWizard } from '../../components/Lessons/LessonWizard/LessonWizard';
import WellDone from '../../components/Lessons/LessonWizard/WellDone/WellDone';
export const MODAL_COMPONENTS: Record<string, ComponentType<any>> = {
    Definition,
    LessonWizard,
    Answers,
    WellDone,
    // Add all your modal components here
};