import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWordList } from 'src/hook/data/useWordList';
import { useBackHandler } from 'src/hook/global/useBackHandler';
import { SortType } from 'src/model/enum/enums';
import { WordList } from 'src/model/WordList';
import { IconLocation } from '../../../../utils/URLUtils';
import Menu from './Menu/Menu';
import styles from './WordListPageHeader.module.scss';

interface WordListPageHeaderProps {
    wordList: WordList;
    setRemoveMode: (removeMode: boolean) => void;

    removeMode: boolean;
    selectedItem: SortType;
    setSelectedItem: (selectedItem: SortType) => void;
}


export const WordListPageHeader = ({ wordList, setRemoveMode, removeMode, selectedItem, setSelectedItem }: WordListPageHeaderProps) => {
    const handleBack = useBackHandler();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const { updateWordListStats } = useWordList();
    const navigate = useNavigate();

    const back = () => {
        updateWordListStats(wordList);
        navigate('/wordlists');
    }

    return (
        <section className={styles.wordListPageHeader}>
            <img className={styles.backIcon} onClick={back}

                src={IconLocation('chevron-left')} alt='back' />
            <h1>{wordList.Title}</h1>
            {!removeMode ? <span ref={menuRef} onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.menu}>•••</span>
                :
                <button className={styles.doneButton} onClick={() => setRemoveMode(false)}>Done</button>}
            <Menu
                isOpen={isMenuOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                onClose={() => setIsMenuOpen(false)}
                trigger={menuRef.current}
                wordList={wordList}
                setRemoveMode={setRemoveMode}
            />
        </section>
    );
};
