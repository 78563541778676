import { useCallback, useEffect, useState } from 'react';
import Divider from 'src/components/ui/GlobalComponents/Divider/Divider';
import { useWords } from 'src/hook/data/useWords';
import { CompareData, WordData } from 'src/model/WordData';
import { Word } from '../../../model/Word';
import { SenseImageLocation } from "../../../utils/URLUtils";
import styles from "./Compare.module.scss";
import CompareItem from './CompareItem/CompareItem';


interface CompareProps {
    word: Word;
    wordData: WordData;
}

const Compare = ({ word, wordData }: CompareProps) => {
    const [compares, setCompares] = useState<{ [key: string]: CompareData }>({});
    const { getWordByText } = useWords();
    const [activeCompare, setActiveCompare] = useState<CompareData>({} as CompareData);


    const onItemClick = useCallback((comparedWord: string) => {
        setActiveCompare(compares[comparedWord]);
    }, [compares]);

    const fetchData = useCallback(async () => {
        const comparisons: { [key: string]: CompareData } = {};
        wordData.Comparisons.forEach((compare) => {
            const parts = compare.split('|');
            const wordRoot = parts[0];
            const wordDefinition = parts[1]
            const mainWord = getWordByText(wordRoot);
            const wordImage = SenseImageLocation(mainWord?.PrimarySenseId ?? '');
            comparisons[wordRoot] = {
                Title: wordRoot,
                Description: wordDefinition,
                ImageUrl: wordImage
            };
        });

        setCompares(comparisons);
        setActiveCompare(Object.values(comparisons)[0]);

    }, [wordData, getWordByText]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (Object.keys(compares).length === 0) return null;

    return (
        <>
            <section className={styles.compare}>
                <h1 className={styles.title}>Compare with</h1>
                <div className={styles.words}>
                    {
                        Object.keys(compares).map((word, index) => (
                            <span
                                key={index}
                                className={[styles.word,
                                word === activeCompare?.Title ?
                                    styles.green : styles.gray].join(' ')}
                                onClick={() => onItemClick(word)}
                            >
                                {word}
                            </span>
                        ))
                    }
                </div>
                <CompareItem activeCompare={activeCompare} word={word} />
            </section>
            <Divider thick={true} noMargin={true} />
        </>
    );
}

export default Compare;