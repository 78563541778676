import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WordTranslate } from '../../model/Word';

interface WordTranslateState {
    translates: WordTranslate;
}

const initialState: WordTranslateState = {
    translates: {} as WordTranslate,
};

const translatesSlice = createSlice({
    name: 'translates',
    initialState,
    reducers: {
        setWordTranslates: (state, action: PayloadAction<WordTranslate>) => {
            state.translates = action.payload;
        },
        logout: (state) => {
            state.translates = {} as WordTranslate;
        }
    }
});


export const translatesActions = translatesSlice.actions;
export default translatesSlice.reducer;