import { useDispatch, useSelector } from 'react-redux';
import { setMoveNext } from '../../store/slices/discoverSlice';
import { RootState } from '../../store/store';

export const useDiscover = () => {
    const dispatch = useDispatch();
    const moveNext = useSelector((state: RootState) => state.discover.moveNext);

    return {
        moveNext: () => moveNext?.(),
        setMoveNext: (fn: () => void) => dispatch(setMoveNext(fn)),
    };
};
