import { useState } from 'react';
import { useLearning } from 'src/hook/data/useLearning';
import { useWordList } from 'src/hook/data/useWordList';
import { useWords } from 'src/hook/data/useWords';
import { Word } from 'src/model/Word';
import { WordList } from 'src/model/WordList';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './Search.module.scss';

export const Search = ({ currentList }: { currentList: WordList }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<Word[]>([]);
    const { searchWords } = useWords();
    const { learningInfo } = useLearning();
    const { addWordToCustomList } = useWordList();

    const addWord = (word: Word) => {
        if (!currentList) return;
        addWordToCustomList(currentList, word, word.PrimarySenseId);
    }


    const handleSearch = (value: string) => {
        setSearchTerm(value);
        // Only search if there are 2 or more characters
        if (value.length >= 2) {
            const results = searchWords(value);
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }

    };




    return (
        <div className={styles.search}>
            <div className={styles.searchBox}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search for a word"
                    className={styles.searchInput}
                />

                <img
                    alt="Clear search"
                    src={IconLocation(searchTerm ? 'x' : 'search')}
                    className={styles.clearSearch}
                    onClick={() => {
                        setSearchTerm('');
                        setSearchResults([]);
                    }}
                />



            </div>

            {/* Show search results when we have a search term of 2 or more characters */}
            {searchTerm.length >= 2 && searchResults.length > 0 && (
                <div className={styles.searchResults}>
                    {searchResults.map((word, index) => (
                        <div key={word.ID}>
                            <div className={styles.wordItem}>
                                <div className={styles.wordMain}>
                                    <h3>{word.Text}</h3>
                                    <p className={`${styles.wordForms} overFlowDots`}>
                                        {word.AllForms.join(', ') || '\u00A0'}</p>
                                    <span className={`${styles.wordRank} ${learningInfo?.KnownWordIds.includes(word.ID) ?
                                        styles.known :
                                        learningInfo?.UnknownWordIds.includes(word.ID) ?
                                            styles.learn :
                                            ''}`}>
                                        #{word.CustomRank.toLocaleString('en-US')}
                                    </span>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <button className={styles.addButton}
                                        disabled={currentList.CustomListData?.some(x => x.WordId === word.ID)}
                                        onClick={() => addWord(word)}>+ Add</button>
                                </div>


                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            )}

        </div>
    );
};
