import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie-player';
import Slide from 'react-slick';
import { useDiscover } from 'src/hook/components/useDiscover';
import useAudioPlayer from 'src/hook/global/useAudioPlayer';
import { GetottieUrl } from 'src/utils/URLUtils';
import { Word } from '../../../../model/Word';
import { SliderSettings } from '../../../../utils/SliderSetting';
import { SlideItem } from './SlideItem/SlideItem';
import styles from './Slider.module.scss';

interface SliderProps {
    words: Word[];
    setCurrentWord: (word: Word) => void;
    goToNextRange: () => void;
}

export const Slider: FC<SliderProps> = ({ words, setCurrentWord, goToNextRange }) => {
    const sliderRef = useRef<Slide>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { play } = useAudioPlayer();
    const { setMoveNext } = useDiscover();
    const [showPrize, setShowPrize] = useState(false);

    const moveNext = useCallback(() => {
        if (currentIndex < words.length - 1) {
            sliderRef.current?.slickNext();
        }
        else {
            setShowPrize(true);
            setTimeout(() => {
                goToNextRange();
                setShowPrize(false);
                //  sliderRef.current?.slickNext();
            }, 1500);

        }
    }, [currentIndex, words.length, goToNextRange]);

    const handleChange = useCallback(
        (oldIndex: number, newIndex: number) => {
            play(words[newIndex].Text, '', true);
            setCurrentIndex(newIndex);
            setCurrentWord(words[newIndex]);
        },
        [setCurrentWord, words]
    );

    useEffect(() => {
        if (words.length > 0) {
            setCurrentIndex(0);
            setCurrentWord(words[0]);
            sliderRef.current?.slickGoTo(0);
        }
    }, [words, setCurrentWord]);


    useEffect(() => {
        setMoveNext(moveNext);
    }, [moveNext, setMoveNext]);


    return (
        <section className={styles.slider}>
            <Slide {...SliderSettings()} ref={sliderRef} beforeChange={handleChange}>
                {words.map((word, index) => (
                    <SlideItem key={word.ID} word={word} moveNext={moveNext} />
                ))}
            </Slide>
            {showPrize && <div className={styles.lottieContainer}>
                <Lottie
                    path={GetottieUrl(`Celebrate/${Math.random() < 0.5 ? '4' : '5'}`)}
                    play
                    className={styles.lottie}
                />
            </div>}
        </section>
    );
};
