import { useMemo } from 'react';
import Lottie from 'react-lottie-player';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { Question } from 'src/model/Scenario';
import { getRandomPraise } from 'src/utils/PraiseUtils';
import { GetWellDoneLottieUrl } from 'src/utils/URLUtils';
import styles from './WellDone.module.scss';

interface WellDoneProps {
    question: Question;
}

const WellDone = ({ question }: WellDoneProps) => {
    const lottieUrl = useMemo(() => GetWellDoneLottieUrl(), []);
    const praise = useMemo(() => getRandomPraise(), []);
    const { open } = useComponentModal();


    const handleContinue = () => {
        //  navigate(`/lessons/answers?id=${question.r}`);
        open('Answers', { question: question }, AnimationType.SlideRight)
    }

    return (
        <div className={styles.wellDone}>

            <div className={styles.lottieContainer}>
                <Lottie
                    path={lottieUrl}
                    loop={true}
                    play
                    className={styles.lottie}
                />
            </div>

            <h1 className={styles.praise}>{praise}</h1>

            <button
                className='turquoiseButton'
                onClick={handleContinue}
            >Continue</button>
        </div>
    );
};

export default WellDone;
