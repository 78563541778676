import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from 'src/hook/data/useUser';
import { useShare } from 'src/hook/global/useShare';
import { WordList } from 'src/model/WordList';
import { MenuPosition, SortType, WordListType } from '../../../../../model/enum/enums';
import { GetWebSiteUrl, IconLocation } from '../../../../../utils/URLUtils';
import DropdownMenu from '../../../../ui/Features/DropDownMenu/DropdownMenu';
import styles from './Menu.module.scss';
import { SortModal } from './SortModal/SortModal';

interface MenuProps {
    isOpen: boolean;
    onClose: () => void;
    trigger: HTMLSpanElement | null;
    wordList: WordList;
    setRemoveMode: (removeMode: boolean) => void;
    selectedItem: SortType;
    setSelectedItem: (selectedItem: SortType) => void;
}

const Menu = ({ isOpen, onClose, trigger, wordList, setRemoveMode, selectedItem, setSelectedItem }: MenuProps) => {
    const navigate = useNavigate();
    const { share } = useShare();
    const { userInfo } = useUser();
    const [isSortModalOpen, setIsSortModalOpen] = useState(false);


    const shareList = () => {
        const customListUrl = `${GetWebSiteUrl()}/custom-word-lists?handle=${userInfo?.UserId}&customList=${encodeURIComponent(wordList.ID)}`;
        share(`Share List ${wordList.Title} \n ${customListUrl}`);
    }

    const sortList = () => {
        setIsSortModalOpen(true);
        onClose();
    }


    return (
        <>
            {isOpen && <DropdownMenu
                isOpen={isOpen}
                onClose={onClose}
                trigger={trigger}
                position={MenuPosition.BottomLeft}
            >
                <div className={styles.menuItems}>
                    {wordList.Type === WordListType.Custom &&
                        <>
                            <div className={styles.menuItem} onClick={() => navigate(`/wordlists/add-words?id=${wordList.ID}`)}>
                                <img src={IconLocation('plus2')} alt="Plus" />
                                <span>Add Words</span>
                            </div>
                            <div className={styles.menuItem} onClick={() => navigate(`/wordlists/import-words?id=${wordList.ID}`)}>
                                <img src={IconLocation('download')} alt="Download" />
                                <span>Import Words</span>
                            </div>
                            <div className={styles.menuItem} onClick={() => { setRemoveMode(true); onClose(); }}>
                                <img src={IconLocation('x')} alt="X" />
                                <span>Remove Words</span>
                            </div>
                            {wordList.ID !== "0" &&
                                <div className={styles.menuItem} onClick={() => navigate(`/wordlists/create-new?id=${wordList.ID}`)}>
                                    <img src={IconLocation('clipboard')} alt="ClipBoard" />
                                    <span>Edit List</span>
                                </div>}
                        </>
                    }
                    {wordList.ID !== "0" &&
                        <div className={styles.menuItem} onClick={shareList}>
                            <img src={IconLocation('share2')} alt="Share" />
                            <span>Share List</span>
                        </div>}

                    <div className={styles.menuItem} onClick={sortList}>
                        <img src={IconLocation('sort')} alt="Sort" />
                        <span>Sort</span>
                    </div>
                </div>
            </DropdownMenu>}
            {isSortModalOpen &&
                <SortModal
                    isOpen={isSortModalOpen}
                    onClose={() => setIsSortModalOpen(false)}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />

            }
        </>
    );
};

export default Menu;