const ioSafeHashes: { [key: string]: string } = {};

export const createSHA1Hash = async (text: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-1', data);
    const hashArray = new Uint8Array(hashBuffer);

    // Match C#'s exact byte-to-base64 conversion
    return btoa(Array.from(hashArray, byte => String.fromCharCode(byte)).join('')).replace(/=+$/, '');
};

export const ToIOSafeHash = async (clearText: string): Promise<string> => {
    if (ioSafeHashes[clearText]) {
        return ioSafeHashes[clearText];
    }

    const hash = await createSHA1Hash(clearText);
    const filtered = hash.split('')
        .filter(c => /[a-zA-Z0-9]/.test(c))
        .join('');

    ioSafeHashes[clearText] = filtered;
    return filtered;
};
