import { QuoteData, VideosData, WisdomData } from '../model/WordData';
import { GetAuthorImageUrl, GetWisdomImageUrl } from './URLUtils';

export class WordDataParser {
    static parseQuotes(quotes: string[]): QuoteData[] {
        return quotes.map(quote => {
            const parts = quote.split('|');

            if (parts.length < 5) {
                throw new Error(`Invalid quote format: ${quote}`);
            }

            const [id1, id2, name, title, description] = parts;

            // Provide default values for empty fields
            const safeName = name?.trim() || 'Unknown Author';
            const safeTitle = title?.trim() || 'Quote';
            const safeDescription = description?.trim() || 'No description available';

            return {
                Title: safeTitle,
                Name: safeName,
                Description: safeDescription.replace(/[.,]$/, ''),
                ImageUrl: GetAuthorImageUrl(safeName.replaceAll(" ", "-").replaceAll("_", "-")
                    .replaceAll(/[`~!@#$%^&*()|+=?;:'",.<>\{\}\[\]\\\/]/gi, '').trim()
                    .replaceAll("--", "-").toLowerCase())
            };
        });
    }
}

export class FactDataParser {
    static parseFacts(facts: string[]): QuoteData[] {
        return facts.map(fact => {
            return {
                Title: 'Interesting Fact',
                Name: 'Lexi',
                Description: fact.trim(),
                ImageUrl: GetAuthorImageUrl('lexi')
            };
        });
    }
}

export class WisdomDataParser {
    static parseWisdom(wisdom: string[]): WisdomData[] {
        return wisdom.map(wisdom => {
            return {
                Description: wisdom.trim(),
                ImageUrl: GetWisdomImageUrl()
            };
        });
    }
}

export class VideoDataParser {
    static parseVideos(videos: string[]): VideosData[] {
        return videos.map(video => {
            const parts = video.split('|');

            if (parts.length < 5) {
                throw new Error(`Invalid video format: ${video}`);
            }

            const videoId = parts[3];
            const startTime = parseInt(parts[4].split('-')[0]) / 1000;

            return {
                Title: parts[1].replace(/%|\\|/g, "").trim(),
                Description: parts[2].replace(/■*[0-9]*-[0-9]*╣|\|/g, " ").trim(),
                VideoUrl: `https://www.youtube.com/embed/${videoId.trim()}?start=${startTime}`,
            };
        });
    }
}