import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NoteSQSType } from 'src/model/enum/enums';
import { Note } from 'src/model/Note';
import { noteActions } from 'src/store/slices/noteSlice';
import { insertIntoQueue } from 'src/utils/QueueUtils';
import { RootState } from '../../store/store';
import { GetNoteUrl, GetQueueUrl } from '../../utils/URLUtils';
import useFetch from '../api/useFetch';
import useUser from '../data/useUser';

export const useNote = (autoFetch: boolean = true) => {
    const { data, isLoading, error } = useSelector((state: RootState) => state.note);
    const { userInfo } = useUser();
    const { send } = useFetch<Note>();
    const appDispatch = useDispatch();

    const ac = useMemo(
        () => bindActionCreators(noteActions, appDispatch),
        []
    );

    useEffect(() => {
        if (!data && autoFetch) {
            ac.getNote();
        }
    }, [ac, data, autoFetch]);

    const fetchNoteData = useCallback(async (userId?: string) => {
        ac.setIsLoading(true);
        try {
            const { response } = await send(GetNoteUrl(userId || userInfo?.UserId));

            if (response) {
                ac.setNote(response);
            }
        } catch (err) {
            ac.setError(err instanceof Error ? err.message : 'Failed to fetch note data');
            console.error(err);
        } finally {
            ac.setIsLoading(false);
        }
    }, [send, userInfo?.UserId, ac]);

    const getNotesBySense = (wordId: string, senseId: string) => {
        if (!data) return [];
        return data[wordId]?.[senseId] || [];
    };

    const uploadNote = useCallback((operation: number, wordId: string, senseId: string, keyNote: string = '', updatedNote: string = '') => {
        if (!userInfo?.UserId) return;

        const jsonData = JSON.stringify({
            UserId: userInfo.UserId,
            WordId: parseInt(wordId),
            SenseId: senseId,
            KeyNote: keyNote,
            UpdatedNote: updatedNote,
            Operation: operation
        });

        insertIntoQueue(GetQueueUrl('user-notes'), jsonData);
    }, [userInfo?.UserId]);

    const addNote = useCallback((wordId: string, senseId: string, noteText: string) => {
        const currentData = { ...data };
        if (!currentData) {
            ac.setNote({
                [wordId]: {
                    [senseId]: [noteText]
                }
            });
        } else {
            const existingNotes = currentData[wordId]?.[senseId] || [];
            const newData = {
                ...currentData,
                [wordId]: {
                    ...currentData[wordId],
                    [senseId]: [...existingNotes, noteText]
                }
            };
            ac.setNote(newData);
        }
        uploadNote(0, wordId, senseId, noteText);
    }, [data, ac]);

    const editNote = useCallback((wordId: string, senseId: string, index: number, newText: string) => {
        if (!data?.[wordId]?.[senseId]) return;
        const updatedNotes = [...data[wordId][senseId]];
        const oldText = updatedNotes[index];
        updatedNotes[index] = newText;
        const newData = {
            ...data,
            [wordId]: {
                ...data[wordId],
                [senseId]: updatedNotes
            }
        };
        ac.setNote(newData);
        uploadNote(1, wordId, senseId, oldText, newText);
    }, [data, ac]);

    const removeNote = useCallback((wordId: string, senseId: string, index: number) => {
        if (!data?.[wordId]?.[senseId]) return;
        const updatedNotes = [...data[wordId][senseId]];
        const removedNote = updatedNotes[index];
        updatedNotes.splice(index, 1);
        const newData = {
            ...data,
            [wordId]: {
                ...data[wordId],
                [senseId]: updatedNotes
            }
        };
        ac.setNote(newData);
        uploadNote(2, wordId, senseId, removedNote);
    }, [data, ac]);

    const saveNotes = useCallback((wordId: string, senseId: string, textValue: string) => {
        const newNotes = textValue.split('\n')
            .map(note => note.trim())
            .filter(note => note.length > 0);

        const existingNotes = getNotesBySense(wordId, senseId);

        // Upload changes to the server
        existingNotes.forEach((note) => {
            if (!newNotes.includes(note)) {
                uploadNote(NoteSQSType.Delete, wordId, senseId, note);
            }
        });

        newNotes.forEach((note, index) => {
            if (index < existingNotes.length) {
                if (note !== existingNotes[index]) {
                    uploadNote(NoteSQSType.Edit, wordId, senseId, existingNotes[index], note);
                }
            } else {
                uploadNote(NoteSQSType.Add, wordId, senseId, note);
            }
        });

        // Update the state in one batch
        const currentData = { ...data };
        const newData = {
            ...currentData,
            [wordId]: {
                ...currentData?.[wordId],
                [senseId]: newNotes
            }
        };
        ac.setNote(newData);
    }, [data, ac]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    return {
        data,
        isLoading,
        error,
        fetchNoteData,
        getNotesBySense,
        addNote,
        editNote,
        removeNote,
        saveNotes,
        handleLogout
    };
};
