import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'src/components/ui/Features/Modal/Modal';
import { useLearning } from 'src/hook/data/useLearning';
import { useWordList } from 'src/hook/data/useWordList';
import { useToast } from 'src/hook/global/useToasts';
import { Word } from 'src/model/Word';
import { WordList } from 'src/model/WordList';
import { WordListType } from 'src/model/enum/enums';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './AddToListModal.module.scss';

interface AddToListModalProps {
    isOpen: boolean;
    onClose: () => void;
    word: Word;
    senseId: string;
    setIsCreateNewListModalOpen: (isOpen: boolean) => void;
}

export const AddToListModal = ({ isOpen, onClose, word, senseId, setIsCreateNewListModalOpen }: AddToListModalProps) => {
    const { learningInfo } = useLearning();
    const navigate = useNavigate();
    const { addWordToSomeCustomLists } = useWordList();
    const [selectedLists, setSelectedLists] = useState<WordList[]>([]);
    const toast = useToast();

    const customLists = learningInfo?.WordLists?.filter(
        list => list.Type === WordListType.Custom
    ) || [];

    const handleCheckboxChange = (list: WordList) => {
        setSelectedLists(prev => {
            if (prev.includes(list)) {
                return prev.filter(id => id !== list);
            }
            return [...prev, list];

        });
    };

    const save = async () => {
        addWordToSomeCustomLists(selectedLists, word, senseId);
        onClose();
        toast.showSuccess('List(s) updated.');
    };

    const newList = () => {
        onClose();
        setIsCreateNewListModalOpen(true);
    };


    if (!word) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.addToListModal}>
                <div className={styles.modalHeader}>
                    <h1>Add "{word.Text}" to...</h1>
                    <img src={IconLocation('x')} alt="close" onClick={onClose} />
                </div>
                <div className={styles.modalBody}>
                    {customLists.map(list => (
                        <div key={list.ID} className={styles.checkboxRow}>
                            <input
                                type="checkbox"
                                id={list.ID}
                                checked={selectedLists.includes(list)}
                                onChange={() => handleCheckboxChange(list)}

                            />
                            <label htmlFor={list.ID} className={styles.listTitle}>{list.Title}</label>
                        </div>
                    ))}
                </div>
                <hr />
                <div className={styles.modalFooter}>
                    <div className={styles.newList} onClick={newList}>+ New List</div>
                    <button onClick={save}>Save</button>

                </div>
            </div>
        </Modal>
    );
};