import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLearnWizard } from 'src/hook/components/useLearnWizard';
import { useLearning } from 'src/hook/data/useLearning';
import { useWordData } from 'src/hook/data/useWordData';
import { Actions, LearnWizardStep } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import styles from './ButtonSection.module.scss';

type ButtonSectionProps = {
    word: Word;
    moveNext: () => void;
}

export const ButtonSection = ({ word, moveNext }: ButtonSectionProps) => {
    const { shouldLearn, alreadeyKnew, forget } = useLearning();
    const { learningInfo } = useLearning();
    const [existWord, setExistWord] = useState<string>("");
    const { open } = useLearnWizard();
    const { wordData } = useWordData(word);
    const [searchParams] = useSearchParams();
    const wordListId = searchParams.get('id');


    const handleShouldLearn = useCallback(() => {
        shouldLearn(word, wordListId === "-1");

        if (learningInfo?.UnknownWordIds && learningInfo?.UnknownWordIds?.length >= 5) {
            moveNext?.();
        }
    }, [word, shouldLearn, learningInfo?.UnknownWordIds, moveNext, wordListId]);

    const handleAlreadyKnew = useCallback(() => {
        alreadeyKnew(word, wordListId === "-1");
        moveNext?.();
    }, [word, alreadeyKnew, moveNext, wordListId]);

    const handleRemove = useCallback(() => {
        forget(word);
    }, [word, forget]);

    const handleLearnNowContinue = useCallback(async () => {
        if (existWord === Actions.Knew) {
            moveNext?.();
        } else {
            open(LearnWizardStep.First, { word, wordData });
            //   moveNext?.();
        }
    }, [word, open, existWord, moveNext, wordData]);

    useEffect(() => {
        if (learningInfo?.KnownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Knew)
        else if (learningInfo?.UnknownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Learn)
        else
            setExistWord('');
    }, [learningInfo?.KnownWordIds, learningInfo?.UnknownWordIds, word.ID]);


    if (!existWord) {
        return (
            <div className={styles.buttonSection}>
                <button onClick={() => handleShouldLearn()} >Should learn</button>
                <button onClick={() => handleAlreadyKnew()} className='turquoiseButton'>Already knew</button>
            </div>
        );
    }

    return (
        <section className={styles.selectSection}>
            <div className={styles.labelSection}>
                <span className={styles.label}>Your knowledge:</span>
                <div onClick={handleRemove}
                    className={`${styles.styledButton} ${existWord === Actions.Knew ? styles.knew : styles.learn}`}>
                    {existWord === Actions.Knew ? 'Already Knew' : 'Should Learn'} ▼
                </div>
            </div>
            <button className={styles.learnNowButton} onClick={handleLearnNowContinue}>
                {existWord === Actions.Knew ? 'Continue' : 'Learn Now'}
            </button>
        </section>

    );
};
