import { useMemo } from 'react';
import { useLearning } from 'src/hook/data/useLearning';
import { useScenario } from 'src/hook/data/useScenario';
import { Lesson } from '../../model/Scenario';
import Spinner from '../ui/Features/Spinner/Spinner';
import styles from './Lessons.module.scss';
import { LessonsItem } from './LessonsItem/LessonsItem';

const Lessons = () => {

    const { learningInfo } = useLearning();
    const { scenarios, scenarioQAs, isLoading, findFirstIncompleteLesson } = useScenario();

    // Cache the firstIncompleteId using useMemo
    const firstIncompleteId = useMemo(() => {
        if (!scenarios?.categories || !learningInfo) return null;
        return findFirstIncompleteLesson();
    }, [scenarios?.categories, learningInfo, findFirstIncompleteLesson]);

    if (isLoading) return <Spinner />;

    return (
        <article className={styles.lessons}>
            {scenarios?.categories?.map((category: Lesson) => (
                <div key={category.cat} className={styles.section}>
                    <h1 className={styles.title}>{category.cat}</h1>
                    <div className={styles.itemsContainer}>
                        {category.ctx.map((ctx, ctxIndex) => (
                            <LessonsItem
                                key={ctxIndex}
                                ctx={ctx}
                                category={category}
                                firstIncompleteId={firstIncompleteId}
                                questions={scenarioQAs?.filter(q => q.r === `${category.ref}${ctx.ref}`) || []}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </article>
    );
}

export default Lessons;