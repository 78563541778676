import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLearning } from 'src/hook/data/useLearning';
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { Ctx, Lesson, Question } from '../../../model/Scenario';
import { IconLocation, ScenariosLocation } from '../../../utils/URLUtils';
import styles from './LessonsItem.module.scss';


interface LessonsItemProps {
    ctx: Ctx;
    category: Lesson;
    firstIncompleteId: string | null;
    questions: Question[];
}


export const LessonsItem = ({ ctx, category, firstIncompleteId, questions }: LessonsItemProps) => {
    const navigate = useNavigate();
    const { learningInfo } = useLearning();
    const questionId = `${category.ref}${ctx.ref}`;
    const [isFirstIncomplete, setIsFirstIncomplete] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [completedCount, setCompletedCount] = useState<number | null>(null);

    const handleClick = () => {
        navigate(`/lessons/lesson?id=${questionId}`);
    };

    const fetchData = useCallback(async () => {
        setIsFirstIncomplete(questionId === firstIncompleteId);

        const isCompleted = learningInfo?.Lessons.CompletedContext.includes(questionId) || false;
        setIsCompleted(isCompleted);

        if (isCompleted) {
            setCompletedCount(questions.length);
            return;
        }

        const conversations = learningInfo?.Lessons.Conversations || [];
        let completed = 0;

        for (const question of questions) {
            const hash = await ToIOSafeHash(question.q);
            if (conversations.some(conv => conv.includes(hash))) {
                completed++;
            }
        }
        setCompletedCount(completed);

    }, [learningInfo?.Lessons, questionId, firstIncompleteId, questions]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);




    return (
        <section className={styles.lessonsItem} onClick={handleClick}>
            <div className={styles.lessonsItemContent}>
                <img className={styles.lessonsItemImage} src={ScenariosLocation(`${category.ref}${ctx.ref}`)} alt={ctx.name} />
                <div className={styles.lessonsItemContentText}>
                    <h2>{ctx.name}</h2>
                    <p>{completedCount} of {questions.length} completed</p>
                </div>
            </div>
            <div className={`${styles.lessonsItemContentButton} ${isFirstIncomplete ? styles.firstLesson : isCompleted ? styles.completed : ''}`}>
                <img src={IconLocation(isCompleted ? 'check' : 'chevron-right')} alt="Button" />
            </div>
        </section>
    );
}