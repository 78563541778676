import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../../hook/data/useUser';
import { useIFrameModal } from '../../../hook/modal/useIFrameModal';
import { PathName } from '../../../model/enum/enums';
import { GetRazorSiteUrl, IconLocation, ImageLocation } from '../../../utils/URLUtils';
import MainNavigation from '../MainNavigation/MainNavigation';
import { ConvertURL } from './ConvertURL';
import styles from './Header.module.scss';
import { LearnProgressChart } from './LearnProgressChart/LearnProgressChart';

const Header = () => {
    const [showNavigation, setShowNavigation] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [title, setTitle] = useState('');
    const location = useLocation();
    const { userInfo } = useUser();
    const { open } = useIFrameModal();

    const toggleNavigation = () => {
        setShowNavigation(prevState => !prevState);
    };

    useEffect(() => {
        setTitle(ConvertURL(location.pathname));
        setIsProgress(location.pathname === PathName.progress);
    }, [location.pathname]);

    return (
        <section className={styles.header + ' ' + (isProgress ? styles.progressBar : '')} >
            {showNavigation && <MainNavigation toggleNavigation={toggleNavigation} show={showNavigation} />}
            <img className={styles.menu} src={IconLocation('menu', isProgress ? true : false)} alt="Menu" onClick={toggleNavigation} />
            {!isProgress && <h1 className={styles.headerTitle}> {title} </h1>}
            {isProgress &&
                <div className={styles.progressContainer}>
                    <img className={styles.progressImage} src={ImageLocation('logo.webp')} alt="logo" />
                </div>
            }
            {location.pathname === PathName.wordlists &&
                <img className={styles.logo} onClick={() => open(GetRazorSiteUrl('discover/word-list-fullmap', userInfo?.Token), 'Full knowledge map')}
                    src={IconLocation('idiomslogo')} alt="Logo" />}

            {location.pathname === PathName.learn
                && <LearnProgressChart />}
        </section>

    );
}

export default Header;