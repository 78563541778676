import { useCallback, useEffect, useRef, useState } from 'react';
import LessonLottie from 'src/components/ui/GlobalComponents/LessonLottie/LessonLottie';
import { useAudio } from 'src/hook/global/useAudio';
import useAudioPlayer from 'src/hook/global/useAudioPlayer';
import { isEqualNormalizeText } from 'src/utils/NormalizeText';
import { GetSoundUrl, IconLocation } from 'src/utils/URLUtils';
import { getVoice } from 'src/utils/VoiceUtils';
import { Question } from '../../../../model/Scenario';
import type { SpeechRecognitionEvent } from '../../../../model/Window';
import styles from './FourthWizard.module.scss';

declare global {
    interface Window {
        SpeechRecognition: any;
        webkitSpeechRecognition: any;
    }
}
type SpeechRecognition = any;

interface Props {
    question: Question;
    onContinue: () => void;
    answer?: string;
    onPlay: () => void;
    voice?: string;
}

const FourthWizard = ({ question, onContinue, answer, onPlay, voice }: Props) => {
    const { play } = useAudioPlayer();
    const [played, setPlayed] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [spokenText, setSpokenText] = useState('');
    const recognitionRef = useRef<SpeechRecognition | null>(null);
    const [permissionError, setPermissionError] = useState<string>('');
    const [isCorrect, setIsCorrect] = useState(false);
    const [showWrongAnimation, setShowWrongAnimation] = useState(false);
    const { playAudio } = useAudio();
    const title = answer ? answer : question.q;
    const [skipDisabled, setSkipDisabled] = useState(true);


    const handleSpeakerClick = useCallback(() => {
        const voicePerson = getVoice(voice, 0.8);
        if (answer)
            play(title, voicePerson);
        else
            onPlay();
    }, [title, play, answer, onPlay, voice]);

    // Stop recording function to reuse
    const stopRecording = useCallback(() => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
        setIsRecording(false);
    }, []);

    const capitalizeAndPunctuate = (text: string) => {
        let processed = text.trim();
        // Capitalize first letter
        processed = processed.charAt(0).toUpperCase() + processed.slice(1);

        // Add punctuation if missing
        const questionEnding = title.slice(-1);
        if (['.', '?'].includes(questionEnding) && !['.', '?'].includes(processed.slice(-1))) {
            processed += questionEnding;
        }
        return processed;
    };

    const handleMicrophoneClick = () => {
        if (!isRecording) {
            setSpokenText('');
            setIsCorrect(false);
            setIsRecording(true);
            if (recognitionRef.current) {
                try {
                    recognitionRef.current.start();
                } catch (error) {
                    console.error('Error starting recognition:', error);
                    stopRecording();
                }
            }
        } else {
            stopRecording();
        }
    };

    const handleCheck = () => {
        const isAnswerCorrect = isEqualNormalizeText(spokenText, title);


        if (!isAnswerCorrect) {
            setShowWrongAnimation(true);
            setTimeout(() => setShowWrongAnimation(false), 500);
            playAudio(GetSoundUrl('IncorrectAnswer'));
        }
        else {
            setIsCorrect(isAnswerCorrect);
            playAudio(GetSoundUrl('CorrectAnswer'));
        }
    };

    useEffect(() => {
        // Check and request microphone access when component mounts
        const requestMicrophoneAccess = async () => {
            try {
                // First check if we already have permission
                const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });

                if (permissionStatus.state === 'denied') {
                    setPermissionError('Microphone access was denied. Please reset permissions in your browser settings and refresh the page.');
                    return;
                }

                // Try to get microphone access
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setPermissionError('');

                // Stop the stream immediately since we only needed it for permission
                stream.getTracks().forEach(track => track.stop());

                // Initialize speech recognition after getting microphone access
                const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
                if (SpeechRecognition) {
                    recognitionRef.current = new SpeechRecognition();
                    recognitionRef.current.continuous = false;
                    recognitionRef.current.interimResults = false;
                    recognitionRef.current.lang = 'en-US';

                    recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
                        const transcript = event.results[0][0].transcript;
                        const processedText = capitalizeAndPunctuate(transcript);
                        setSpokenText(processedText);
                        stopRecording();

                        // Check if correct immediately after recognition
                        const isAnswerCorrect = processedText.toLowerCase().trim() === title.toLowerCase().trim();
                        setIsCorrect(isAnswerCorrect);
                    };

                    recognitionRef.current.onerror = (event: any) => {
                        console.error('Speech recognition error:', event);
                        if (event.error === 'not-allowed') {
                            setPermissionError('Please allow microphone access to use this feature. You may need to reset permissions in your browser settings.');
                        }
                        stopRecording();
                    };

                    recognitionRef.current.onend = () => {
                        stopRecording();
                    };
                } else {
                    setPermissionError('Speech Recognition is not supported in this browser');
                }
            } catch (error) {
                console.error('Failed to get microphone access:', error);
                setPermissionError(`Microphone access is required.`);
            }
        };

        requestMicrophoneAccess();

        // Cleanup
        return () => {
            stopRecording();
        };
    }, [stopRecording]);

    // Watch for correct answers and stop recording
    useEffect(() => {
        if (isCorrect) {
            stopRecording();
        }
    }, [isCorrect, stopRecording]);


    useEffect(() => {
        if (!played) {
            handleSpeakerClick();

            setPlayed(true);
            setTimeout(() => {
                setSkipDisabled(false);
            }, 3000);
        }
    }, [title, play, played, onPlay, answer, handleSpeakerClick]);




    return (
        <section className={styles.fourthWizard}>
            <img src={IconLocation('speaker')} onClick={handleSpeakerClick}
                alt='speaker' className={styles.speaker} />
            <h1 className={styles.questionText}>{title}</h1>

            <div className={`${styles.spokenTextContainer} ${showWrongAnimation ? styles.wrong : ''}`}>
                <p className={`
                    ${!spokenText ? styles.placeholder : ''}
                    ${isRecording ? styles.recording : ''}
                    ${isCorrect ? styles.correct : ''}
                `}>
                    {spokenText || '...'}
                </p>
            </div>

            {permissionError && (
                <div className={styles.errorMessage}>
                    {permissionError}
                </div>
            )}

            {!isCorrect && <img
                src={IconLocation('microphone')}
                onClick={handleMicrophoneClick}
                alt='microphone'
                className={`${styles.microphone} ${isRecording ? styles.recording : ''}`}
            />}

            {isCorrect && (
                <LessonLottie />
            )}
            <div className={styles.buttonSections}>
                {!isCorrect && <button className='secondaryButton' onClick={onContinue}
                    disabled={skipDisabled}>Skip</button>}
                <button
                    className={styles.checkButton}
                    onClick={isCorrect ? onContinue : handleCheck}
                    disabled={!spokenText}
                >
                    {isCorrect ? 'Continue' : 'Check'}
                </button>
            </div>
        </section >
    );
};

export default FourthWizard;
