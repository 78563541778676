import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { LoadingStatus } from "../../model/enum/enums";
import { Social } from '../../model/Social';
import { fetchSocialData, socialActions } from '../../store/slices/socialSlice';
import { appDispatch, RootState } from '../../store/store';

export const useSocial = (autoLoad: boolean = true) => {
    const initialLoadDone = useRef(false);
    // Memoize bound actions
    const ac = useMemo(
        () => bindActionCreators({
            ...socialActions,
            fetchSocialData
        }, appDispatch),
        []
    )

    const { socialInfo, status, error } = useSelector((state: RootState) => state.social);

    // Load social data from storage on mount if not already loaded
    useEffect(() => {
        if (autoLoad && !socialInfo) {
            ac.getSocial({});
        }
    }, [ac, socialInfo, autoLoad]);

    const fetchSocialInfo = useCallback(async (userId: string, countryCode: string = 'GB') => {
        if (initialLoadDone.current) return; // Prevent multiple fetches
        try {
            ac.fetchSocialData({ userId, countryCode });
            initialLoadDone.current = true;
        } catch (error) {
            console.error('Error fetching translates:', error);
        }
    }, [ac]);

    const updateSocialInfo = useCallback((info: Partial<Social>) => {
        ac.updateSocial(info);
    }, [ac]);

    const hasFriends = useCallback(() => {
        return !!socialInfo?.Friends?.length;
    }, [socialInfo?.Friends]);

    const hasNearbyPeople = useCallback(() => {
        return !!socialInfo?.PeopleNearBy?.length;
    }, [socialInfo?.PeopleNearBy]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    return useMemo(() => ({
        socialInfo,
        status,
        error,
        fetchSocialInfo,
        updateSocialInfo,
        hasFriends,
        hasNearbyPeople,
        isLoading: status === LoadingStatus.LOADING,
        isError: status === LoadingStatus.FAILED,
        isSuccess: status === LoadingStatus.SUCCEEDED,
        handleLogout
    }), [
        socialInfo,
        status,
        error,
        fetchSocialInfo,
        updateSocialInfo,
        hasFriends,
        hasNearbyPeople,
        handleLogout
    ]);
};