import { useCallback, useEffect, useState } from 'react';
import useUser from 'src/hook/data/useUser';
import { GetSpecificVoiceUrl, GetSpeechUrl } from '../../utils/URLUtils';

// Add this outside the hook to share across all instances
const globalAudioRef = new Audio();
// Add a custom event for audio start
const AUDIO_START_EVENT = 'audioPlayerStart';

// Add a global array to track all playing states
type PlayingState = {
    id: string;
    setIsPlaying: (playing: boolean) => void;
};
const playingStates: PlayingState[] = [];

// Remove global listeners since we'll handle state individually
globalAudioRef.onended = () => {
    globalAudioRef.src = '';
    // Update all states to false when audio ends
    playingStates.forEach(state => state.setIsPlaying(false));
};

const useAudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const { userInfo } = useUser();

    // Generate a unique ID for this instance
    const [instanceId] = useState(() => Math.random().toString(36).substr(2, 9));

    // Register this instance's state setter
    useEffect(() => {
        const stateEntry: PlayingState = {
            id: instanceId,
            setIsPlaying
        };
        playingStates.push(stateEntry);

        return () => {
            const index = playingStates.findIndex(state => state.id === instanceId);
            if (index !== -1) {
                playingStates.splice(index, 1);
            }
        };
    }, [instanceId]);

    // Listen for audio start events from other instances
    useEffect(() => {
        const handleAudioStart = (event: CustomEvent) => {
            // If this isn't the instance that started playing, set its state to false
            if (!event.detail?.isSource) {
                setIsPlaying(false);
            }
        };

        // Add event listener
        window.addEventListener(AUDIO_START_EVENT, handleAudioStart as EventListener);

        return () => {
            window.removeEventListener(AUDIO_START_EVENT, handleAudioStart as EventListener);
        };
    }, []);

    // Update the play function to manage individual state
    const play = useCallback(async (text: string, voice: string = '', checkAutoPronounce: boolean = false) => {
        if (checkAutoPronounce === true) {
            if (userInfo?.AutoPronounce === false) return;
        }

        try {
            globalAudioRef.pause();
            globalAudioRef.src = '';

            // Set all other states to false
            playingStates.forEach(state => {
                if (state.id !== instanceId) {
                    state.setIsPlaying(false);
                }
            });

            const link = voice
                ? GetSpecificVoiceUrl(voice, text)
                : GetSpeechUrl(userInfo?.Voice || 'gb', text);

            setIsPlaying(true);

            try {
                const response = await fetch(link);
                const audioUrl = await response.text();

                globalAudioRef.src = audioUrl;
                await globalAudioRef.play();

                globalAudioRef.onended = () => {
                    playingStates.forEach(state => state.setIsPlaying(false));
                };
            } catch (e) {
                setIsPlaying(false);
            }
        } catch (e) {
            setIsPlaying(false);
        }
    }, [userInfo, instanceId]);

    const pause = useCallback(() => {
        globalAudioRef.pause();
        playingStates.forEach(state => state.setIsPlaying(false));
    }, []);

    const stop = useCallback(() => {
        globalAudioRef.pause();
        globalAudioRef.currentTime = 0;
        playingStates.forEach(state => state.setIsPlaying(false));
    }, []);

    return {
        audioRef: globalAudioRef,
        isPlaying,
        play,
        pause,
        stop
    };
};

export default useAudioPlayer; 