import { useBackHandler } from 'src/hook/global/useBackHandler';
import { ChevronType } from 'src/model/enum/enums';
import { Chevron } from '../../Features/Chevron/Chevron';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
    title: string;
    backUrl?: string;
    handleBackClick?: () => void;
    forceUrl?: boolean;
}

export const PageHeader = ({ title, backUrl, handleBackClick, forceUrl }: PageHeaderProps) => {
    const handleBack = useBackHandler();
    const back = () => {
        if (handleBackClick) {
            handleBackClick();
        } else {
            handleBack(backUrl, forceUrl);
        }
    }
    return (
        <section className={styles.pageHeader}>
            <Chevron type={ChevronType.Left} onClick={back} size={40} />
            <h1>{title}</h1>
        </section>
    );
};
