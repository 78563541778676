import pako from 'pako';
import { useCallback, useState } from 'react';
import { HttpMethod, ResponseType } from '../../model/enum/enums';

interface FetchResponse<T> {
    response: T | null;
    errorMessage: string | null;
}

interface UseFetchResult<T> {
    send: (url: string, method?: HttpMethod, body?: any, responseType?: ResponseType) => Promise<FetchResponse<T>>;
    sendChain: (requests: Array<{ url: string, method?: HttpMethod, body?: any, responseType?: ResponseType }>) => Promise<FetchResponse<T>[]>;
    sendSequentialChain: (
        requests: Array<{
            url: string | ((prevResult: any) => string),
            method?: HttpMethod,
            body?: any | ((prevResult: any) => any),
            responseType?: ResponseType
        }>
    ) => Promise<FetchResponse<T>[]>;

    isLoading: boolean;
}



function useFetch<T>(): UseFetchResult<T> {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const send = useCallback(async (
        url: string,
        method: HttpMethod = HttpMethod.GET,
        body?: any,
        responseType: ResponseType = ResponseType.Json,
        isChain: boolean = false
    ): Promise<FetchResponse<T>> => {
        if (!isChain) setIsLoading(true);

        try {
            const fetchOptions: RequestInit = {
                method
            };

            if (['POST', 'PUT', 'PATCH'].includes(method) && body) {
                fetchOptions.body = JSON.stringify(body);
                fetchOptions.headers = {
                    'Content-Type': 'application/json',
                };
            }

            const fetchResponse = await fetch(url, fetchOptions);
            if (!fetchResponse.ok) {
                throw new Error(`HTTP error! status: ${fetchResponse.status}`);
            }

            let response;
            if (url.includes('.gz')) {
                const arrayBuffer = await fetchResponse.arrayBuffer();
                const decompressed = pako.inflate(arrayBuffer, { to: 'string' });
                response = JSON.parse(decompressed);
            } else if (responseType === ResponseType.Json) {
                response = await fetchResponse.json();
            } else {
                response = await fetchResponse.text();
            }

            return { response: response as T, errorMessage: null };
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'An unknown error occurred';
            console.error(errorMessage);
            return { response: null, errorMessage };
        } finally {
            if (!isChain) setIsLoading(false);
        }
    }, []);

    const sendChain = useCallback(async (
        requests: Array<{
            url: string,
            method?: HttpMethod,
            body?: any,
            responseType?: ResponseType
        }>
    ): Promise<FetchResponse<T>[]> => {
        setIsLoading(true);
        try {
            const results = await Promise.all(
                requests.map(request =>
                    send(request.url, request.method, request.body, request.responseType, true)
                )
            );
            return results;
        } finally {
            setIsLoading(false);
        }
    }, [send]);

    const sendSequentialChain = useCallback(async (
        requests: Array<{
            url: string | ((prevResult: any) => string),
            method?: HttpMethod,
            body?: any | ((prevResult: any) => any),
            responseType?: ResponseType
        }>
    ): Promise<FetchResponse<T>[]> => {
        setIsLoading(true);
        const results: FetchResponse<T>[] = [];

        try {
            for (const request of requests) {
                const prevResult = results.length > 0 ? results[results.length - 1].response : null;

                const resolvedUrl = typeof request.url === 'function'
                    ? request.url(prevResult)
                    : request.url;

                const resolvedBody = typeof request.body === 'function'
                    ? request.body(prevResult)
                    : request.body;

                const result = await send(
                    resolvedUrl,
                    request.method,
                    resolvedBody,
                    request.responseType,
                    true
                );

                results.push(result);

                // Stop the chain if there's an error
                if (result.errorMessage) {
                    break;
                }
            }

            return results;
        } finally {
            setIsLoading(false);
        }
    }, [send]);

    return { send, sendChain, sendSequentialChain, isLoading };
}
export default useFetch;