import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResponseType } from 'src/model/enum/enums';
import { CustomListData } from 'src/model/WordList';
import { LevenshteinDistance } from 'src/utils/TextUtils';
import { Word, WordSense, WordType } from '../../model/Word';
import { setWords } from '../../store/slices/wordsSlice';
import { AppDispatch, RootState } from '../../store/store';
import { GetResourcesUrl } from '../../utils/URLUtils';
import { parseWordsFile } from '../../utils/WordParser';
import useFetch from '../api/useFetch';
import useUser from './useUser';

export const useWords = (autoLoad: boolean = true) => {
  const dispatch = useDispatch<AppDispatch>();
  const words = useSelector((state: RootState) => state.words.words);
  const { sendChain } = useFetch<string>();
  const { userInfo } = useUser();

  const fetchWords = useCallback(async (rankingsUrl?: string) => {
    const result = await sendChain([{
      url: GetResourcesUrl('Words.txt'),
      responseType: ResponseType.Text
    }, {
      url: rankingsUrl || userInfo?.RankingsUrl || '',
      responseType: ResponseType.Text
    }]);

    if (!result[0]?.response) return;
    const parsedWords = parseWordsFile(result[0].response, result[1]?.response);

    dispatch(setWords(parsedWords));
  }, [dispatch, sendChain, userInfo?.RankingsUrl]);

  useEffect(() => {
    if (autoLoad && !words.length) {
      fetchWords();
    }
  }, [autoLoad, words.length, fetchWords]);

  const getWordByRank = useCallback((rank: number, type: WordType = WordType.Word) => {
    return words.find(word => word.Rank === rank && word.Type === type);
  }, [words]);

  const getWordById = useCallback((id: number) => {
    return words.find(word => word.ID === id);
  }, [words]);

  const getWordByText = useCallback((text: string) => {
    return words.find(word => word.Text.toLowerCase() === text.toLowerCase());
  }, [words]);

  const searchWords = useCallback((query: string) => {
    const lowercaseQuery = query.toLowerCase();
    return words.filter(word =>
      word.Text.toLowerCase().startsWith(lowercaseQuery) ||
      word.AllForms.some(form => form.toLowerCase().startsWith(lowercaseQuery))
    );
  }, [words]);

  const getWordsByRange = useCallback((range: number = 1) => {
    const startRank = ((range - 1) * 100) + 1;
    const endRank = startRank + 99;

    return words
      .filter(word => {
        const rank = word.Rank;
        return rank >= startRank && rank <= endRank && word.Type === WordType.Word;
      })
      .sort((a, b) => a.Rank - b.Rank);
  }, [words]);

  const getWordsByWordList = useCallback((filterWords: number[], range: number = 0) => {
    if (!filterWords || filterWords.length === 0) return [];

    let rangeIds = range !== 0
      ? filterWords.slice((range - 1) * 100, (range - 1) * 100 + 100)
      : filterWords;

    const filteredWords = words
      .filter(word => rangeIds.includes(word.ID))
      .sort((a, b) => {
        const indexA = filterWords.indexOf(a.ID);
        const indexB = filterWords.indexOf(b.ID);
        return indexA - indexB;
      });

    return range === 0 ? filteredWords.slice(0, 100) : filteredWords;
  }, [words]);

  const getWordsByCustomList = useCallback((filterWords: CustomListData[], range: number = 0) => {
    if (!filterWords || filterWords.length === 0) return [];
    let rangeIds = range !== 0
      ? filterWords.slice((range - 1) * 100, (range - 1) * 100 + 100)
      : filterWords;

    const filteredWords = words
      .filter(word => rangeIds.some(fw => fw.WordId === word.ID))
      .sort((a, b) => {
        const indexA = filterWords.findIndex(fw => fw.WordId === a.ID);
        const indexB = filterWords.findIndex(fw => fw.WordId === b.ID);
        return indexA - indexB;
      });

    return range === 0 ? filteredWords.slice(0, 100) : filteredWords;
  }, [words]);

  const getWordSensesByWordList = useCallback((filterWords: number[]) => {
    if (!filterWords || filterWords.length === 0) return [];

    return words
      .filter(word => filterWords.includes(word.ID))
      .map(word => ({
        SenseId: word.PrimarySenseId,
        Word: word
      } as WordSense))
      .sort((a, b) => {
        const indexA = filterWords.indexOf(a.Word.ID);
        const indexB = filterWords.indexOf(b.Word.ID);
        return indexA - indexB;
      });
  }, [words]);

  const getWordSensesByCustomList = useCallback((filterWords: CustomListData[]) => {
    if (!filterWords || filterWords.length === 0) return [];
    return words
      .filter(word => filterWords.some(fw => fw.WordId === word.ID))
      .map(word => {
        const customWord = filterWords.find(fw => fw.WordId === word.ID);
        return {
          SenseId: customWord?.SenseId || word.PrimarySenseId,
          Word: word
        } as WordSense;
      })
      .sort((a, b) => {
        const indexA = filterWords.findIndex(fw => fw.WordId === a.Word.ID);
        const indexB = filterWords.findIndex(fw => fw.WordId === b.Word.ID);
        return indexA - indexB;
      });
  }, [words]);

  const findClosestWord = useCallback((searchText: string): Word | undefined => {
    if (!searchText || !words.length) return undefined;

    const searchLower = searchText.toLowerCase().trim();

    // First try exact match
    const exactMatch = words.find(word =>
      word.Text.toLowerCase() === searchLower ||
      word.AllForms.some(form => form.toLowerCase() === searchLower)
    );
    if (exactMatch) return exactMatch;

    // Set maximum allowed Levenshtein distance based on word length
    const maxAllowedDistance = Math.min(
      Math.floor(searchLower.length * 0.3), // Allow up to 30% difference
      3 // But never more than 3 characters different
    );

    let closestWord: Word | undefined;
    let minDistance = Infinity;

    words.forEach(word => {
      // Check main word form
      const distance = LevenshteinDistance(searchLower, word.Text.toLowerCase());
      if (distance < minDistance && distance <= maxAllowedDistance) {
        minDistance = distance;
        closestWord = word;
      }

      // Check alternate forms
      word.AllForms.forEach(form => {
        const formDistance = LevenshteinDistance(searchLower, form.toLowerCase());
        if (formDistance < minDistance && formDistance <= maxAllowedDistance) {
          minDistance = formDistance;
          closestWord = word;
        }
      });
    });

    return closestWord;
  }, [words]);

  return {
    words,
    fetchWords,
    findClosestWord,
    getWordById,
    getWordByText,
    getWordByRank,
    searchWords,
    getWordsByRange,
    getWordsByWordList,
    getWordsByCustomList,
    getWordSensesByWordList,
    getWordSensesByCustomList
  };
};