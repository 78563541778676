export enum UserFields {
    Token = "Token",
    Email = "Email",
    UserId = "UserId",
    Pro = "Pro",
    LanguageLevel = "LanguageLevel",
    AutoPronounce = "AutoPronounce",
    BirthYear = "BirthYear",
    BirthDate = "BirthDate",
    CardBackground = "CardBackground",
    ChallengeTypes = "ChallengeTypes",
    Examples = "Examples",
    FirstName = "FirstName",
    LastName = "LastName",
    ForceToBasic = "ForceToBasic",
    DarkMode = "DarkMode",
    GoalMinsPerDay = "GoalMinsPerDay",
    NativeLanguageCode = "NativeLanguageCode",
    NextSecondaryEmailPopupVisit = "NextSecondaryEmailPopupVisit",
    ReminderTimes = "ReminderTimes",
    SeeTranslations = "SeeTranslations",
    ShowHardWordBanner = "ShowHardWordBanner",
    TranslateUI = "TranslateUI",
    Voice = "Voice",
    PlaySound = "PlaySound",
    Casing = "Casing",
    AgeGroup = "AgeGroup",
    LearningGoals = "LearningGoals",
    LastTicket = "LastTicket",
    RankingsUrl = "RankingsUrl",
    CountryCode = "CountryCode",
    SelectedRange = "SelectedRange",
    RecentSearches = "RecentSearches",
}