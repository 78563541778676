import { FC } from 'react';
import useUser from '../../../../hook/data/useUser';
import { useIFrameModal } from '../../../../hook/modal/useIFrameModal';
import { FriendSummaryData } from '../../../../model/Social';
import { GetRazorSiteUrl, IconLocation, ImageLocation } from '../../../../utils/URLUtils';
import styles from './FriendsItem.module.scss';


interface FriendsItemProps {
    friend: FriendSummaryData;
    index: number;
}

export const FriendsItem: FC<FriendsItemProps> = ({ friend, index }) => {
    const { open } = useIFrameModal();
    const { userInfo } = useUser();

    const handleClick = () => {
        if (friend.DisplayName !== 'You') {
            open(GetRazorSiteUrl(`profile/friend`, userInfo?.Token, `&id=${friend.Handle}`), friend.DisplayName!);
        }
    };

    return (
        <div onClick={handleClick}
            className={styles.friendsItem + ' ' + (friend.DisplayName === 'You' ? styles.you : '')}>
            <div className={styles.avatar}>
                <span className={`${styles.avatarIndex} ${friend.DisplayName === 'You' && index === 19 ? styles.hidden : ''}`}>
                    {index + 1}
                </span>
                <img
                    src={friend.Avatar ?? ImageLocation('Profile-3.png')}
                    onError={(e) => {
                        e.currentTarget.src = ImageLocation('Profile-3.png');
                    }}
                    alt={friend.DisplayName ?? 'User'} />
                <span className={styles.name}>{friend.DisplayName}</span>
            </div>
            <div className={styles.progress}>
                <div className={styles.progressTime}>
                    <span className={styles.progressTimeValue}>{friend.CurrentWeekProgress}</span>
                    <span className={styles.progressTimeUnit}>min</span>
                </div>
                <img src={IconLocation('chevron-right')} alt='Right' />
            </div>
        </div>
    );
};