import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNote } from 'src/hook/components/useNote';
import { useCountries } from 'src/hook/data/useCountries';
import { useLearning } from 'src/hook/data/useLearning';
import { useServiceWorker } from 'src/hook/global/useServiceWorker';
import { GetStartPageUrl } from 'src/utils/URLUtils';
import useUser from '../../hook/data/useUser';
import { useWords } from '../../hook/data/useWords';
import { useWordTranslate } from '../../hook/data/useWordTranslate';
import ErrorMessage from '../ui/Features/Error/Error';
import Spinner from '../ui/Features/Spinner/Spinner';
import { RoutesPage } from './RoutesPage';

const Load: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { fetchUserData, isAuthenticated, isUserLoaded } = useUser();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('t');
    const { fetchWords } = useWords(false);
    const { fetchCountries } = useCountries(false);
    const { fetchTranslates } = useWordTranslate(false);
    const { registerWorker } = useServiceWorker();
    const { fetchNoteData } = useNote(false);
    //   const { fetchSocialInfo } = useSocial();
    const { fetchLearningData } = useLearning();


    const initializeApp = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            const user = await fetchUserData(token!);
            await Promise.all([
                fetchLearningData(token!),
                //  fetchSocialInfo(user?.UserId!, user?.CountryCode),
                fetchNoteData(user?.UserId),
                fetchWords(user?.RankingsUrl),
                fetchCountries(false),
                fetchTranslates(user?.NativeLanguageCode),
                registerWorker()
            ]);



            // const home = datUser?.LanguageLevel === LanguageLevel.Basic ? 'lessons' : 'wordlists';
            // navigate(`/${home}`);

        } catch (error) {
            console.log('error', error);
            //   window.location.href = GetStartPageUrl();
            setError(error as string);
        } finally {
            setIsLoading(false);
        }
    }, [registerWorker, token,
        fetchWords, fetchCountries, fetchTranslates,
        fetchNoteData,
        fetchLearningData, fetchUserData, isLoading]);


    useEffect(() => {
        if (token) {
            initializeApp();
        }
    }, []);


    useEffect(() => {
        if (!token && isUserLoaded && !isAuthenticated()) {
            window.location.href = GetStartPageUrl();
        }
    }, [token, isUserLoaded, isAuthenticated]);


    if (isLoading || !isAuthenticated()) return <Spinner fullPage={true} />;
    else if (error) return <ErrorMessage message={error} />;
    else return <RoutesPage />;
};

export default memo(Load);