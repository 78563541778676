import { FC } from 'react';
import Lottie from 'react-lottie-player';
import { GetWellDoneLottieUrl } from 'src/utils/URLUtils';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import { CapitalizeFirstLetter } from '../../../../utils/TextUtils';
import ButtonSection from './ButtonSection/ButtonSection';
import styles from './MainSection.module.scss';

interface MainSectionProps {
    word: Word;
    wordData: WordData;
    translateWord: string;
}

const MainSection: FC<MainSectionProps> = ({ word, wordData, translateWord }) => {

    return (
        <div className={styles.mainSection}>

            <div className={styles.lottieContainer}>
                <Lottie
                    path={GetWellDoneLottieUrl()}
                    loop={true}
                    play
                    className={styles.lottie}
                />
            </div>

            {translateWord && <h1 className={styles.translateWord}>{translateWord}</h1>}
            <h2 className={styles.otherMeanings}>
                {`'${CapitalizeFirstLetter(word.Text)}' has ${wordData?.Senses?.length - 1} other meanings`}</h2>
            <ButtonSection word={word} wordData={wordData} />
        </div>
    );
};

export default MainSection;