import { Navigate, Route, Routes } from 'react-router-dom';
import { AddWordToCustomList } from '../CustomLists/AddWordToCustomList/AddWordToCustomList';
import { CreateCustomList } from '../CustomLists/CreateCustomList/CreateCustomList';
import { ImportWords } from '../CustomLists/ImportWords/ImportWords';
import Discover from '../Discover/Discover';
import { DiscoverPage } from '../Discover/DiscoverPage/DiscoverPage';
import { WordListPage } from '../Discover/WordListPage/WordListPage';
import Learn from '../Learn/Learn';
import { LessonPage } from '../Lessons/LessonPage/LessonPage';
import Lessons from '../Lessons/Lessons';
import Practice from '../Practice/Practice';
import Progress from '../Progress/Progress';
import { Search } from '../Search/Search';
import { LogOut } from './LogOut';

const RoutesPage = () => {
    return (
        <Routes>
            <Route index element={<Navigate to="/wordlists" replace />} />
            <Route path='practice' element={<Practice />} />
            <Route path='lessons' element={<Lessons />} />
            <Route path='wordlists' element={<Discover />} />
            <Route path='learn' element={<Learn />} />
            <Route path='progress' element={<Progress />} />
            <Route path='wordlists/discover' element={<DiscoverPage />} />
            <Route path='wordlists/wordlist' element={<WordListPage />} />
            <Route path='lessons/lesson' element={<LessonPage />} />
            {/* <Route path='lessons/answers' element={<Answers />} />   */}
            {/* <Route path='lessons/lesson/lessonwizard' element={<LessonWizard />} /> */}
            {/* <Route path='*' element={<HomePage />} /> */}
            <Route path='*' element={<Discover />} />
            <Route path='search' element={<Search />} />
            <Route path='logout' element={<LogOut />} />
            <Route path='wordlists/create-new' element={<CreateCustomList />} />
            <Route path='wordlists/add-words' element={<AddWordToCustomList />} />
            <Route path='wordlists/import-words' element={<ImportWords />} />
        </Routes>
    );
}

export { RoutesPage };

