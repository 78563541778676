import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageHeader } from 'src/components/ui/GlobalComponents/PageHeader/PageHeader';
import { useLearning } from 'src/hook/data/useLearning';
import { useWordList } from 'src/hook/data/useWordList';
import { useToast } from 'src/hook/global/useToasts';
import { WordList } from 'src/model/WordList';
import { ButtonSection } from './ButtonSection/ButtonSection';
import { Search } from './Search/Search';

export const AddWordToCustomList = () => {
    const [searchParams] = useSearchParams();
    const listId = searchParams.get('id');
    const { learningInfo } = useLearning();
    const [currentList, setCurrentList] = useState<WordList | undefined>(undefined);
    const navigate = useNavigate();
    const toast = useToast();
    const { removeCustomList } = useWordList();


    useEffect(() => {
        if (listId) {
            const list = learningInfo?.WordLists
                .find(list => list.ID === listId);
            setCurrentList(list);
        }
    }, [listId, learningInfo]);

    const save = () => {
        handleBack();
        setTimeout(() => {
            toast.showSuccess('List updated.');
        }, 1000);
    }

    const handleBack = () => {
        if (currentList?.CustomListData?.length) {
            navigate(`/wordlists/wordlist?id=${currentList?.ID}`);
        } else {
            removeCustomList(currentList!);
            navigate(`/wordlists`);
        }
    }


    if (!currentList) return null;


    return (
        <section className='fullPage'>
            <PageHeader title={`Add to ${currentList?.Title}`}
                handleBackClick={handleBack} />
            <Search currentList={currentList} />
            <ButtonSection onAdd={save} />

        </section>
    );
};
