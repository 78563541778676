import { useRef, useState } from 'react';
import { IconLocation } from 'src/utils/URLUtils';
import { Word } from '../../../../model/Word';
import styles from './ImageSection.module.scss';
import MoreOptionsMenu from './MoreOptionsMenu/MoreOptionsMenu';
import VideoSection from './VideoSection/VideoSection';

interface ImageSectionProps {
    word: Word;
    hasRank?: boolean;
    hasClose?: boolean;
    close?: () => void;
}

const ImageSection = ({ word, hasClose, hasRank = true, close }: ImageSectionProps) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const moreOptionsRef = useRef<HTMLSpanElement>(null);

    return (
        <section className={styles.imageSection}>
            <VideoSection word={word} />
            {hasRank && <div className={styles.wordRank}>#{word.Rank}</div>}
            <span ref={moreOptionsRef} onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.moreOptions}>•••</span>
            {hasClose && <span onClick={() => close?.()}
                className={styles.close}>
                <img src={IconLocation('x')} alt="close" />
            </span>}
            <MoreOptionsMenu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={moreOptionsRef.current}
                word={word}
            />
        </section>
    );
};

export default ImageSection;