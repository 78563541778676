import { useState } from 'react';
import { WordList } from 'src/model/WordList';
import { WordSense } from '../../../../model/Word';
import { WordCard } from '../../../ui/GlobalComponents/WordCard/WordCard';
import styles from './WordListPageContent.module.scss';

interface WordListPageContentProps {
    wordSenses: WordSense[];
    removeMode: boolean;
    wordList: WordList;
}

export const WordListPageContent = ({ wordSenses, removeMode, wordList }: WordListPageContentProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredWords = wordSenses.filter((wordSense) =>
        wordSense.Word.Text.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className={styles.wordListPageContent}>
            <div className={styles.searchContainer}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchInput}
                />
            </div>
            <div className={styles.wordList}>
                {filteredWords.map((wordSense) => (
                    <WordCard key={wordSense.Word.ID} removeMode={removeMode} wordList={wordList}
                        word={wordSense.Word} senseId={wordSense.SenseId} />
                ))}
            </div>
        </div>
    );
};