import { useState } from 'react';
import { useLearning } from '../../../../hook/data/useLearning';
import { color } from '../../../../utils/ColorUtils';
import { IconLocation } from '../../../../utils/URLUtils';
import ProgressContent from '../../../Progress/MyProgress/ProgressContent/ProgressContent';
import CircleChart from '../../../ui/Features/CircleChart/CircleChart';
import Modal from '../../../ui/Features/Modal/Modal';
import styles from './LearnProgressChart.module.scss';

export const LearnProgressChart = () => {
    const { learningInfo } = useLearning();
    const myProgress = learningInfo?.MyAccount?.MyProgress;
    const learnItem = myProgress?.Daily.Items[6];
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <div className={styles.progressChartContainer}>
            <div onClick={() => setIsModalOpen(true)}>
                <CircleChart
                    value={learnItem?.Percentage ?? 0}
                    color={color(learnItem?.Percentage ?? 0)}
                    size={40}
                    ringWidth={5}
                    backgroundColor='transparent'
                    className={styles.progressChart}
                />
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalHeader}>
                        <h2>Keep going!</h2>
                        <img src={IconLocation('x')} alt="close" onClick={() => setIsModalOpen(false)} />
                    </div>
                    <div className={styles.modalBody}>
                        {`Achieved ${learnItem?.Percentage}% of your daily target.`}
                    </div>
                    <div className={styles.modalFooter}>
                        <ProgressContent chartSize={{ Daily: 40, Weekly: 65, Monthly: 65 }} />
                    </div>
                </div>
            </Modal>

        </div>
    );
};