import { useEffect } from 'react';
import { AnimationType } from 'src/model/enum/enums';
import { useComponentModal } from '../../../../hook/modal/useComponentModal';
import { MODAL_COMPONENTS } from '../../../../hook/registry/componentRegistry';
import styles from './ComponentModal.module.scss';

const ComponentModal = () => {
    const { isOpen, isClosing, componentKey, componentProps, animation, close, startClosing } = useComponentModal();

    useEffect(() => {
        if (isClosing) {
            const timer = setTimeout(() => {
                close();
            }, 300); // Match this with the CSS transition duration
            return () => clearTimeout(timer);
        }
    }, [isClosing, close]);

    if (!isOpen || !componentKey) return null;

    const ModalComponent = MODAL_COMPONENTS[componentKey];

    if (!ModalComponent) {
        console.error(`No component found for key: ${componentKey}`);
        return null;
    }

    return (
        <div
            key={`${componentKey}-${animation}`}
            className={`${styles.componentModal} 
                       ${styles[animation || AnimationType.SlideRight]} 
                       ${isClosing ? styles.closing : ''}`}
        >
            <div className={styles.modalContainer}>
                <ModalComponent {...componentProps} />
            </div>
        </div>
    );
};

export default ComponentModal;