import { useEffect, useState } from 'react';
import useProgress from 'src/hook/data/useProgress';
import useUser from 'src/hook/data/useUser';
import { UserFields } from 'src/model/enum/dataEnums';
import { DarkMode } from 'src/model/enum/enums';
import { IconLocation } from '../../../../utils/URLUtils';
import Divider from '../../../ui/GlobalComponents/Divider/Divider';
import styles from './ThemeSection.module.scss';

interface ThemeSectionProps {
    thick?: boolean;
}

const ThemeSection = ({ thick }: ThemeSectionProps) => {
    const [theme, setTheme] = useState<boolean | null>(null);
    const { userInfo } = useUser();
    const { updateUserInfoField } = useProgress();

    const handleThemeChange = (value: boolean) => {
        setTheme(value);
        updateUserInfoField(UserFields.DarkMode, value ? DarkMode.Dark : DarkMode.Light);
        setBodyTheme(value);
    };

    const setBodyTheme = (value: boolean) => {
        document.body.classList.toggle('dark', value);
    };

    useEffect(() => {
        if (theme === null) {
            setTheme(userInfo?.DarkMode === DarkMode.Dark);
        }
    }, [userInfo, theme]);

    return (
        <>
            <div className={styles.section}>
                <div className={styles.row}>
                    <img className={styles.icon} src={IconLocation('tablet')} alt="Theme" />
                    <span className={styles.text}>Theme</span>
                </div>
                <div className={styles.switch}>
                    <div
                        className={theme ? styles.active : styles.notActive}
                        onClick={() => handleThemeChange(true)}>
                        <img src={IconLocation('moon')} alt="Moon" />
                    </div>
                    <div
                        className={!theme ? styles.active : styles.notActive}
                        onClick={() => handleThemeChange(false)}>
                        <img src={IconLocation('sun')} alt="Sun" />
                    </div>
                </div>
            </div>
            <Divider thick={thick} />
        </>
    );
};

export default ThemeSection;