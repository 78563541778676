import { useNavigate } from 'react-router-dom';

export const useBackHandler = () => {
    const navigate = useNavigate();

    const handleBack = (fallbackPath: string = '/wordlists', forceUrl: boolean = false) => {
       
        if (forceUrl) {
            navigate(fallbackPath);
        } else if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate(fallbackPath);
        }
    };

    return handleBack;
};
