import { FC } from 'react';
import { useToast } from '../../../../hook/global/useToasts';
import Toast from './Toast';
import styles from './Toast.module.scss';

const ToastContainer: FC = () => {
    const { toasts } = useToast();

    if (!toasts.length) return null;

    return (
        <div className={styles.toastContainer}>
            {toasts.map(toast => (
                <Toast
                    key={toast.id}
                    id={toast.id}
                    message={toast.message}
                    type={toast.type}
                />
            ))}
        </div>
    );
};

export default ToastContainer;