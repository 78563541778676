import { createSlice } from '@reduxjs/toolkit';
import { Word } from '../../model/Word';

interface WordsState {
    words: Word[];
}

const initialState: WordsState = {
    words: []
};

const wordsSlice = createSlice({
    name: 'words',
    initialState,
    reducers: {
        setWords: (state, action) => {
            state.words = action.payload;
        }
    }
});

export const { setWords } = wordsSlice.actions;
export default wordsSlice.reducer;