import { useNavigate } from 'react-router-dom';
import { Actions } from 'src/model/enum/enums';
import  useProgress  from '../../../hook/data/useProgress';   
import { WordList } from '../../../model/WordList';
import { IconLocation, ImageLocation } from '../../../utils/URLUtils';
import WordCountHR from '../../ui/Features/ProgressBar/ProgressBar';
import styles from './DiscoverItem.module.scss';

export const DiscoverItem = ({ wordlist }: { wordlist: WordList }) => {
    const navigate = useNavigate();
    const { saveProgress } = useProgress();


    const handleClick = () => {
        saveProgress({
            Action: Actions.SeenWordList,
            Value: wordlist.Title
        });
        navigate(`/wordlists/${wordlist.IsCompleted ? 'wordlist' : 'discover'}?id=${wordlist.ID}`);
    };


    return (
        <div className={styles.discoverItem} onClick={handleClick}>
            <img className={styles.discoverItemImage}
                src={wordlist.ImageUrl}
                alt={wordlist.Title} />
            <div className={styles.discoverItemContentWrapper}>
                <div className={styles.discoverItemContent}>
                    <div className={styles.discoverItemContentText}>
                        <h3>{wordlist.Title}</h3>
                        <WordCountHR
                            knownCount={wordlist.KnownCount}
                            unknownCount={wordlist.UnknownCount}
                            remainCount={wordlist.TotalCount -
                                (wordlist.KnownCount + wordlist.UnknownCount)}
                        />
                        <p>{wordlist.UncoveredCountText}</p>
                    </div>
                    <div className={styles.discoverItemContentButton
                        + ' ' + (wordlist.IsCompleted ? styles.complete : styles.unComplete)}>
                        <img
                            src={IconLocation(wordlist.IsCompleted ? 'check' : 'chevron-right')} alt="Button" />
                    </div>
                    {wordlist.IsRestricted && <img className={styles.pro} src={ImageLocation('Pro.png')} alt="Pro" />}
                </div>
            </div>
        </div>
    );
}