import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNote } from 'src/hook/components/useNote';
import { useLearning } from 'src/hook/data/useLearning';
import useProgress from 'src/hook/data/useProgress';
import { useSocial } from 'src/hook/data/useSocial';
import { useWordTranslate } from 'src/hook/data/useWordTranslate';
import { useToast } from 'src/hook/global/useToasts';
import useUser from '../../../hook/data/useUser';
import { useIFrameModal } from '../../../hook/modal/useIFrameModal';
import { GetChromeExtensionUrl, GetRazorSiteUrl, GetWebSiteUrl, ImageLocation } from '../../../utils/URLUtils';
import ButtonGroupSection from './ButtonGroupSection/ButtonGroupSection';
import styles from './MainNavigation.module.scss';
import NavigationSection from './NavigationSection/NavigationSection';
import PronounceSection from './PronounceSection/PronounceSection';
import ThemeSection from './ThemeSection/ThemeSection';
import VideoSection from './VideoSection/VideoSection';

const MainNavigation: React.FC<{ toggleNavigation: () => void, show: boolean }> = ({ toggleNavigation, show }) => {
    const { open } = useIFrameModal();
    const { userInfo } = useUser();
    const navigate = useNavigate();
    const { fetchUserData } = useUser();
    const toast = useToast();
    const { fetchTranslates } = useWordTranslate(false);
    const { fetchNoteData } = useNote(false);
    const { fetchSocialInfo } = useSocial();
    const [isLoading, setIsLoading] = useState(false);
    const { fetchLearningData } = useLearning();
    const { uploadProgressAtomic } = useProgress();

    const handleOpenModal = (url: string, title: string) => {
        open(url, title);
        toggleNavigation();
    };

    const handleSync = async () => {
        setIsLoading(true);
        await uploadProgressAtomic();
        const user = await fetchUserData();
        await Promise.all([
            fetchLearningData(user?.Token!),
            fetchTranslates(user?.NativeLanguageCode),
            fetchNoteData(),
            fetchSocialInfo(user?.UserId!, user?.CountryCode),
        ]);

        toggleNavigation();
        toast.showSuccess('Synced.');
        setIsLoading(false);
    };

    return (
        <>
            {show && (
                <>
                    <div className={styles.overlay} onClick={toggleNavigation} />
                    <nav className={styles.mainNavigation}>
                        <img className={styles.logo} src={ImageLocation('logo.webp')} alt="Logo" />
                        <button className={styles.proButton} onClick={() => window.open(`${GetWebSiteUrl()}/our-plans`, '_blank')}>Get WordUp Pro</button>

                        <NavigationSection icon="search" onClick={() => navigate('/search')} text="Search" thick />

                        <ThemeSection />

                        <PronounceSection thick />

                        <NavigationSection
                            icon="user-check"
                            text="Account Settings"
                            hasArrow
                            onClick={() => handleOpenModal(GetRazorSiteUrl('settings/account', userInfo?.Token), 'Account Settings')}
                        />

                        <NavigationSection
                            icon="settings"
                            text="More Settings"
                            hasArrow
                            onClick={() => handleOpenModal(GetRazorSiteUrl('settings/preferences', userInfo?.Token), 'Preferences')}
                            thick
                        />

                        <NavigationSection icon="quote" text="My Interests" hasArrow
                            onClick={() => handleOpenModal(GetRazorSiteUrl('profile/my-interest', userInfo?.Token), 'Personalise WordUp')} />

                        <NavigationSection icon="chrome-extension" onClick={() => window.open(GetChromeExtensionUrl(), '_blank')}
                            text="Chrome Extension" />


                        <NavigationSection icon="repeat" onClick={handleSync}
                            text="Sync devices" isLoading={isLoading} />

                        <NavigationSection icon="tool" onClick={() => window.open(`${GetWebSiteUrl()}/support`, '_blank')}
                            text="Support & feedback" hasArrow />

                        <ButtonGroupSection />
                        <VideoSection />

                        <p className={styles.version}>Version 6.5.10</p>
                    </nav >
                </>
            )}
        </>
    );
};

export default MainNavigation;