import { FC } from 'react';
import VolumeSection from 'src/components/ui/GlobalComponents/VolumeSection/Volumesection';
import useAudioPlayer from 'src/hook/global/useAudioPlayer';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { AnimationType, DefinitionSource, LearnWizardStep } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { IconLocation } from '../../../../utils/URLUtils';
import ProgressBar from '../../../ui/Features/ProgressBar/ProgressBar';
import styles from './HeaderSection.module.scss';

interface HeaderSectionProps {
    word: Word;
    onClose: () => void;
    stepKey: LearnWizardStep;
}

const HeaderSection: FC<HeaderSectionProps> = ({ word, onClose, stepKey }) => {
    const { open } = useComponentModal();
    const { stop } = useAudioPlayer();

    const fillCount = stepKey === LearnWizardStep.First
        ? 25 : stepKey === LearnWizardStep.Second ? 50 : 75;
    const showProgress = stepKey !== LearnWizardStep.Final;


    return (
        <section className={styles.headerSection}>
            <div className={styles.titleSection}>
                <img src={IconLocation('x')} alt='close' onClick={onClose} />
                <h1>{word.Text}</h1>
                <img src={IconLocation('fullscreen')} alt='FullScreen'
                    onClick={() => {
                        stop();
                        open('Definition', { word: word, source: DefinitionSource.Learn }, AnimationType.SlideUp);
                    }}
                />

            </div>

            <VolumeSection word={word} />

            {showProgress && <div className={styles.progressSection}>
                <ProgressBar knownCount={fillCount} totalCount={100} height={10} />
            </div>}
        </section>
    );
};

export default HeaderSection;