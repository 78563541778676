import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWordList } from 'src/hook/data/useWordList';
import { useWords } from 'src/hook/data/useWords';
import { SortType, WordListType } from 'src/model/enum/enums';
import { WordSense } from 'src/model/Word';
import { WordList } from 'src/model/WordList';
import { WordListPageContent } from './WordListPageContent/WordListPageContent';
import { WordListPageHeader } from './WordListPageHeader/WordListPageHeader';

export const WordListPage = () => {
    const [wordList, setWordList] = useState<WordList>();
    const [wordSenses, setWordSenses] = useState<WordSense[]>([]);
    const { learningInfo, fetchWordListData, fetchCustomListData } = useWordList();
    const { getWordSensesByWordList, getWordSensesByCustomList, words } = useWords();
    const [searchParams] = useSearchParams();
    const wordListId = searchParams.get('id');
    const [removeMode, setRemoveMode] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SortType>(SortType.EasiestFirst);

    // Add sorting function
    const sortedWordSenses = useMemo(() => {
        return [...wordSenses].sort((a, b) => {
            switch (selectedItem) {
                case SortType.Alphabetic:
                    return a.Word.Text.localeCompare(b.Word.Text);
                case SortType.HardestFirst:
                    return (b.Word.Rank ?? 0) - (a.Word.Rank ?? 0);
                case SortType.EasiestFirst:
                    return (a.Word.Rank ?? 0) - (b.Word.Rank ?? 0);
                default:
                    return 0;
            }
        });
    }, [wordSenses, selectedItem]);

    useEffect(() => {
        const fetchData = async () => {
            if (!wordListId) return;

            const wordList = learningInfo?.WordLists.find(x => x.ID === wordListId);
            if (!wordList) return;
            setWordList(wordList);
            if (wordList.Type === WordListType.Custom) {
                if (wordList?.CustomListData && wordList.CustomListData.length > 0) {
                    const data = getWordSensesByCustomList(wordList.CustomListData);
                    setWordSenses(data);
                } else {
                    const data = await fetchCustomListData(wordList);
                    if (data) {
                        setWordSenses(getWordSensesByCustomList(data));
                    }
                }
            } else {
                const data = await fetchWordListData(wordList);
                if (data) {
                    setWordSenses(getWordSensesByWordList(data));
                }
            }
        };

        if (words.length > 0)
            fetchData();
    }, [wordListId, words.length,
        learningInfo?.WordLists]);///**dont add other depenceies */


    if (!wordList) return null;


    return (
        <article className='fullPage'>
            <WordListPageHeader wordList={wordList} removeMode={removeMode}
                setRemoveMode={setRemoveMode} selectedItem={selectedItem}
                setSelectedItem={setSelectedItem} />
            <WordListPageContent wordList={wordList} wordSenses={sortedWordSenses}
                removeMode={removeMode} />
        </article>


    );
};
