import { ComponentType } from 'react';
import FinalWizard from '../../components/Learn/FinalWizard/FinalWizard';
import FirstWizard from '../../components/Learn/FirstWizard/FirstWizard';
import SecondWizard from '../../components/Learn/SecondWizard/SecondWizard';
import ThirdWizard from '../../components/Learn/ThirdWizard/ThirdWizard';

export const WIZARD_COMPONENTS: Record<string, ComponentType<any>> = {
    FirstWizard,
    SecondWizard,
    ThirdWizard,
    FinalWizard,
};
