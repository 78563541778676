import { FC } from 'react';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import ImageSection from '../../ui/GlobalComponents/ImageSection/ImageSection';
import styles from './FirstWizard.module.scss';
import TextContent from './TextContent/TextContent';


interface FirstWizardProps {
    word: Word;
    wordData: WordData;
}

const FirstWizard: FC<FirstWizardProps> = ({ word, wordData }) => {

    return (
        <div className={styles.firstWizard}>
            <ImageSection word={word} hasRank={false} />
            <TextContent
                word={word}
                wordData={wordData}
                autoPlay={true}
            />
        </div>
    );
};

export default FirstWizard;