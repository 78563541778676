import { FC, useEffect, useState } from 'react';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { getDirection } from 'src/utils/DirectionUtils';
import useUser from '../../../../../hook/data/useUser';
import { useWordTranslate } from '../../../../../hook/data/useWordTranslate';
import { AnimationType, Voice } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import ImageSection from '../../../../ui/GlobalComponents/ImageSection/ImageSection';
import VolumeButton from '../../../../ui/GlobalComponents/VolumeButton/VolumeButton';
import { ButtonSection } from './ButtonSection/ButtonSection';
import styles from './SlideItem.module.scss';


interface SlideItemProps {
    word: Word;
    moveNext: () => void;
}

export const SlideItem: FC<SlideItemProps> = ({ word, moveNext }) => {
    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const { getTranslateBySenseId } = useWordTranslate();
    const { open } = useComponentModal();
    const [translate, setTranslate] = useState<string>('');


    const openDefinition = () => {
        open('Definition', { word, moveNext }, AnimationType.SlideUp);
    }


    useEffect(() => {
        if (isNonEnglishNativeLanguage()) {
            setTranslate(getTranslateBySenseId(word.PrimarySenseId));
        }

    }, [word, isNonEnglishNativeLanguage, getTranslateBySenseId]);

    return (
        <section className={styles.slideItem}>
            <ImageSection word={word} hasRank={false} />
            <div className={styles.container} onDoubleClick={openDefinition}>
                <div className={styles.word}>
                    <div className={styles.volumeSection}>
                        <h1 className={word.Text.length > 20 ? styles.smallerText : ''}>{word.Text}</h1>
                        <VolumeButton word={word} />
                    </div>
                    {word.BritishPhonetic && <div className={styles.rank}>
                        <span>/{userInfo?.Voice === Voice.gb ? word.BritishPhonetic : word.AmericanPhonetic}/</span>
                        <span>#{word.CustomRank}</span>
                    </div>}
                </div>
                {translate &&
                    <p className={`${styles.translate} ${translate.length > 80 ? styles.smallText : ''}`} dir={getDirection(translate)}>
                        {translate}
                    </p>}
                <div className={styles.primaryMeaning}>
                    {word.PrimaryMeaning}
                    <span onClick={openDefinition}
                        className={styles.learnMore}>Learn more...</span>
                </div>
                <ButtonSection word={word} moveNext={moveNext} />
            </div>
        </section>
    );
};
