import { useCallback } from "react";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions, LearningArray, WordListType } from "src/model/enum/enums";
import { Word, WordSense } from "src/model/Word";
import { SenseImageLocation } from "src/utils/URLUtils";
import { CustomListData, WordList } from "../../model/WordList";
import { learningActions } from '../../store/slices/learningSlice';
import { appDispatch, RootState } from '../../store/store';
import useFetch from "../api/useFetch";
import { useLearning } from "./useLearning";
import useProgress from "./useProgress";


export const useWordList = () => {
    const { send } = useFetch<any>();
    const ac = bindActionCreators(learningActions, appDispatch);
    const { learningInfo } = useSelector((state: RootState) => state.learning);
    const { addWord } = useLearning();
    const { saveProgress } = useProgress();


    const fetchCustomListData = useCallback(async (wordList: WordList, updateStore: boolean = true) => {
        if (!wordList?.WordsUrl) return null;

        const { response } = await send(wordList.WordsUrl);
        const customListData = response?.uw || [];

        if (learningInfo && updateStore) {
            const updatedWordLists = learningInfo.WordLists.map(wl =>
                wl.ID === wordList.ID ? { ...wl, CustomListData: customListData } : wl
            );
            ac.updateLearning({ WordLists: updatedWordLists });
        }
        return customListData as CustomListData[];

    }, [learningInfo, send, ac]);

    const fetchWordListData = useCallback(async (wordList: WordList) => {
        if (!wordList?.WordsUrl) return null;

        const { response } = await send(wordList.WordsUrl);
        const wordListData = response?.uw
            ?.split(',')
            ?.map((id: string) => parseInt(id.trim(), 10))
            ?.filter((id: number) => !isNaN(id)) ?? [];

        return wordListData as number[];
    }, [send]);

    const removeCustomList = useCallback((wordList: WordList) => {
        if (!learningInfo?.WordLists) return;

        const updatedWordLists = learningInfo.WordLists.filter(
            list => list.ID !== wordList.ID
        );

        ac.updateLearning({ WordLists: updatedWordLists });
        saveProgress({
            Action: Actions.RemoveWordList,
            Value: `{"id":"${wordList.ID}"}`
        });
    }, [learningInfo, ac, saveProgress]);

    const updateWordListStats = useCallback(async (wordList: WordList) => {
        if (!wordList) return;
        if (wordList.Type === WordListType.Custom && wordList.CustomListData.length === 0) {
            removeCustomList(wordList);
            return;
        }


        let updatedWordlist: WordList;
        if (wordList.Type === WordListType.All) {
            const known = learningInfo?.KnownWordIds?.length || 0;
            const unknown = learningInfo?.UnknownWordIds?.length || 0;
            const total = 24241; // Total vocabulary count
            const uncovered = known + unknown;
            const isCompleted = uncovered >= total;
            const uncoveredCountText = isCompleted ? '' :
                `${Math.round((uncovered / total) * 100)}%`;

            updatedWordlist = {
                ...wordList,
                KnownCount: known,
                UnknownCount: unknown,
                TotalCount: total,
                UncoveredCountText: uncoveredCountText,
                IsCompleted: isCompleted
            };
        } else if (wordList.Type === WordListType.Custom) {
            const total = wordList.CustomListData.length || 0;
            const known = learningInfo?.KnownWordIds?.filter
                (x => wordList.CustomListData?.some(w => w.WordId === x)).length || 0;
            const unknown = learningInfo?.UnknownWordIds?.filter
                (x => wordList.CustomListData?.some(w => w.WordId === x)).length || 0;
            const uncovered = known + unknown;
            const isCompleted = uncovered >= total;

            const uncoveredCountText = isCompleted ? '' :
                `${Math.round((uncovered / total) * 100)}%`;


            // console.log('wordList', wordList.CustomListData)
            // console.log('known', known)
            // console.log('unknown', unknown)
            // console.log('total', total)
            // console.log('uncovered', uncovered)
            // console.log('isCompleted', isCompleted)

            updatedWordlist = {
                ...wordList,
                KnownCount: known,
                UnknownCount: unknown,
                TotalCount: total,

                UncoveredCountText: uncoveredCountText,
                IsCompleted: isCompleted
            };
        }

        else {
            const wordListData = await fetchWordListData(wordList);
            const total = wordListData?.length || 0;
            const known = learningInfo?.KnownWordIds?.filter(x => wordListData?.includes(x)).length || 0;
            const unknown = learningInfo?.UnknownWordIds?.filter(x => wordListData?.includes(x)).length || 0;
            const uncovered = known + unknown;
            const isCompleted = uncovered >= total;


            const uncoveredCountText = isCompleted ? '' :
                `${Math.round((uncovered / total) * 100)}%`;

            updatedWordlist = {
                ...wordList,
                KnownCount: known,
                UnknownCount: unknown,
                TotalCount: total,
                UncoveredCountText: uncoveredCountText,
                IsCompleted: isCompleted
            };
        }

        const updatedWordLists = learningInfo?.WordLists.map(wl =>
            wl.ID === wordList.ID ? updatedWordlist : wl
        );
        ac.updateLearning({ WordLists: updatedWordLists });
    }, [learningInfo, ac, fetchWordListData, removeCustomList]);

    const createCustomList = useCallback((listName: string, saveData: boolean = true) => {
        if (!learningInfo) return;

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString()
            .replace(/[:.]/g, '-')
            .slice(0, 19); // Format: "2024-07-25 15-00-05"

        const newWordList: WordList = {
            ID: formattedDate,
            Type: "custom",
            ImageUrl: "",
            Title: listName,
            TotalCount: 1,
            TotalCountText: "1 word",
            KnownCount: 0,
            UnknownCount: 1,
            UncoveredCountText: "",
            IsAll: false,
            IsCompleted: true,
            IsRestricted: false,
            WordsUrl: ``,
            CustomListData: []
        };

        if (saveData) {
            const updatedWordLists = [...(learningInfo.WordLists || []), newWordList];
            ac.updateLearning({ WordLists: updatedWordLists });

            saveProgress({
                Action: Actions.NewWordList,
                Value: `{"id":"${formattedDate}","name":"${listName}"}`
            });
        }
        return newWordList;

    }, [learningInfo, ac, saveProgress]);

    const removeWordFromCustomList = useCallback((wordList: WordList, word: Word, senseId?: string) => {
        if (!learningInfo?.WordLists) return;

        const updatedWordLists = learningInfo.WordLists.map(list => {
            if (list.ID === wordList.ID) {
                const filteredData = (list.CustomListData || []).filter(
                    item => !(item.WordId === word.ID && item.SenseId === senseId)
                );

                return {
                    ...list,
                    CustomListData: filteredData,
                    TotalCount: filteredData.length,
                    TotalCountText: `${filteredData.length} ${filteredData.length === 1 ? 'word' : 'words'}`
                };
            }
            return list;
        });

        ac.updateLearning({ WordLists: updatedWordLists });

        saveProgress({
            Action: Actions.RemoveWordFromList,
            Value: `{"id":"${wordList.ID}","senses":"${senseId}:${word.ID}"}`
        });

    }, [learningInfo, ac, saveProgress]);

    const addWordToSomeCustomLists = useCallback((wordLists: WordList[], word: Word, senseId?: string) => {
        if (!learningInfo) return;

        const customListData: CustomListData = {
            SenseId: senseId || word.PrimarySenseId,
            WordId: word.ID,
        };

        const updatedWordLists = learningInfo.WordLists.map(list => {
            if (wordLists.some(selectedList => selectedList.ID === list.ID)) {
                // Check for duplicate in this list
                const isDuplicate = list.CustomListData?.some(item =>
                    item.WordId === customListData.WordId &&
                    item.SenseId === customListData.SenseId
                );

                // Only update if not a duplicate
                if (!isDuplicate) {
                    return {
                        ...list,
                        CustomListData: [...(list.CustomListData || []), customListData],
                        ImageUrl: list.ImageUrl || SenseImageLocation(customListData.SenseId)
                    };
                }
            }
            return list;
        });

        ac.updateLearning({ WordLists: updatedWordLists });

        const progressData = wordLists.map(list => ({
            Action: Actions.InsertWordToList,
            Value: `{"id":"${list.ID}","senses":"${senseId}:${word.ID}"}`
        }));

        if (!learningInfo.KnownWordIds.includes(word.ID)
            && !learningInfo.UnknownWordIds.includes(word.ID)) {
            addWord(LearningArray.UnknownWordIds, word.ID);
            addWord(LearningArray.ToLearn, word.ID);
            progressData.push({
                Action: Actions.Learn,
                Value: word.ID.toString()
            });
        }

        saveProgress(progressData);

    }, [learningInfo, ac, addWord, saveProgress]);

    const addWordToCustomList = useCallback((wordList: WordList, word: Word, senseId?: string) => {
        if (!learningInfo) return;

        const customListData: CustomListData = {
            SenseId: senseId || word.PrimarySenseId,
            WordId: word.ID,
        };
        const progressData = [{
            Action: Actions.InsertWordToList,
            Value: `{"id":"${wordList.ID}","senses":"${senseId}:${word.ID}"}`
        }];

        // Check if wordList exists in learningInfo.WordLists
        const wordListExists = learningInfo.WordLists.some(list => list.ID === wordList.ID);

        let updatedWordLists;
        if (!wordListExists) {
            // Add the wordList to learningInfo.WordLists
            updatedWordLists = [...learningInfo.WordLists, {
                ...wordList,
                CustomListData: [customListData],
                ImageUrl: wordList.ImageUrl || SenseImageLocation(customListData.SenseId)
            }];

            progressData.push({
                Action: Actions.NewWordList,
                Value: `{"id":"${wordList.ID}","name":"${wordList.Title}"}`
            });

        } else {
            // Check for duplicate in the current wordList
            const isDuplicate = wordList.CustomListData?.some(item =>
                item.WordId === customListData.WordId &&
                item.SenseId === customListData.SenseId
            );

            // Only proceed if not a duplicate
            if (isDuplicate) return;

            updatedWordLists = learningInfo.WordLists.map(list => {
                if (list.ID === wordList.ID) {
                    return {
                        ...list,
                        CustomListData: [...(list.CustomListData || []), customListData],
                        ImageUrl: list.ImageUrl || SenseImageLocation(customListData.SenseId)
                    };
                }
                return list;
            });
        }

        ac.updateLearning({ WordLists: updatedWordLists });



        if (!learningInfo.KnownWordIds.includes(word.ID)
            && !learningInfo.UnknownWordIds.includes(word.ID)) {
            addWord(LearningArray.UnknownWordIds, word.ID);
            addWord(LearningArray.ToLearn, word.ID);
            progressData.push({
                Action: Actions.Learn,
                Value: word.ID.toString()
            });
        }

        saveProgress(progressData);

    }, [learningInfo, ac, saveProgress, addWord]);

    const editCustomList = useCallback((wordList: WordList, listName: string) => {
        if (!learningInfo?.WordLists) return;

        const updatedWordLists = learningInfo.WordLists.map(list =>
            list.ID === wordList.ID ? {
                ...list,
                Title: listName
            } : list
        );

        ac.updateLearning({ WordLists: updatedWordLists });
        saveProgress({
            Action: Actions.RenameWordList,
            Value: `{"id":"${wordList.ID}","name":"${listName}"}`
        });
    }, [learningInfo, ac, saveProgress]);

    const addSomeWordsToCustomList = useCallback((wordList: WordList, words: WordSense[]) => {
        if (!learningInfo) return;

        // Create CustomListData entries for all words
        const newCustomListData: CustomListData[] = words.map(({ SenseId, Word }) => ({
            SenseId,
            WordId: Word.ID,
        }));


        // Filter out duplicates
        const uniqueNewData = newCustomListData.filter(newItem =>
            !wordList.CustomListData?.some(existingItem =>
                existingItem.WordId === newItem.WordId &&
                existingItem.SenseId === newItem.SenseId
            )
        );

        if (uniqueNewData.length === 0) return;

        // Update word lists
        const updatedWordLists = learningInfo.WordLists.map(list => {
            if (list.ID === wordList.ID) {
                return {
                    ...list,
                    CustomListData: [...(list.CustomListData || []), ...uniqueNewData],
                    ImageUrl: list.ImageUrl || SenseImageLocation(uniqueNewData[0].SenseId)
                };
            }
            return list;
        });

        // Handle learning state updates in bulk
        const wordIds = words.map(({ Word }) => Word.ID);
        const newUnknownWords = wordIds.filter(id =>
            !learningInfo.KnownWordIds.includes(id) &&
            !learningInfo.UnknownWordIds.includes(id)
        );


        if (newUnknownWords.length > 0) {
            const updatedUnknownWordIds = Array.from(new Set([
                ...learningInfo.UnknownWordIds,
                ...newUnknownWords
            ]));
            const updatedToLearn = Array.from(new Set([
                ...learningInfo.ToLearn,
                ...newUnknownWords
            ]));

            // Update learning info with all changes at once
            ac.updateLearning({
                WordLists: updatedWordLists,
                UnknownWordIds: updatedUnknownWordIds,
                ToLearn: updatedToLearn
            });

            // Save progress for learning state
            const progressData = newUnknownWords.map(wordId => ({
                Action: Actions.Learn,
                Value: wordId.toString()
            }));
            saveProgress(progressData);

        } else {
            // Update only word lists if no learning state changes needed
            ac.updateLearning({ WordLists: updatedWordLists });
        }

        // Save progress for adding to list
        const sensesData = uniqueNewData
            .map(item => `${item.SenseId}:${item.WordId}`)
            .join(',');

        saveProgress({
            Action: Actions.InsertWordToList,
            Value: `{"id":"${wordList.ID}","senses":"${sensesData}"}`
        });
    }, [learningInfo, ac, saveProgress]);



    return {
        learningInfo,
        updateWordListStats,
        fetchWordListData,
        createCustomList,
        removeCustomList,
        addWordToCustomList,
        addWordToSomeCustomLists,
        fetchCustomListData,
        removeWordFromCustomList,
        editCustomList,
        addSomeWordsToCustomList
    };
};