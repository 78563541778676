import { useCallback, useEffect, useState } from 'react';
import Spinner from 'src/components/ui/Features/Spinner/Spinner';
import { useTranslate } from 'src/hook/data/useTranslate';
import { useAudio } from 'src/hook/global/useAudio';
import { getDirection } from 'src/utils/DirectionUtils';
import { GetSoundUrl } from 'src/utils/URLUtils';
import { Question, QuestionTranslate } from '../../../../model/Scenario';
import styles from './SecondWizard.module.scss';

interface Props {
    question: Question;
    onContinue: () => void;
    lessonQuestions: string[];
    translateText?: string;
    answer?: string;
}

const SecondWizard = ({ question, onContinue, lessonQuestions, translateText, answer }: Props) => {
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [wrongIndex, setWrongIndex] = useState<number | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [questionTranslate, setQuestionTranslate] = useState<QuestionTranslate[]>([]);
    const { playAudio } = useAudio();
    const title = answer ? answer : question.q;
    const { translateMultiple, isLoading } = useTranslate();



    const fetchData = useCallback(async () => {
        if (isFetching || questionTranslate.length > 0) {
            return;
        }

        try {
            setIsFetching(true);

            const otherQuestions = lessonQuestions
                .filter(q => q !== title)
                .sort(() => Math.random() - 0.5)
                .slice(0, 2);

            const results = await translateMultiple(otherQuestions);

            if (!results || results.length === 0) return;

            const allQuestions = [title, ...otherQuestions];
            const translates = results.map(r => r ?? '');
            const allTranslates = [translateText, ...translates];

            const qr = allTranslates.map((r, index) => ({
                question: allQuestions[index],
                translate: r ?? '',
                correct: index === 0
            }));

            setQuestionTranslate([...qr].sort(() => Math.random() - 0.5));
        } finally {
            setIsFetching(false);
        }
    }, [title, translateText, lessonQuestions, isFetching, questionTranslate.length, translateMultiple]);



    const handleOptionClick = (index: number) => {
        if (isCorrect) return;

        if (questionTranslate[index].correct) {
            setIsCorrect(true);
            playAudio(GetSoundUrl('CorrectAnswer'));
        } else {
            setWrongIndex(index);
            playAudio(GetSoundUrl('IncorrectAnswer'));
            // Reset the wrong state after animation completes
            setTimeout(() => {
                setWrongIndex(null);
            }, 500); // 500ms = 0.5s (matching our animation duration)
        }
    };


    useEffect(() => {
        if (!isFetching && questionTranslate.length === 0)
            fetchData();
    }, [fetchData, isFetching, questionTranslate.length]);


    if (isLoading) return <Spinner />;
    if (questionTranslate.length === 0) return null;

    return (
        <section className={styles.secondWizard}>
            <h1 className={styles.questionText}>{title}</h1>
            <div className={styles.options}>
                {questionTranslate.map((tr, index) => (
                    <div dir={getDirection(tr.translate)}
                        key={index}
                        className={`${styles.option} ${tr.correct && isCorrect
                            ? styles.correct
                            : index === wrongIndex
                                ? styles.wrong
                                : ''
                            }`}
                        onClick={() => handleOptionClick(index)}
                    >
                        <span className={styles.optionLetter}>
                            {index === 0 ?
                                'A' : index === 1 ?
                                    'B' : 'C'}
                        </span>
                        <span
                            className={styles.translateText}
                        > {tr.translate}</span>
                    </div>
                ))}
            </div>

            <button onClick={onContinue} disabled={!isCorrect}
                className={styles.continueButton}>Continue</button>
        </section>
    );
};

export default SecondWizard;
