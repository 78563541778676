import { FC, useCallback, useEffect, useState } from "react";
import { useAudio } from "src/hook/global/useAudio";
import useUser from "../../../hook/data/useUser";
import { useWordTranslate } from "../../../hook/data/useWordTranslate";
import { Word } from "../../../model/Word";
import { WordData } from "../../../model/WordData";
import { GetSoundUrl } from "../../../utils/URLUtils";
import MainSection from "./MainSection/MainSection";

interface FinalWizardProps {
    word: Word;
    wordData: WordData;
}

const FinalWizard: FC<FinalWizardProps> = ({ word, wordData }) => {
    const [translateWord, setTranslateWord] = useState<string>('');
    const { isNonEnglishNativeLanguage } = useUser();
    const { getTranslateBySenseId } = useWordTranslate();
    const { playAudio } = useAudio();

    const fetchData = useCallback(async () => {
        const randomNumber = Math.floor(Math.random() * 11) + 1;
        if (isNonEnglishNativeLanguage()) {
            setTranslateWord(getTranslateBySenseId(word?.PrimarySenseId));
        }
        playAudio(GetSoundUrl(randomNumber.toString()));


    }, [isNonEnglishNativeLanguage, getTranslateBySenseId,
        word.PrimarySenseId, playAudio]);


    useEffect(() => {
        fetchData();
    }, []);


    return (
        <MainSection word={word} wordData={wordData}
            translateWord={translateWord} />
    );
};

export default FinalWizard;
