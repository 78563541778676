import { useCallback, useEffect, useState } from 'react';
import useFetch from 'src/hook/api/useFetch';
import { useWords } from 'src/hook/data/useWords';
import { useAudio } from 'src/hook/global/useAudio';
import { useLearning } from '../../../hook/data/useLearning';
import useUser from '../../../hook/data/useUser';
import { Voice } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import { GetWordDataFileUrl } from '../../../utils/URLUtils';
import Spinner from "../../ui/Features/Spinner/Spinner";
import ImageSection from '../../ui/GlobalComponents/ImageSection/ImageSection';
import ActionButtons from './ActionButtons/ActionButtons';
import AudioPlayButton from './AudioPlayButton/AudioPlayButton';
import EmptyLearn from './EmptyLearn/EmptyLearn';
import styles from './MainLearn.module.scss';

const MainLearn = () => {
    const { userInfo } = useUser();
    const { learningInfo } = useLearning();
    const [word, setWord] = useState<Word | undefined>(undefined);
    const [index, setIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [wordData, setWordData] = useState<WordData>({} as WordData);
    const { getWordById } = useWords();
    const { send } = useFetch<WordData>();
    const [isEmptyLearn, setIsEmptyLearn] = useState(false);
    const { playWord } = useAudio();

    const fetchData = useCallback(async () => {
        const wordId = learningInfo?.ToLearn[index];
        if (!wordId || wordId === word?.ID) return;
        const { response } = await send(GetWordDataFileUrl(wordId));
        if (!response) return;

        const fetchedWord = getWordById(wordId);
        if (fetchedWord) {
            setWord(fetchedWord);
            setWordData(response);
            playWord(fetchedWord.ID);
        }

    }, [getWordById, learningInfo?.ToLearn, index, send, playWord, word?.ID]);



    useEffect(() => {
        if (learningInfo?.ToLearn?.length && learningInfo?.ToLearn?.length > 0)
            fetchData();
        else
            setIsEmptyLearn(true);
    }, [fetchData, learningInfo?.ToLearn]);




    if (isEmptyLearn) return <EmptyLearn />
    else if (!word) return <Spinner />

    else
        return (
            <article className={styles.mainLearn}>
                <div className={styles.mainContent}>
                    <ImageSection word={word} />
                    <AudioPlayButton
                        wordId={word?.ID}
                        onPlayStart={() => setIsPlaying(true)}
                        onPlayEnd={() => setIsPlaying(false)}
                    />
                </div>
                <div className={`${styles.wordInfo} ${isPlaying ? styles.playing : ''}`}>
                    <h1>{word?.Text}</h1>
                    <p>/{userInfo?.Voice === Voice.gb ? word?.BritishPhonetic : word?.AmericanPhonetic}/</p>
                </div>
                <ActionButtons
                    word={word}
                    wordData={wordData}
                    index={index}
                    setIndex={setIndex}
                />
            </article>
        );
};

export default MainLearn;