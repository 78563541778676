import { ChevronType } from 'src/model/enum/enums';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './Chevron.module.scss';

interface ChevronProps {
    type: ChevronType;
    size?: number;
    onClick?: () => void;
}

export const Chevron = ({ type, size = 24, onClick }: ChevronProps) => {
    return (
        <img
            src={IconLocation(`chevron-${type}`)}
            alt="chevron"
            width={size}
            height={size}
            className={styles.chevron}
            onClick={onClick}
        />
    );
};
