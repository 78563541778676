import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hidePopUp, showPopUp } from 'src/store/slices/popUpSlice';
import { RootState } from 'src/store/store';

interface PopUpConfig {
    title: string;
    message: string;
    primaryButtonText?: string;
    primaryButtonClick?: () => void;
    secondaryButtonText?: string;
    secondaryButtonClick?: () => void;
    showSecondaryButton?: boolean;
}

const usePopUp = () => {
    const dispatch = useDispatch();
    const { isOpen } = useSelector((state: RootState) => state.popUp);

    const show = useCallback((popUpConfig: PopUpConfig) => {
        dispatch(showPopUp(popUpConfig));
    }, [dispatch]);

    const hide = useCallback(() => {
        dispatch(hidePopUp());
    }, [dispatch]);

    return {
        isOpen,
        show,
        hide,
    };
};

export default usePopUp;
