import { useEffect, useState } from 'react';
import { useTranslate } from 'src/hook/data/useTranslate';
import { AnswerDone, Question } from 'src/model/Scenario';
import { getDirection } from 'src/utils/DirectionUtils';
import { GetPeopleImageUrl } from 'src/utils/URLUtils';
import ActionButtons from './ActionButtons/ActionButtons';
import styles from './Answer.module.scss';

interface Props {
    question: Question;
    currentIndex: number;
    totalItems: number;
    answerDone: AnswerDone;
    personName: string;
}

const Answer = ({ question, currentIndex, totalItems, answerDone, personName }: Props) => {
    const [translateText, setTranslateText] = useState<string | null>(null);
    const { translate } = useTranslate();

    useEffect(() => {
        const getTranslate = async () => {
            const response = await translate(answerDone.answer);
            setTranslateText(response);
        }
        if (!translateText) getTranslate();
    }, [answerDone.answer, translate, translateText]);


    return (
        <div className={styles.answer}>
            <div className={`${styles.number} ${answerDone.isDone ? styles.done : ''}`}>
                {`Answer ${currentIndex + 1}/${totalItems}`}
            </div>
            <h1>{answerDone.answer}</h1>
            <h2 dir={getDirection(translateText)}
                className={styles.translateText}>{translateText || '\u00A0'}</h2>
            <div className={styles.footer}>
                <ActionButtons question={question} answerDone={answerDone}
                    translateText={translateText} voice={personName} />
                <div className={styles.peopleImageContainer}>
                    <img src={GetPeopleImageUrl(personName)}
                        alt='people'
                        className={styles.peopleImage} />
                </div>
            </div>
        </div>
    );
};

export default Answer;
