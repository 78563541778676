import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageHeader } from 'src/components/ui/GlobalComponents/PageHeader/PageHeader';
import useUser from 'src/hook/data/useUser';
import { useIFrameModal } from 'src/hook/modal/useIFrameModal';
import { GetRazorSiteUrl } from 'src/utils/URLUtils';
import styles from './CreateCustomList.module.scss';
import { CustomListForm } from './CustomListForm/CustomListForm';

export const CreateCustomList = () => {
    const { open } = useIFrameModal();
    const { userInfo } = useUser();
    const navigate = useNavigate();



    const handleBackClick = () => {
        open(GetRazorSiteUrl('profile/my-interest',
            userInfo?.Token), 'Personalise WordUp');
        setTimeout(() => navigate('/wordlists'), 1000);
    }

    return (
        <section className={`fullPage ${styles.createCustomList}`}>
            <PageHeader
                title='Create new list'
                handleBackClick={handleBackClick} />
            <CustomListForm />
        </section>
    );
};
