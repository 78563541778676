import { useAudio } from 'src/hook/global/useAudio';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './VolumeButton.module.scss';
import { Word } from 'src/model/Word';

const VolumeButton = ({ word }: { word: Word }) => {
    const { playWord } = useAudio();

    return (
        <img
            src={IconLocation('volume-1')}
            alt='volume'
            onClick={() => playWord(word.ID)}
            className={styles.volumeButton}
        />
    );
};

export default VolumeButton;