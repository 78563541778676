import { FC } from 'react';
import styles from './ButtonSection.module.scss';

interface ButtonSectionProps {
    onAdd?: () => void;
}

export const ButtonSection: FC<ButtonSectionProps> = ({ onAdd }) => {
    return (
        <div className={styles.buttonSection}>
            <button
                className={styles.addButton}
                onClick={onAdd}
            >
                Save
            </button>
        </div>
    );
};
