import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, LessonArrays } from 'src/model/enum/enums';
import { Lessons } from 'src/model/Lessons';
import { Question } from 'src/model/Scenario';
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { setError, setIsLoading, setScenarioQAs, setScenariosData } from '../../store/slices/scenarioSlice';
import { RootState } from '../../store/store';
import { GetResourcesUrl } from '../../utils/URLUtils';
import { useLearning } from './useLearning';
import useProgress from './useProgress';

export const useScenario = () => {
    const dispatch = useDispatch();
    const { scenarios, scenarioQAs, isLoading, error } = useSelector((state: RootState) => state.scenario);
    const { learningInfo, updateLearningInfo } = useLearning();
    const { saveProgress } = useProgress();

    useEffect(() => {
        if (!scenarios || !scenarioQAs) {
            fetchScenarioData();
        }
    }, []);

    const fetchScenarioData = async () => {
        dispatch(setIsLoading(true));
        try {
            const [scenariosResponse, scenarioQAsResponse] = await Promise.all([
                fetch(GetResourcesUrl('Scenarios.json')),
                fetch(GetResourcesUrl('ScenarioQAs.json'))
            ]);

            if (!scenariosResponse.ok || !scenarioQAsResponse.ok) {
                throw new Error('Failed to fetch scenario data');
            }
            const scenariosData = await scenariosResponse.json();
            const scenarioQAsData = await scenarioQAsResponse.json();
            dispatch(setScenariosData(scenariosData));
            dispatch(setScenarioQAs(scenarioQAsData));
        } catch (err) {
            dispatch(setError(err instanceof Error ? err.message : 'Failed to fetch scenario data'));
            console.error(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const findFirstIncompleteLesson = () => {
        if (!scenarios) return null;

        for (const category of scenarios.categories) {
            for (const ctx of category.ctx) {
                const questionId = `${category.ref}${ctx.ref}`;
                if (!learningInfo?.Lessons.CompletedContext.includes(questionId)) {
                    return questionId;
                }
            }
        }
        return null;
    };

    const areAllQuestionsCompleted = async (question: Question) => {
        const hashQuestion = await ToIOSafeHash(question.q);
        const questions = scenarioQAs?.filter(q => q.r === question.r) || [];

        const questionHashes = await Promise.all(
            questions.map(question => ToIOSafeHash(question.q))
        );

        return questionHashes.every(hash =>
            [...(learningInfo?.Lessons.Conversations || []), hashQuestion].includes(hash)
        );
    };

    const areAllAnswersCompleted = async (question: Question, answer: string) => {
        const hashAnswer = await ToIOSafeHash(`${question.q}|${answer}`);
        const answersHashes = await Promise.all(
            question.a.map(async (answer) =>
                await ToIOSafeHash(`${question.q}|${answer}`)
            )
        );

        return answersHashes.every(hash =>
            [...(learningInfo?.Lessons.Answers || []), hashAnswer].includes(hash)
        );
    };

    const addQuestion = async (question: Question) => {

        const hashQuestion = await ToIOSafeHash(question.q);
        const allQuestionsCompleted = await areAllQuestionsCompleted(question);
        if (allQuestionsCompleted) {
            addLesson(LessonArrays.CompletedContext, question.r);
            saveProgress({
                Action: Actions.LessonCompleted,
                Value: question.r
            });
        }


        addLesson(LessonArrays.Conversations, hashQuestion);
        saveProgress({
            Action: Actions.LessonQuestionAdded,
            Value: hashQuestion
        });


    };

    const addAnswer = async (question: Question, answer: string) => {
        const hashAnswer = await ToIOSafeHash(`${question.q}|${answer}`);
        const allAnswersCompleted = await areAllAnswersCompleted(question, answer);
        if (allAnswersCompleted) {
            addQuestion(question);
        }

        addLesson(LessonArrays.Answers, hashAnswer);
        saveProgress({
            Action: Actions.LessonAnswerAdded,
            Value: hashAnswer
        });
    };

    const addQuestionWithAllAnswers = async (question: Question) => {
        const hashQuestion = await ToIOSafeHash(question.q);
        const answerHashes = await Promise.all(question.a.map(a =>
            ToIOSafeHash(`${question.q}|${a}`)
        ));

        const allQuestionsCompleted = await areAllQuestionsCompleted(question);

        const lessons: Lessons = {
            CompletedContext: [...(learningInfo?.Lessons.CompletedContext || []), ...(allQuestionsCompleted ? [question.r] : [])],
            Conversations: [...(learningInfo?.Lessons.Conversations || []), hashQuestion],
            Answers: [...(learningInfo?.Lessons.Answers || []), ...answerHashes]
        }

        updateLearningInfo({ Lessons: lessons });

        saveProgress({
            Action: Actions.LessonQuestionAdded,
            Value: hashQuestion
        });

        if (allQuestionsCompleted) {
            saveProgress({
                Action: Actions.LessonCompleted,
                Value: question.r
            });
        }
    };

    const addLesson = useCallback((arrayName: LessonArrays, value: string) => {
        if (learningInfo) {
            const updatedArray = Array.from(new Set([...learningInfo.Lessons[arrayName], value]));
            updateLearningInfo({
                Lessons: {
                    ...learningInfo.Lessons,
                    [arrayName]: updatedArray
                }
            });
        }
    }, [learningInfo, updateLearningInfo]);

    return {
        scenarios,
        scenarioQAs,
        isLoading,
        error,
        fetchScenarioData,
        findFirstIncompleteLesson,
        areAllQuestionsCompleted,
        areAllAnswersCompleted,
        addQuestion,
        addAnswer,
        addQuestionWithAllAnswers
    };
};
