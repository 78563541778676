import { useLearning } from '../../../hook/data/useLearning';
import { ImageLocation } from '../../../utils/URLUtils';
import ProgressBar from '../../ui/Features/ProgressBar/ProgressBar';
import styles from './ProgressHeader.module.scss';


const ProgressHeader = () => {
    const { learningInfo } = useLearning();
    const summary = learningInfo?.MyAccount?.Summary;
    return (
        <section
            className={styles.progressHeader}
            style={{ '--bg-image': `url(${ImageLocation('discover-gesture.png')})` } as React.CSSProperties}
        >
            {/* <img className={styles.backGroundImage} src={ImageLocation('discover-gesture.png')} alt="logo" /> */}
            <div className={styles.content}>
                <div className={styles.avatarSection}>
                    <img className={styles.avatar} src={summary?.ImageUrl ?? ImageLocation('Profile-4.png')} alt="Avatar" />
                    <div className={styles.textContent}>
                        <h2>Knowledge Map</h2>
                        <h3>uncovered words</h3>
                    </div>
                    <h1 className={styles.uncoverCount}>{summary?.UncoveredCount}</h1>
                </div>
                <ProgressBar
                    knownCount={learningInfo?.KnownWordIds?.length || 0}
                    unknownCount={(learningInfo?.UnknownWordIds?.length || 0) -
                        (learningInfo?.ToLearn?.length || 0)}
                    remainCount={learningInfo?.ToLearn?.length || 0}
                    unknownColor='#D692FA'
                    remainColor='#AE24F6'
                    height={7} />

                <div className={styles.dataSection}>
                    <div className={styles.dataItem}>
                        <h3 className={styles.knewTitle}>Knew</h3>
                        <h4 className={styles.knewTitle}>{summary?.KnewCount}</h4>
                    </div>
                    <div className={styles.dataItem}>
                        <h3 className={styles.learningTitle}>Learning</h3>
                        <h4 className={styles.learningTitle}>{summary?.LearningCount}</h4>
                    </div>
                    <div className={styles.dataItem}>
                        <h3 className={styles.toLearnTitle}>To Learn</h3>
                        <h4 className={styles.toLearnTitle}>{summary?.ToLearnCount}</h4>
                    </div>
                </div>
                <button className={styles.continueButton}>Continue</button>
            </div>
        </section>
    );
};

export default ProgressHeader;