import { WordCard } from "src/components/ui/GlobalComponents/WordCard/WordCard";
import { Word } from "../../../../model/Word";
import styles from "./ListView.module.scss";

interface ListViewProps {
    words: Word[];
    setCurrentWord: (word: Word) => void;
}

const ListView = ({ words, setCurrentWord }: ListViewProps) => {


    return (
        <section className={styles.listView}>
            {words.map((word) => (
                <div key={word.ID} onClick={() => setCurrentWord(word)}>
                    <WordCard word={word} showTranslate={true}  />
                </div>
            ))}
        </section>
    );
};

export default ListView;