import { useCallback, useState } from 'react';
import { useAudio } from 'src/hook/global/useAudio';
import useUser from '../../../../hook/data/useUser';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './AudioPlayButton.module.scss';
interface AudioPlayButtonProps {
    wordId: number;
    onPlayStart?: () => void;
    onPlayEnd?: () => void;
}
const AudioPlayButton = ({
    wordId,
    onPlayStart,
    onPlayEnd
}: AudioPlayButtonProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const { playWord } = useAudio();

    const handleClick = useCallback(() => {
        setIsPlaying(true);
        onPlayStart?.(); 

        playWord(wordId);
        setTimeout(() => {

            setIsPlaying(false);
            onPlayEnd?.();
        }, 500);
    }, [wordId, playWord, onPlayStart, onPlayEnd]);

    return (
        <img
            src={IconLocation('play2')}
            alt='play audio'
            className={`${styles.playButton} ${isPlaying ? styles.playing : ''}`}
            onClick={handleClick}
        />
    );
};
export default AudioPlayButton;