import { useState } from 'react';
import Modal from 'src/components/ui/Features/Modal/Modal';
import { useWordList } from 'src/hook/data/useWordList';
import { useToast } from 'src/hook/global/useToasts';
import { Word } from 'src/model/Word';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './CreateNewListModal.module.scss';

interface CreateNewListModalProps {
    isOpen: boolean;
    onClose: () => void;
    word: Word;
    senseId: string;
}

export const CreateNewListModal = ({ isOpen, onClose, word, senseId }: CreateNewListModalProps) => {
    const { createCustomList, addWordToCustomList } = useWordList();
    const toast = useToast();
    const [listName, setListName] = useState('');



    const handleSubmit = async () => {
        const list = createCustomList(listName.trim(), false);
        if (!list) return;

        addWordToCustomList(list, word, senseId);
        onClose();
        toast.showSuccess('List updated.');
    };



    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.createNewListModal}>

                <div className={styles.modalHeader}>
                    <h1>Create new list</h1>
                    <img src={IconLocation('x')} alt="close" onClick={onClose} />
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.inputContainer}>
                        <label htmlFor="listName" className={styles.label}>
                            List Name
                        </label>
                        <input
                            id="listName"
                            type="text"
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                            className={styles.input}
                            placeholder="Enter list name"
                            required
                        />
                    </div>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className={styles.continueButton}
                        disabled={!listName.trim()}

                    >
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    );
};