import { useDispatch, useSelector } from 'react-redux';
import { addToast, removeToast, ToastType } from '../../store/slices/toastSlice';
import { RootState } from '../../store/store';

export const useToast = () => {
    const dispatch = useDispatch();
    const toasts = useSelector((state: RootState) => state.toast.toasts);

    const showToast = (message: string, type: ToastType) => {
        dispatch(addToast({ message, type }));
    };

    const showSuccess = (message: string) => {
        showToast(message, 'success');
    };

    const showError = (message: string) => {
        showToast(message, 'error');
    };

    const showInfo = (message: string) => {
        showToast(message, 'info');
    };

    const removeToastById = (id: number) => {
        dispatch(removeToast(id));
    };

    return {
        toasts,
        showToast,
        showSuccess,
        showError,
        showInfo,
        removeToastById,
    };
};