import React from 'react';

export const HighlightWord = (
    text: string | null = '',
    wordToHighlight: string | null = '',
    highlightColor: string = '#AE24F6'
): JSX.Element => {
    if (!text || !wordToHighlight) return <>{text}</>;

    text = text.trim()
    text = text.charAt(0).toUpperCase() + text.slice(1)


    const regex = new RegExp(`(${wordToHighlight})`, 'gi');
    const parts = text.split(regex);

    return (
        <>{parts.map((part, index) =>
            part.toLowerCase() === wordToHighlight.toLowerCase() ?
                <span key={index} style={{ color: highlightColor }}>{part}</span> :
                part
        )}</>
    );
};

export const HighlightTwoWords = (
    text: string | null = '',
    word1: string | null = '',
    word2: string | null = '',
    color1: string = '#AE24F6',
    color2: string = '#32BDD2'
): JSX.Element => {
    if (!text) return <>{text}</>;

    // Only trim the start and end, not internal whitespace
    text = text.replace(/^\s+|\s+$/g, '');
    text = text.charAt(0).toUpperCase() + text.slice(1);

    // Create regex patterns for both words
    const regex1 = word1 ? new RegExp(`(${word1})`, 'gi') : null;
    const regex2 = word2 ? new RegExp(`(${word2})`, 'gi') : null;

    // Split by both words
    let parts: string[] = [text];

    if (regex1) {
        parts = parts.flatMap(part => part.split(regex1!));
    }

    if (regex2) {
        parts = parts.flatMap(part => part.split(regex2!));
    }

    return (
        <>{parts.map((part, index) => {
            if (word1 && part.toLowerCase() === word1.toLowerCase()) {
                return <span key={index} style={{ color: color1 }}>{part}</span>;
            }
            if (word2 && part.toLowerCase() === word2.toLowerCase()) {
                return <span key={index} style={{ color: color2 }}>{part}</span>;
            }
            // Replace newline characters with <br /> tags
            if (part.includes('\n')) {
                return part.split('\n').map((line, i, arr) => (
                    <React.Fragment key={`${index}-${i}`}>
                        {line}
                        {i < arr.length - 1 && <br />}
                    </React.Fragment>
                ));
            }
            return part;
        })}</>
    );
};

export const CapitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const EncodeForUrl = (text: string) => {
    return text.replaceAll('%', '%25').replaceAll('&', '%26').replaceAll(';', '%3B');
};

export const LevenshteinDistance = (str1: string, str2: string): number => {
    const track = Array(str2.length + 1).fill(null).map(() =>
        Array(str1.length + 1).fill(null));

    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }

    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator // substitution
            );
        }
    }
    return track[str2.length][str1.length];
};

