import { FC, useState } from 'react';
import Modal from 'src/components/ui/Features/Modal/Modal';
import { useCountries } from 'src/hook/data/useCountries';
import { useSocial } from 'src/hook/data/useSocial';
import useUser from 'src/hook/data/useUser';
import { IconLocation, ImageLocation } from 'src/utils/URLUtils';
import styles from './SelectCountry.module.scss';

interface SelectCountryProps { }

export const SelectCountry: FC<SelectCountryProps> = () => {
    const { userInfo } = useUser();
    const { getCountryByCode, countries } = useCountries();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(userInfo?.CountryCode);
    const { fetchSocialInfo } = useSocial();


    const handleCountrySelect = (countryCode: string) => {
        setSelectedCountry(countryCode);
        setIsModalOpen(false);
        fetchSocialInfo(userInfo?.UserId!, countryCode);
    };

    const filteredCountries = countries.filter(country =>
        country.EnglishName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <button className={styles.addFriend} onClick={() => setIsModalOpen(true)}>
                <span>{getCountryByCode(selectedCountry)?.EnglishName}</span>
                <img src={IconLocation('chevron-down', true)} alt='Plus' />
            </button >

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalHeader}>
                        <h2>Choose a Country</h2>
                        <img src={IconLocation('x')} alt="close" onClick={() => setIsModalOpen(false)} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search Country"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchBox}
                    />
                    <ul className={styles.countryList}>
                        {filteredCountries.map(country => (
                            <li
                                key={country.CountryCode}
                                onClick={() => handleCountrySelect(country.CountryCode)}
                                className={`${styles.countryItem} ${country.CountryCode === selectedCountry ? styles.selected : ''}`}
                            >
                                <img src={ImageLocation(`Flags/${country.CountryCode.toLowerCase()}.png`)} alt={country.EnglishName} className={styles.flag} />
                                {country.EnglishName}
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal>
        </>
    );
};