import { FC, memo, PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLearnWizard } from "src/hook/components/useLearnWizard";
import { useToast } from "src/hook/global/useToasts";
import { useComponentModal } from "src/hook/modal/useComponentModal";
import { useIFrameModal } from "src/hook/modal/useIFrameModal";
import usePopUp from "src/hook/modal/usePopUp";
import useUser from '../../hook/data/useUser';
import LearnWizard from '../Learn/LearnWizard/LearnWizard';
import ComponentModal from '../ui/Features/ComponentModal/ComponentModal';
import IFrameModal from '../ui/Features/IFrameModal/IFrameModal';
import PopUp from "../ui/Features/PopUp/PopUp";
import ToastContainer from '../ui/Features/Toast/ToastContainer';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import styles from './Layout.module.scss';
import Main from './Main/Main';

// Define pages that don't need layout
const PagesWithoutLayout = ['/wordlists/import-words', '/wordlists/add-words', '/wordlists/create-new', '/search', '/lessons/answers', '/lessons/lesson', '/wordlists/discover', '/learn/learn-wizard', '/wordlists/wordlist'];


const Layout: FC<PropsWithChildren> = ({ children }) => {
    const { isAuthenticated } = useUser();
    const location = useLocation();
    const [shouldShowLayout, setShouldShowLayout] = useState(false);
    const { isOpen: isOpenIFrameModal } = useIFrameModal();
    const { toasts } = useToast();
    const { isOpen: isOpenComponentModal } = useComponentModal();
    const { isOpen: isOpenLearnWizard } = useLearnWizard();
    const { isOpen: isOpenPopUp } = usePopUp();

    useEffect(() => {
        const hasLayout = !PagesWithoutLayout.includes(location.pathname) && isAuthenticated();
        setShouldShowLayout(hasLayout);
    }, [location.pathname, isAuthenticated]);

    return (
        <>
            {shouldShowLayout ? (
                <article className={styles.layout} >
                    <Header />
                    <Main>
                        {children}
                    </Main>
                    <Footer />
                </article >
            ) : (
                children
            )}
            {isOpenIFrameModal && <IFrameModal />}
            {isOpenComponentModal && <ComponentModal />}
            {toasts.length > 0 && <ToastContainer />}
            {isOpenLearnWizard && <LearnWizard />}
            {isOpenPopUp && <PopUp />}
        </>
    );
}

export default memo(Layout);