import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { LoadingStatus } from "../../model/enum/enums";
import { User } from '../../model/User';
import { userActions } from '../../store/slices/userSlice';
import { AppDispatch, RootState } from '../../store/store';
import { GetRazorSiteUrl } from "../../utils/URLUtils";
import useFetch from "../api/useFetch";

const useUser = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { send } = useFetch<any>();
    const ac = bindActionCreators(userActions, dispatch);
    const { userInfo, isUserLoaded, status, error } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (!isUserLoaded) {
            ac.getUser();
        }
    }, [ac, isUserLoaded]);

    const setUserInfo = useCallback((info: User | null) => {
        ac.setUser(info);
    }, [ac]);



    const updateUserInfo = useCallback((info: Partial<User>) => {
        ac.updateUser(info);
    }, [ac]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    const isAuthenticated = useCallback(() => {
        return !!userInfo?.Token;
    }, [userInfo?.Token]);

    const isNonEnglishNativeLanguage = useCallback(() => {
        if (!userInfo?.NativeLanguageCode) return false;
        return userInfo.NativeLanguageCode !== 'en';
    }, [userInfo?.NativeLanguageCode]);

    const fetchUserData = useCallback(async (token?: string): Promise<User | null> => {
        const { response } = await send(GetRazorSiteUrl('settings', token ?? userInfo?.Token));
        if (!response) return null;
        const user = { ...response, Token: token ?? userInfo?.Token };
        setUserInfo(user);
        return user;
    }, [userInfo?.Token, send, setUserInfo]);



    return {
        userInfo,
        setUserInfo,
        updateUserInfo,
        handleLogout,
        isAuthenticated,
        isUserLoaded,
        isNonEnglishNativeLanguage,
        fetchUserData,
        isLoading: status === LoadingStatus.LOADING,
        isError: status === LoadingStatus.FAILED,
        error
    }
};

export default useUser;