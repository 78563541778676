import { useState } from 'react';
import { useLearning } from 'src/hook/data/useLearning';
import useUser from 'src/hook/data/useUser';
import { useWords } from 'src/hook/data/useWords';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { UserFields } from 'src/model/enum/dataEnums';
import { AnimationType } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import { ImageLocation } from 'src/utils/URLUtils';
import { PageHeader } from '../ui/GlobalComponents/PageHeader/PageHeader';
import styles from './Search.module.scss';
import useProgress from 'src/hook/data/useProgress';

export const Search = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<Word[]>([]);
    const { searchWords, getWordByText } = useWords();
    const { open } = useComponentModal();
    const { userInfo } = useUser();
    const { learningInfo } = useLearning();
    const { updateUserInfoField } = useProgress();


    const handleSearch = (value: string) => {
        setSearchTerm(value);

        // Only search if there are 2 or more characters
        if (value.length >= 2) {
            const results = searchWords(value);
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }

    };

    const addToRecentSearches = (term: string) => {
        // Handle case when RecentSearches is undefined or empty
        const recentSearches = userInfo?.RecentSearches ? userInfo.RecentSearches.split('|') : [];
        const updatedSearches = [
            term,
            ...recentSearches.filter(search => search !== term)
        ].slice(0, 8);

        updateUserInfoField(UserFields.RecentSearches, updatedSearches.join('|'));
    };

    const clearRecentSearches = () => {
        updateUserInfoField(UserFields.RecentSearches, '');
    };

    const removeFromRecentSearches = (term: string) => {
        const updatedSearches = userInfo?.RecentSearches
            ? userInfo.RecentSearches.split('|').filter(search => search !== term)
            : [];

        updateUserInfoField(UserFields.RecentSearches, updatedSearches.join('|'));
    };

    const handleClickWord = (word: Word | undefined) => {
        if (!word) return;

        addToRecentSearches(word.Text.trim());

        open('Definition', { word: word }, AnimationType.SlideUp);
    };

    return (
        <div className={styles.search}>
            <PageHeader title='Search' />

            <div className={styles.searchBox}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search for a word"
                    className={styles.searchInput}
                />
                {searchTerm && (
                    <span
                        className={styles.clearSearch}
                        onClick={() => {
                            setSearchTerm('');
                            setSearchResults([]);
                        }}
                    >
                        ×
                    </span>
                )}
            </div>

            {/* Show search results when we have a search term of 2 or more characters */}
            {searchTerm.length >= 2 && searchResults.length > 0 && (
                <div className={styles.searchResults}>
                    {searchResults.map((word, index) => (
                        <div key={word.ID}>
                            <div className={styles.wordItem} onClick={() => handleClickWord(word)}>
                                <div className={styles.wordMain}>
                                    <h3>{word.Text}</h3>
                                    <span className={styles.wordRank + ' ' +
                                        (learningInfo?.KnownWordIds.includes(word.ID) ?
                                            styles.known :
                                            learningInfo?.UnknownWordIds.includes(word.ID) ?
                                                styles.learn :
                                                '')}>
                                        #{word.CustomRank.toLocaleString('en-US')}
                                    </span>
                                </div>
                                <p className={styles.wordForms}>
                                    {word.AllForms.join(', ') || '\u00A0'}</p>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            )}

            {/* Show recent searches only when there's no search term */}
            {(searchTerm?.length < 2 || searchResults.length === 0) && userInfo?.RecentSearches && (
                <div className={styles.recentSearches}>
                    <div className={styles.recentHeader}>
                        <span>Recent searches</span>
                        <span onClick={clearRecentSearches}
                            className={styles.clearButton}>
                            Clear
                        </span>
                    </div>
                    <hr />
                    <div className={styles.searchList}>
                        {userInfo.RecentSearches.split('|').map((term, index) => (
                            term && (
                                <div key={index}>
                                    <div className={styles.searchItem}>
                                        <span
                                            className={styles.searchTerm}
                                            onClick={() => handleClickWord(getWordByText(term))}>{term}</span>
                                        <span
                                            onClick={() => removeFromRecentSearches(term)}
                                            className={styles.removeButton}
                                        >
                                            ×
                                        </span>
                                    </div>
                                    <hr />
                                </div>
                            )
                        ))}
                    </div>
                </div>
            )}

            {/* Show empty Image */}
            {(searchTerm?.length < 2 || searchResults.length === 0) && !userInfo?.RecentSearches && (
                <div className={styles.empty}>
                    <img src={ImageLocation('SearchBackground.png')}
                        className={styles.emptyImage}
                        alt="SearchBackground.png" />
                    {searchTerm?.length === 0 ? <p>Type in at least 2 characters</p>
                        : searchTerm?.length === 1 ? <p>Type 1 more character</p>
                            : <p>Found no matching record :(</p>}
                </div>
            )}
        </div>
    );
};
