import Flag from 'src/components/ui/GlobalComponents/Flag/Flag';
import { useSmartWord } from 'src/hook/global/useSmartWord';
import { Word } from 'src/model/Word';
import { QuoteData } from '../../../../model/WordData';
import styles from './Quote.module.scss';


interface QuoteProps {
    data: QuoteData;
    word: Word
}

export const Quote = ({ data, word }: QuoteProps) => {
    const { HighlightWord } = useSmartWord(true);
    return (
        <div className={styles.quote}>
            <img src={data.ImageUrl} className={styles.quoteImage} alt={data.Name} />
            <h2 className={styles.quoteAuthor}>{data.Name}</h2>
            <h3 className={styles.quoteTitle}>{data.Title}</h3>
            <p className={styles.quoteDescription}>
                ❝{HighlightWord(data.Description, word.Text)}❞
            </p>
            <Flag text={data.Description} />
        </div>
    );
};

export default Quote;