import { useDispatch, useSelector } from 'react-redux';
import { hidePopUp } from 'src/store/slices/popUpSlice';
import { RootState } from 'src/store/store';
import Modal from '../Modal/Modal';
import styles from './PopUp.module.scss';

const PopUp = () => {
    const dispatch = useDispatch();
    const {
        isOpen,
        title,
        message,
        primaryButtonText,
        primaryButtonClick,
        secondaryButtonText,
        secondaryButtonClick,
        showSecondaryButton
    } = useSelector((state: RootState) => state.popUp);

    const handleClose = () => {
        dispatch(hidePopUp());
    };

    const handlePrimaryButtonClick = () => {
        if (primaryButtonClick) {
            primaryButtonClick();
        }
        handleClose();
    };

    const handleSecondaryButtonClick = () => {
        if (secondaryButtonClick) {
            secondaryButtonClick();
        }
        handleClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <div className={styles.popUp}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.message}>{message}</p>
                <hr />
                <div className={styles.buttonContainer}>
                    {showSecondaryButton && (
                        <button
                            className={`secondaryButton`}
                            onClick={handleSecondaryButtonClick}
                        >
                            {secondaryButtonText || 'Cancel'}
                        </button>
                    )}
                    <button
                        className={`${styles.button} ${styles.primaryButton}`}
                        onClick={handlePrimaryButtonClick}
                    >
                        {primaryButtonText || 'OK'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default PopUp;
