import styles from './ProgressBar.module.scss';

interface WordCountHRProps {
    knownCount?: number;
    knownColor?: string;
    unknownCount?: number;
    unknownColor?: string;
    remainCount?: number;
    remainColor?: string;
    totalCount?: number;
    height?: number;
}

const ProgressBar =
    ({ knownCount = 0, unknownCount = 0, remainCount = 0, totalCount = 0, height = 4,
        knownColor = '#32BDD2', unknownColor = '#AE24F6', remainColor = '#D9DDED'
    }: WordCountHRProps) => {
        if (totalCount === 0) {
            totalCount = knownCount + unknownCount + remainCount;
        }
        if (remainCount === 0) {
            remainCount = totalCount - (knownCount + unknownCount)
        }

        const getPercentage = (count: number) => (count / totalCount) * 100;

        return (
            <div className={styles.progressBar} style={{ height: `${height}px` }}>
                <div
                    className={`${styles.hrSegment} ${styles.known}`}
                    style={{ width: `${getPercentage(knownCount)}%`, backgroundColor: knownColor }}
                />
                <div
                    className={`${styles.hrSegment} ${styles.unknown}`}
                    style={{ width: `${getPercentage(unknownCount)}%`, backgroundColor: unknownColor }}
                />
                <div
                    className={`${styles.hrSegment} ${styles.remain}`}
                    style={{ width: `${getPercentage(remainCount)}%`, backgroundColor: remainColor }}
                />
            </div>
        );
    };

export default ProgressBar;