import { useCallback, useEffect, useState } from 'react';
import { useLearning } from '../../../../../hook/data/useLearning';
import { Actions } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import styles from './ButtonSection.module.scss';


type ButtonSectionProps = {
    word: Word;
}

export const ButtonSection = ({ word }: ButtonSectionProps) => {
    const { shouldLearn, alreadeyKnew, forget } = useLearning();
    const { learningInfo } = useLearning();
    const [existWord, setExistWord] = useState<string | null>(null);


    const handleShouldLearn = useCallback(() => {
        shouldLearn(word);
    }, [word, shouldLearn]);

    const handleAlreadyKnew = useCallback(() => {
        alreadeyKnew(word);
    }, [word, alreadeyKnew]);

    const handleContinue = useCallback(() => {
        forget(word);
    }, [word, forget]);


    useEffect(() => {
        if (learningInfo?.KnownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Knew)
        else if (learningInfo?.UnknownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Learn)
        else
            setExistWord('');
    }, [learningInfo?.KnownWordIds, learningInfo?.UnknownWordIds, word.ID]);


    if (existWord === null)
        return null;

    if (!existWord) {
        return (
            <section className={styles.buttonSection}>
                <button onClick={() => handleShouldLearn()} >Should learn</button>
                <button onClick={() => handleAlreadyKnew()} className='turquoiseButton'>Already knew</button>
            </section>
        );
    }

    return (
        <section onClick={handleContinue}
            className={`${styles.styledButton} ${existWord === Actions.Knew ? styles.knew : styles.learn}`}>
            {existWord === Actions.Knew ? 'Already Knew' : 'Should Learn'} ▼
        </section>


    );
};
