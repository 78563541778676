import React, { useEffect, useRef } from 'react';
import { useSmartWord } from 'src/hook/global/useSmartWord';
import { Word } from 'src/model/Word';
import { VideosData } from '../../../../model/WordData';
import styles from './Video.module.scss';
interface VideoProps {
    data: VideosData;
    word: Word;
}

export const Video: React.FC<VideoProps> = ({ data, word }) => {
    const videoRef = useRef<HTMLIFrameElement>(null);
    const { HighlightWord } = useSmartWord(true);

    const getEnabledApiUrl = (url: string) => {
        const separator = url.includes('?') ? '&' : '?';
        return `${url}${separator}enablejsapi=1&origin=${window.location.origin}`;
    };

    useEffect(() => {
        const video = videoRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (video && video.contentWindow && !entry.isIntersecting) {
                    const command = JSON.stringify({
                        event: 'command',
                        func: 'pauseVideo',
                        args: ''
                    });
                    video.contentWindow.postMessage(command, '*');
                }
            },
            { threshold: 0.5 }
        );
        if (video) {
            observer.observe(video);
        }
        return () => {
            if (video) {
                observer.unobserve(video);
            }
        };
    }, []);

    return (
        <div className={styles.video}>
            <div className={styles.videoPlayer}>
                <iframe
                    ref={videoRef}
                    width="100%"
                    height="250"
                    src={getEnabledApiUrl(data.VideoUrl)}
                    title={data.Title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            <p className={styles.description}>
                ...{HighlightWord(data.Description, word.Text)}
            </p>
        </div>
    );
}
export default Video;