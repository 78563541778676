import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useProgress from 'src/hook/data/useProgress';
import useUser from 'src/hook/data/useUser';
import { useWordTranslate } from 'src/hook/data/useWordTranslate';
import { useIFrameModal } from '../../../../hook/modal/useIFrameModal';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './IFrameModal.module.scss';
import Spinner from '../Spinner/Spinner';
import { useLearning } from 'src/hook/data/useLearning';

const IFrameModal = () => {
  const { isOpen, url, title, close } = useIFrameModal();
  const [hasNavigated, setHasNavigated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { fetchUserData } = useUser();
  const { fetchTranslates } = useWordTranslate(false);
  const navigate = useNavigate();
  const { uploadProgressAtomic } = useProgress();
  const { fetchLearningData } = useLearning();

  useEffect(() => {
    const init = async () => {
      await uploadProgressAtomic();
      setIsLoading(false);
    };
    init();

    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'NAVIGATION_COMPLETED') {
        setHasNavigated(true);
      } else if (event.data.type === 'NAVIGATION_BACK_COMPLETED') {
        setHasNavigated(false);
      }
      else if (event.data.type === 'NAVIGATION_TO_DOMAIN') {
        close();
        navigate(event.data.url);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [close, navigate, uploadProgressAtomic]);


  const updateData = async () => {
    const user =   await fetchUserData();
    fetchTranslates(user?.NativeLanguageCode);
    fetchLearningData();

  };

  const handleClose = async () => {
    const iframe = document.querySelector('iframe');
    if (iframe?.contentWindow && hasNavigated) {
      iframe.contentWindow.postMessage({ type: 'NAVIGATE_BACK' }, '*');
    } else {
      close();
      updateData();
    }
  };

  if (!isOpen) return null;
  if (isLoading) return <Spinner />

  return (
    <div className={styles.iFrameModal}>
      <div className={styles.iframeHeader}>
        <img
          className={styles.closeButton}
          src={IconLocation('chevron-left')}
          alt="close"
          onClick={handleClose}
        />
        {!hasNavigated && <h1>{title}</h1>}
      </div>
      <div className={styles.iframeContainer}>
        <iframe
          src={url}
          title={title}
          allow="fullscreen"
          sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  );
};

export default IFrameModal;