import { bindActionCreators } from '@reduxjs/toolkit';
import pako from 'pako';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translatesActions } from 'src/store/slices/wordTranslateSlice';
import { RootState } from 'src/store/store';
import { WordTranslate } from '../../model/Word';
import { GetTranslateFilesUrl } from '../../utils/URLUtils';
import useUser from './useUser';

export const useWordTranslate = (autoLoad: boolean = true) => {
    const dispatch = useDispatch();
    const { translates } = useSelector((state: RootState) => state.translates);
    const ac = bindActionCreators(translatesActions, dispatch);
    const { userInfo, isNonEnglishNativeLanguage } = useUser();

    const fetchTranslates = useCallback(async (langCode?: string) => {
        if (!langCode || langCode === 'en') return;
        const response = await fetch(GetTranslateFilesUrl(langCode));
        if (!response.ok) {
            console.error('Failed to fetch translations file');
            return;
        }

        const compressed = await response.arrayBuffer();
        const decompressed = pako.inflate(new Uint8Array(compressed), { to: 'string' });
        let parsedData: WordTranslate = {} as WordTranslate;

        try {
            // First try direct parsing
            parsedData = JSON.parse(decompressed) as WordTranslate;
        } catch (error) {
            // If direct parsing fails, try with thorough cleaning
            const cleanedText = decompressed
                .replaceAll(/[\x00-\x1F\x7F]/g, '') // Remove control characters
                .replaceAll(/\\/g, '\\\\') // First double all backslashes
                .replaceAll(/\\\\"/g, '\\"') // Fix double-escaped quotes
                .replaceAll(/\\\\\\"/g, '\\"') // Fix triple-escaped quotes
                .replaceAll(/[^\x20-\x7E\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\s{}[\]:"',.-]/g, '') // Keep only valid characters, Arabic/Persian ranges, and JSON syntax
                .trim();

            try {
                parsedData = JSON.parse(cleanedText) as WordTranslate;
            } catch (parseError) {
                console.error('Failed to parse JSON after cleaning:', parseError);
                console.log('Error position:', (parseError as SyntaxError).message);
                console.log('Characters around error:',
                    cleanedText.substring(4672406, 4672426));
            }
        }

        ac.setWordTranslates(parsedData);

    }, [ac]);


    const getTranslateBySenseId = useCallback((senseId: string) => {
        return translates[senseId];
    }, [translates]);


    useEffect(() => {
        if (autoLoad && isNonEnglishNativeLanguage() && Object.keys(translates).length === 0) {
            fetchTranslates(userInfo?.NativeLanguageCode);
        }
    }, [autoLoad, fetchTranslates, userInfo?.NativeLanguageCode,
        isNonEnglishNativeLanguage, translates]);

    return {
        translates,
        fetchTranslates,
        getTranslateBySenseId
    };
}; 