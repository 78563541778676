import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWordList } from 'src/hook/data/useWordList';
import { useLearning } from '../../../hook/data/useLearning';
import useUser from '../../../hook/data/useUser';
import { useWords } from '../../../hook/data/useWords';
import { Direction, DiscoverView, WordListType } from '../../../model/enum/enums';
import { Word, WordType } from '../../../model/Word';
import { WordList } from '../../../model/WordList';
import styles from './DiscoverPage.module.scss';
import { DiscoverPageHeader } from './DiscoverPageHeader/DiscoverPageHeader';
import KnowledgeMap from './KnowledgeMap/KnowledgeMap';
import ListView from './ListView/ListView';
import { Slider } from './Slider/Slider';
import TagsView from './TagsView/TagsView';
import Spinner from 'src/components/ui/Features/Spinner/Spinner';

export const DiscoverPage = () => {
    const { getWordsByRange, getWordsByWordList, getWordsByCustomList } = useWords();
    const { userInfo } = useUser();
    const [words, setWords] = useState<Word[]>([]);
    const [filterWords, setFilterWords] = useState<Word[]>([]);
    const { learningInfo } = useLearning();
    const [currentWord, setCurrentWord] = useState<Word>();
    const [currentRange, setCurrentRange] = useState<number>(1);
    const [wordList, setWordList] = useState<WordList>();
    const [selectedView, setSelectedView] = useState<DiscoverView>(DiscoverView.Cards);
    const [forceChangeView, setForceChangeView] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const wordListId = searchParams.get('id');
    const range = searchParams.get('range');
    const { fetchCustomListData, fetchWordListData } = useWordList();


    const filterWordsByRange = useCallback(async (range: number, direction?: Direction) => {
        if (range < 1 || range > 243) return;
        if (!wordList) return;

        let fetchedWords;
        if (wordList?.Type === WordListType.All)
            fetchedWords = getWordsByRange(range);
        else if (wordList?.Type === WordListType.Custom) {
            if (wordList.CustomListData && wordList.CustomListData.length > 0) {
                fetchedWords = getWordsByCustomList(wordList.CustomListData, range);
            }
            else {
                const data = await fetchCustomListData(wordList);
                if (data) {
                    fetchedWords = getWordsByCustomList(data, range);
                }
            }

        }
        else {
            const data = await fetchWordListData(wordList);
            if (data) {
                fetchedWords = getWordsByWordList(data, range);
            }
        }



        if (!fetchedWords || fetchedWords.length === 0) return;



        const filteredWords = fetchedWords.filter(word =>
            !learningInfo?.KnownWordIds.includes(word.ID) &&
            !learningInfo?.UnknownWordIds.includes(word.ID)
        );


        if (filteredWords.length === 0) {

            setSelectedView(DiscoverView.List);
            setForceChangeView(true);
            // if (!direction) direction = Direction.Next;
            // const newRange = direction === Direction.Next ? range + 1 : range - 1;
            // filterWordsByRange(newRange, direction);
            // return;
        }

        else if (forceChangeView) {
            setForceChangeView(false);
            setSelectedView(DiscoverView.Cards);
        }

        setCurrentRange(range);
        setCurrentWord(filteredWords[0] || fetchedWords[0]);
        setWords(fetchedWords);
        setFilterWords(filteredWords);

    }, [learningInfo, getWordsByRange, getWordsByWordList,
        getWordsByCustomList, wordList, forceChangeView,
        fetchCustomListData, fetchWordListData]);


    const handleRangeChange = useCallback((newRange: number) => {
        const direction = newRange > currentRange ? Direction.Next : Direction.Prev;
        filterWordsByRange(newRange, direction);
    }, [filterWordsByRange, currentRange]);

    const goToNextRange = useCallback(() => {
        filterWordsByRange(currentRange + 1, Direction.Next);
    }, [filterWordsByRange, currentRange]);


    useEffect(() => {
        if (words?.length > 0 || !wordListId) return;

        const wordList = learningInfo?.WordLists.find(x => x.ID === wordListId);
        if (!wordList) return;

        setWordList(wordList);

        const customRange = range || userInfo?.SelectedRange || 1;

        filterWordsByRange(wordList.IsAll ? Number(customRange) : 1);

    }, [range, wordListId, filterWordsByRange, userInfo?.SelectedRange, words?.length, learningInfo?.WordLists]);


    const renderSelectedView = () => {
        // if (!learningInfo || !userInfo) {
        //     return <Spinner />;
        // }
        switch (selectedView) {
            case DiscoverView.Cards:
                return <Slider words={filterWords} setCurrentWord={setCurrentWord} goToNextRange={goToNextRange} />;
            case DiscoverView.Tags:
                return <TagsView words={words} setCurrentWord={setCurrentWord} />;
            case DiscoverView.List:
            default:
                return <ListView words={words} setCurrentWord={setCurrentWord} />;
        }
    };


    if (!wordList || !words || !filterWords) return <Spinner />;

    return (
        <article className={`fullPage ${styles.discoverPage}`}>
            <DiscoverPageHeader onViewChange={setSelectedView}
                wordList={wordList!} isFull={forceChangeView} />
            {renderSelectedView()}
            <KnowledgeMap currentWord={currentWord}
                currentRangeNumber={currentRange} words={words}
                wordType={wordList?.Title === 'Idioms' ? WordType.Phrase : WordType.Word}
                onRangeChange={handleRangeChange} />
        </article>
    );
};