import pako from 'pako';
import { useCallback, useState } from 'react';
import { GetTranslateApiUrl } from '../../utils/URLUtils';
import useUser from './useUser';

interface UseTranslateReturn {
    translate: (text: string) => Promise<string>;
    translateMultiple: (texts: string[]) => Promise<string[]>;
    isLoading: boolean;
}

export const useTranslate = (): UseTranslateReturn => {
    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const [isTranslating, setIsTranslating] = useState(false);
    const [isTranslatingMultiple, setIsTranslatingMultiple] = useState(false);

    const toGZippedBase64 = useCallback((text: string): string => {
        const data = new TextEncoder().encode(text);
        const compressed = pako.gzip(data);
        return btoa(String.fromCharCode.apply(null, Array.from(compressed)));
    }, []);

    // Helper function to decode Base64 and decompress GZip
    const fromGZippedBase64 = useCallback((base64: string): string => {
        const binary = atob(base64);
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }
        const decompressed = pako.ungzip(bytes);
        return new TextDecoder().decode(decompressed);
    }, []);

    const translate = useCallback(async (text: string): Promise<string> => {
        if (!text) return '';
        if (!isNonEnglishNativeLanguage()) return '';

        try {
            setIsTranslating(true);
            const compressedText = toGZippedBase64(text);
            const encodedText = encodeURIComponent(compressedText);

            const url = `${GetTranslateApiUrl()}?to=${userInfo?.NativeLanguageCode}&from=${encodedText}`;

            const response = await fetch(url);
            const compressedResponse = await response.text();

            if (!compressedResponse) return '';

            // Decode HTML entities and decompress
            const decodedText = decodeURIComponent(compressedResponse);
            const translatedText = fromGZippedBase64(decodedText);

            return translatedText;
        } finally {
            setIsTranslating(false);
        }
    }, [userInfo?.NativeLanguageCode, isNonEnglishNativeLanguage, toGZippedBase64, fromGZippedBase64]);

    const translateMultiple = useCallback(async (texts: string[]): Promise<string[]> => {
        if (!isNonEnglishNativeLanguage()) return [];
        if (!texts.length) return [];

        try {
            setIsTranslatingMultiple(true);
            return Promise.all(
                texts.map(text => translate(text))
            );
        } finally {
            setIsTranslatingMultiple(false);
        }
    }, [translate, isNonEnglishNativeLanguage]);


    return {
        translate,
        translateMultiple,
        isLoading: isTranslating || isTranslatingMultiple,
    };
};
