export const Question_STEPS = [
    'Listen to the question',
    'Pick the correct translation',
    'Ask the question',
    'Practice pronunciation',
    'Write in English'
];

export const Answer_STEPS = [
    'Listen to the answer',
    'Pick the correct translation',
    'Sort the answer',
    'Practice pronunciation',
    'Write in English'
];