import Modal from 'src/components/ui/Features/Modal/Modal';
import { useScenario } from 'src/hook/data/useScenario';
import { AnswerDone, Question } from 'src/model/Scenario';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './SkipModal.module.scss';

interface SkipModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    question: Question;
    answerDone: AnswerDone;
}

const SkipModal = ({ isModalOpen, setIsModalOpen, question, answerDone }: SkipModalProps) => {
    const { addAnswer } = useScenario();


    const handleConfirm = async () => {
        setIsModalOpen(false);
        addAnswer(question, answerDone.answer);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <div className={styles.skipModal}>
                <div className={styles.header}>
                    <h2>Already know it?</h2>
                    <img src={IconLocation('x')} className={styles.close}
                        alt='close' onClick={() => setIsModalOpen(false)} />
                </div>
                <p>Do you already know this answer?</p>
                <hr />
                <div className={styles.buttons}>
                    <button onClick={() => setIsModalOpen(false)}
                        className='secondaryButton'>Cancel</button>
                    <button onClick={handleConfirm}
                        className={styles.confirm}>Confirm</button>
                </div>
            </div>
        </Modal>
    );
};

export default SkipModal;