import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Progress } from "src/model/Progress";
import { progressActions } from "src/store/slices/progressSlice";
import { User } from "../../model/User";
import { UserFields } from "../../model/enum/dataEnums";
import { AppDispatch, RootState } from "../../store/store";
import { insertIntoQueue } from "../../utils/QueueUtils";
import { GetAtomicSaveUrl, GetQueueUrl } from "../../utils/URLUtils";
import useUser from "./useUser";

const useProgress = () => {
    const dispatch = useDispatch<AppDispatch>();
    const ac = bindActionCreators(progressActions, dispatch);
    const progressList = useSelector((state: RootState) => state.progress.progressList);
    const { userInfo, updateUserInfo } = useUser();



    const clearProgress = useCallback(() => {
        ac.setProgress([]);
    }, [ac]);

    const setProgress = useCallback((progress: Progress[]) => {
        ac.setProgress(progress);
    }, [ac]);

    const uploadProgress = useCallback((progress: Progress[]) => {
        if (!progressList?.length) return;


        const actionsString = progress.map(
            progress => `${progress.Action}:${progress.Value}`
        ).join('|');

        console.log("actionsString", encodeURIComponent(actionsString))
        // Prepare jsonData
        const jsonData = JSON.stringify({
            Token: userInfo?.Token,

            Actions: encodeURIComponent(actionsString),
            Platform: "Web"
        });


        insertIntoQueue(GetQueueUrl('SaveProgress'), jsonData);

    }, [userInfo?.Token, progressList]);


    const uploadProgressAtomic = useCallback(async () => {
        if (!progressList?.length) return;



        const actionsString = progressList.map(
            progress => `${progress.Action}:${progress.Value}`
        ).join('|');


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        console.log("actionsString", encodeURIComponent(actionsString))

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: encodeURIComponent(actionsString),
            redirect: "follow"
        };

        await fetch(GetAtomicSaveUrl(userInfo?.Token!), requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));



        ac.setProgress([]);
    }, [ac, userInfo?.Token, progressList]);



    const saveProgress = useCallback((progress: Progress | Progress[]) => {
        const updatedProgress = [...progressList || [],
        ...(Array.isArray(progress) ? progress : [progress])];

        if (updatedProgress.length >= 5) {
            uploadProgress(updatedProgress);
            ac.setProgress([]);
        }


        else {
            ac.setProgress(updatedProgress);
        }


    }, [ac, progressList, uploadProgress]);



    const updateUserInfoField = useCallback((field: keyof User, value: string | boolean | number) => {
        updateUserInfo({ [field]: value });
        if (field === UserFields.RecentSearches) {
            value = value.toString().replaceAll('|', ',');
        }

        saveProgress({ Action: 'user-data', Value: `${field}=${value}` });
    }, [saveProgress, updateUserInfo]);



    return {
        uploadProgress,
        saveProgress,
        updateUserInfoField,
        uploadProgressAtomic,
        clearProgress,
        setProgress,
        progressList

    };
};

export default useProgress;
