import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../model/enum/enums";
import { Progress } from "../../model/Progress";
import { progressStorage } from "../../utils/StorageUtils";

interface ProgressState {
    progressList: Progress[];
    isProgressLoaded: boolean;
    status: LoadingStatus;
    error: string | null;
}

const initialState: ProgressState = {
    progressList: [],
    isProgressLoaded: false,
    status: LoadingStatus.IDLE,
    error: null
};

const progressSlice = createSlice({
    name: "progress",
    initialState,
    reducers: {
        getProgress: (state) => {
            state.progressList = progressStorage.get() || [];
            state.isProgressLoaded = true;
        },
        setProgress: (state, action: PayloadAction<Progress[]>) => {
            progressStorage.set(action.payload);
            state.progressList = action.payload;
            state.isProgressLoaded = true;
        },
        addProgress: (state, action: PayloadAction<Progress>) => {
            const updatedList = [...state.progressList, action.payload];
            progressStorage.set(updatedList);
            state.progressList = updatedList;
        },
        logout: (state) => {
            Object.assign(state, initialState);
            progressStorage.set(null);
        },
    },
});


export const progressActions = progressSlice.actions;
export default progressSlice.reducer; 