import Modal from 'src/components/ui/Features/Modal/Modal';
import { SortType } from 'src/model/enum/enums';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './SortModal.module.scss';

interface SortModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedItem: SortType;
    setSelectedItem: (selectedItem: SortType) => void;
}


export const SortModal = ({ isOpen, onClose, selectedItem, setSelectedItem }: SortModalProps) => {

    const handleItemClick = (item: SortType) => {
        setSelectedItem(item);
        onClose();
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.sortModal}>
                <div className={styles.modalHeader}>
                    <h2>Sort by...</h2>
                    <img src={IconLocation('x')} alt="close" onClick={onClose} />
                </div>
                <div className={styles.modalBody}>
                    <div
                        className={`${styles.modalBodyItem} ${selectedItem === SortType.Alphabetic ? styles.active : ''}`}
                        onClick={() => handleItemClick(SortType.Alphabetic)}
                    >

                        Alphabetic
                    </div>
                    <div
                        className={`${styles.modalBodyItem} ${selectedItem === SortType.HardestFirst ? styles.active : ''}`}
                        onClick={() => handleItemClick(SortType.HardestFirst)}
                    >
                        Hardest First
                    </div>

                    <div
                        className={`${styles.modalBodyItem} ${selectedItem === SortType.EasiestFirst ? styles.active : ''}`}
                        onClick={() => handleItemClick(SortType.EasiestFirst)}
                    >
                        Easiest First

                    </div>
                </div>
            </div>
        </Modal>
    );
};