import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLearnWizard } from 'src/hook/components/useLearnWizard';
import { useWordData } from 'src/hook/data/useWordData';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { useLearning } from '../../../hook/data/useLearning';
import { Actions, DefinitionSource, LearnWizardStep, WordListType,LearningArray } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import styles from './DefinitionFooter.module.scss';


type DefinitionFooterProps = {
    word: Word;
    moveNext: () => void;
    source?: DefinitionSource;
}

export const DefinitionFooter = ({ word, moveNext, source }: DefinitionFooterProps) => {
    const { shouldLearn, alreadeyKnew, forget } = useLearning();
    const { learningInfo, removeWord } = useLearning();
    const [existWord, setExistWord] = useState<string | null>(null);
    const { open } = useLearnWizard();
    const { startClosing, close } = useComponentModal();
    const { wordData } = useWordData(word);
    const [searchParams] = useSearchParams();
    const wordListId = searchParams.get('id');
    const navigate = useNavigate();
    const { close: closeLearnWizard } = useLearnWizard();


    const handleShouldLearn = useCallback(() => {
        shouldLearn(word, wordListId === WordListType.All);
    }, [word, shouldLearn, wordListId]);

    const handleAlreadyKnew = useCallback(() => {
        alreadeyKnew(word, wordListId === WordListType.All);
        moveNext?.();
        startClosing();
    }, [word, alreadeyKnew, moveNext, startClosing, wordListId]);

    const handleRemove = useCallback(() => {
        forget(word);
    }, [word, forget]);

    const handleLearnNowContinue = useCallback(async () => {
        if (source === DefinitionSource.Learn) {
            startClosing();
            closeLearnWizard();
            removeWord(LearningArray.ToLearn, word.ID);
            navigate('/learn');
        }
        else if (existWord === Actions.Knew) {
            startClosing();
            moveNext?.();
        } else {
            close();
            open(LearnWizardStep.First, { word, wordData });
        }
    }, [moveNext, startClosing, open, word, closeLearnWizard,
        wordData, existWord, close, source, removeWord, navigate]);

    useEffect(() => {
        if (learningInfo?.KnownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Knew)
        else if (learningInfo?.UnknownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Learn)
        else
            setExistWord('');
    }, [learningInfo?.KnownWordIds, learningInfo?.UnknownWordIds, word.ID]);



    if (existWord === null)
        return null;

    return (
        <section className={styles.definitionFooter}>
            {!existWord && <div className={styles.buttonSection}>
                <button onClick={() => handleShouldLearn()} >Should learn</button>
                <button onClick={() => handleAlreadyKnew()} className='turquoiseButton'>Already knew</button>
            </div>}
            {existWord && <div className={styles.selectSection}>
                <div className={styles.labelSection}>
                    <span className={styles.label}>Your knowledge:</span>
                    <div onClick={handleRemove}
                        className={`${styles.styledButton} ${existWord === Actions.Knew ? styles.knew : styles.learn}`}>
                        {existWord === Actions.Knew ? 'Already Knew' : 'Should Learn'} ▼
                    </div>
                </div>
                <button className={styles.learnNowButton} onClick={handleLearnNowContinue}>
                    {existWord === Actions.Knew || source === DefinitionSource.Learn
                        ? 'Continue' : 'Learn Now'}
                </button>
            </div>}
        </section >
    );
};
