import { useDispatch, useSelector } from 'react-redux';
import { AnimationType, LearnWizardStep } from '../../model/enum/enums';
import { closeWizard, nextStep, openWizard, previousStep } from "../../store/slices/learnWizardSlice";
import type { RootState } from '../../store/store';

const WIZARD_STEPS = [LearnWizardStep.First, LearnWizardStep.Second, LearnWizardStep.Third, LearnWizardStep.Final];

export const useLearnWizard = () => {
    const dispatch = useDispatch();
    const {
        isOpen,
        stepKey,
        stepProps,
        animation,
    } = useSelector((state: RootState) => state.learnWizard);


    const close = () => {
        dispatch(closeWizard());
    };

    const open = (
        initialStep: LearnWizardStep = LearnWizardStep.First,
        props?: Record<string, any>,
        animation: AnimationType = AnimationType.SlideRight
    ) => {
        dispatch(openWizard({ stepKey: initialStep, props, animation }));
    };

    const goToNext = (animation: AnimationType = AnimationType.SlideLeft) => {
        const currentIndex = WIZARD_STEPS.indexOf(stepKey);
        if (currentIndex < WIZARD_STEPS.length - 1) {
            dispatch(nextStep({
                nextStep: WIZARD_STEPS[currentIndex + 1],
                animation
            }));
        }
    };

    const goToPrevious = (animation: AnimationType = AnimationType.SlideRight) => {
        const currentIndex = WIZARD_STEPS.indexOf(stepKey);
        if (currentIndex > 0) {
            dispatch(previousStep({
                previousStep: WIZARD_STEPS[currentIndex - 1],
                animation
            }));
        }
    };


    const isFirstStep = stepKey === WIZARD_STEPS[0];
    const isLastStep = stepKey === WIZARD_STEPS[WIZARD_STEPS.length - 1];

    return {
        isOpen,
        stepKey,
        stepProps,
        animation,
        open,
        close,
        goToNext,
        goToPrevious,
        isFirstStep,
        isLastStep
    };
};