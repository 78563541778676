import { useState } from 'react';
import { useComponentModal } from 'src/hook/modal/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { AnswerDone, Question } from 'src/model/Scenario';
import SkipModal from '../SkipModal/SkipModal';
import styles from './ActionButtons.module.scss';

interface Props {
    question: Question;
    answerDone: AnswerDone;
    translateText: string | null;
    voice?: string;
}

const ActionButtons = ({ question, answerDone, translateText, voice }: Props) => {
    const { open } = useComponentModal();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLearn = () => {
        const answer = answerDone.answer;
        open('LessonWizard', { question, translateText, answer, voice }, AnimationType.SlideRight)
    }

    if (answerDone.isDone) return (
        <div className={styles.done}>
            <span className={styles.tick}>✓</span>
            <button className={styles.repeat} onClick={handleLearn}>
                Repeat
            </button>
        </div>);


    return (
        <div className={styles.buttons}>
            <button onClick={handleLearn}>
                Learn
            </button>
            <button className='secondaryButton' onClick={() => setIsModalOpen(true)}>
                Skip
            </button>
            <SkipModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                question={question}
                answerDone={answerDone}
            />
        </div>
    );
};

export default ActionButtons;
