const PRAISE_MESSAGES = [
    "Well done!",
    "Great job!",
    "Nice!",
    "Great work!",
    "Bravo!",
    "Bingo!",
    "You got it!",
    "That's it!",
    "Exactly!",
    "Correct!"
] as const;

export const getRandomPraise = (): string => {
    const randomIndex = Math.floor(Math.random() * PRAISE_MESSAGES.length);
    return PRAISE_MESSAGES[randomIndex];
}; 