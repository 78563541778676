import { useDispatch, useSelector } from 'react-redux';
import { AnimationType } from '../../model/enum/enums';
import { closeComponentModal, openComponentModal, startClosingModal } from '../../store/slices/componentModalSlice';
import type { RootState } from '../../store/store';

export const useComponentModal = () => {
    const dispatch = useDispatch();
    const { isOpen, isClosing, componentKey, componentProps, animation } = useSelector(
        (state: RootState) => state.componentModal
    );

    const open = (componentKey: string, props?: Record<string, any>, animation: AnimationType = AnimationType.SlideRight) => {
        dispatch(openComponentModal({ componentKey, props, animation }));
    };

    const startClosing = () => {
        dispatch(startClosingModal());
    };

    const close = () => {
        dispatch(closeComponentModal());
    };

    return {
        isOpen,
        isClosing,
        componentKey,
        componentProps,
        animation,
        open,
        startClosing,
        close,
    };
};