import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStatus } from 'src/model/enum/enums';
import { Country } from '../../model/Country';
import { fetchCountries } from '../../store/slices/countriesSlice';
import { AppDispatch, RootState } from '../../store/store';

interface UseCountriesReturn {
    countries: Country[];
    status: LoadingStatus;
    error: string | null;
    fetchCountries: (forceRefresh?: boolean) => Promise<void>;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    getCountryByCode: (code?: string) => Country | undefined;
    getCountryByLanguage: (languageCode: string) => Country[];
    searchCountries: (query: string) => Country[];
}

export const useCountries = (autoLoad: boolean = true): UseCountriesReturn => {
    const dispatch = useDispatch<AppDispatch>();
    const initialLoadDone = useRef(false);

    const countries = useSelector((state: RootState) => state.countries.countries);
    const status = useSelector((state: RootState) => state.countries.status);
    const error = useSelector((state: RootState) => state.countries.error);

    const fetchData = useCallback(async (forceRefresh: boolean = false) => {
        if (initialLoadDone.current) return;

        try {
            await dispatch(fetchCountries({ forceRefresh })).unwrap();
            initialLoadDone.current = true;
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        if (autoLoad && status === LoadingStatus.IDLE && !countries.length && !initialLoadDone.current) {
            fetchData();
        }
    }, [autoLoad, status, fetchData, countries]);

    const getCountryByCode = useCallback((code: string = "GB") => {
        return countries.find(country =>
            country.CountryCode.toLowerCase() === code.toLowerCase()
        );
    }, [countries]);

    const getCountryByLanguage = useCallback((languageCode: string) => {
        return countries.filter(country =>
            country.LanguageCode.toLowerCase() === languageCode.toLowerCase()
        );
    }, [countries]);

    const searchCountries = useCallback((query: string) => {
        const lowercaseQuery = query.toLowerCase();
        return countries.filter(country =>
            country.EnglishName.toLowerCase().includes(lowercaseQuery) ||
            country.CountryCode.toLowerCase().includes(lowercaseQuery) ||
            country.LanguageCode.toLowerCase().includes(lowercaseQuery)
        );
    }, [countries]);

    return {
        countries,
        status,
        error,
        fetchCountries: fetchData,
        isLoading: status === LoadingStatus.LOADING,
        isError: status === LoadingStatus.FAILED,
        isSuccess: status === LoadingStatus.SUCCEEDED,
        getCountryByCode,
        getCountryByLanguage,
        searchCountries
    };
};