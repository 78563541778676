import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimationType } from '../../model/enum/enums';



interface ComponentModalState {
    isOpen: boolean;
    isClosing: boolean; // Add this
    componentKey: string | null;
    componentProps?: Record<string, any>;
    animation?: string;
}

const initialState: ComponentModalState = {
    isOpen: false,
    isClosing: false, // Add this
    componentKey: null,
    componentProps: undefined,
    animation: AnimationType.SlideRight,
};

const componentModalSlice = createSlice({
    name: 'componentModal',
    initialState,
    reducers: {
        openComponentModal: (state, action: PayloadAction<{
            componentKey: string;
            props?: Record<string, any>;
            animation?: AnimationType;
        }>) => {
            state.isOpen = true;
            state.isClosing = false;
            state.componentKey = action.payload.componentKey;
            state.componentProps = action.payload.props;
            state.animation = action.payload.animation || AnimationType.SlideRight;
        },
        startClosingModal: (state) => {
            state.isClosing = true;
        },
        closeComponentModal: (state) => {
            state.isOpen = false;
            state.isClosing = false;
            state.componentKey = null;
            state.componentProps = undefined;
        },
    },
});

export const { openComponentModal, closeComponentModal, startClosingModal } = componentModalSlice.actions;
export default componentModalSlice.reducer;