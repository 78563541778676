import classes from "./Spinner.module.scss";

const Spinner = ({ fullPage }: { fullPage?: boolean }) => {
    if (fullPage) return <div className={classes.fullPage} >
        <div className={classes.Loader} />
    </div>;
    return <div className={classes.Loader} />;
}

export default Spinner;
