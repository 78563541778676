import { useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { UserFields } from "src/model/enum/dataEnums";
import { Word } from "src/model/Word";
import { GetRazorSiteUrl } from "src/utils/URLUtils";
import { Actions, LearningArray } from '../../model/enum/enums';
import { Learning } from '../../model/Learning';
import { learningActions } from '../../store/slices/learningSlice';
import { appDispatch, RootState } from '../../store/store';
import useFetch from "../api/useFetch";
import useProgress from "./useProgress";
import useUser from "./useUser";

export const useLearning = (autoFetch: boolean = true) => {
    const { userInfo, } = useUser();
    const { saveProgress, updateUserInfoField } = useProgress();
    const { send } = useFetch<any>();
    const ac = bindActionCreators(learningActions, appDispatch);
    const { learningInfo } = useSelector((state: RootState) => state.learning);

    useEffect(() => {
        if (!learningInfo && autoFetch) {
            ac.getLearning();
        }
    }, [ac, learningInfo, autoFetch]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    const setLearningInfo = useCallback((info: Learning | null) => {
        ac.setLearning(info);
    }, [ac]);

    const updateLearningInfo = useCallback((info: Partial<Learning>) => {
        ac.updateLearning(info);
    }, [ac]);

    const addWord = useCallback((arrayName: LearningArray, wordId: number) => {
        if (learningInfo) {
            const updatedArray = Array.from(new Set([...learningInfo[arrayName], wordId])); // Add and ensure uniqueness
            updateLearningInfo({ [arrayName]: updatedArray });
        }
    }, [learningInfo, updateLearningInfo]);

    const removeWord = useCallback((arrayName: LearningArray, wordId: number) => {
        if (learningInfo) {
            const updatedArray = learningInfo[arrayName].filter(id => id !== wordId);
            updateLearningInfo({ [arrayName]: updatedArray });
        }
    }, [learningInfo, updateLearningInfo]);


    const fetchLearningData = useCallback(async (token?: string): Promise<Learning | null> => {
        const { response } = await send(GetRazorSiteUrl('learning', token ?? userInfo?.Token));
        if (!response) return null;
        setLearningInfo(response as Learning);
        return response as Learning;

    }, [userInfo?.Token, setLearningInfo, send]);

    const checkRange = useCallback((word: Word) => {
        const wordRange = word?.Rank <= 100 ? 1 : Math.floor((word?.Rank || 0) / 100) + 1;
        if (wordRange !== userInfo?.SelectedRange)
            updateUserInfoField(UserFields.SelectedRange, wordRange);

    }, [userInfo?.SelectedRange, updateUserInfoField]);

    const shouldLearn = useCallback((word: Word, updateRange?: boolean) => {
        if (updateRange)
            checkRange(word);

        removeWord(LearningArray.KnownWordIds, word.ID);
        addWord(LearningArray.UnknownWordIds, word.ID);
        addWord(LearningArray.ToLearn, word.ID);
        saveProgress({
            Action: Actions.Learn,
            Value: word.ID.toString()
        });


    }, [addWord, saveProgress, checkRange, removeWord]);

    const alreadeyKnew = useCallback((word: Word, updateRange?: boolean) => {
        if (updateRange)
            checkRange(word);
        addWord(LearningArray.KnownWordIds, word.ID);
        removeWord(LearningArray.UnknownWordIds, word.ID);
        removeWord(LearningArray.ToLearn, word.ID);
        saveProgress({
            Action: Actions.Knew,
            Value: word.ID.toString()
        });
    }, [addWord, removeWord, saveProgress, checkRange]);

    const remembered = useCallback((word: Word, updateRange?: boolean) => {
        removeWord(LearningArray.ToLearn, word.ID);
        saveProgress({
            Action: Actions.Remembered,
            Value: word.ID.toString()
        });

    }, [removeWord, saveProgress]);

    const forget = useCallback((word: Word, updateRange?: boolean) => {
        if (updateRange)
            checkRange(word);
        removeWord(LearningArray.KnownWordIds, word.ID);
        removeWord(LearningArray.UnknownWordIds, word.ID);
        removeWord(LearningArray.ToLearn, word.ID);
        saveProgress({
            Action: Actions.Forgot,
            Value: word.ID.toString()
        });
    }, [removeWord, saveProgress, checkRange]);



    return {
        learningInfo,
        setLearningInfo,
        updateLearningInfo,
        addWord,
        removeWord,
        fetchLearningData,
        handleLogout,
        shouldLearn,
        alreadeyKnew,
        forget,
        remembered
    };
};