import { useRef, useState } from 'react';
import { useWordList } from 'src/hook/data/useWordList';
import { useBackHandler } from 'src/hook/global/useBackHandler';
import { DiscoverView } from 'src/model/enum/enums';
import { WordList } from 'src/model/WordList';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './DiscoverPageHeader.module.scss';
import ViewMenu from './ViewMenu/ViewMenu';

interface DiscoverPageHeaderProps {
    onViewChange: (view: DiscoverView) => void;
    wordList: WordList;
    isFull: boolean;
}

export const DiscoverPageHeader = ({ onViewChange, wordList, isFull }: DiscoverPageHeaderProps) => {
    const handleBack = useBackHandler();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const viewMenuRef = useRef<HTMLDivElement>(null);
    const { updateWordListStats } = useWordList();

    const back = () => {
        updateWordListStats(wordList);
        handleBack('/wordlists');
    }
    return (
        <section className={styles.discoverPageHeader}>
            <img className={styles.backIcon} onClick={back}
                src={IconLocation('chevron-left')} alt='back' />
            <h1>Knowledge Map</h1>
            <div ref={viewMenuRef} className={styles.iconGroup} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <img className={styles.menuIcon}
                    src={IconLocation('cards_view')} alt='menu' />
                <img className={styles.downIcon}
                    src={IconLocation('chevron-down')} alt='down' />
            </div>
            <ViewMenu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={viewMenuRef.current}
                onViewChange={onViewChange}
                wordList={wordList}
                isFull={isFull}
            />
        </section>
    );
};
