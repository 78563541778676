import { FC, useEffect } from 'react';
import { useLearnWizard } from 'src/hook/components/useLearnWizard';
import { useSmartWord } from 'src/hook/global/useSmartWord';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import styles from './ThirdWizardTextContent.module.scss';

interface ThirdWizardTextContentProps {
    word: Word;
    wordData: WordData;
}

const ThirdWizardTextContent: FC<ThirdWizardTextContentProps> = ({ word, wordData }) => {
    const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);
    const { goToNext } = useLearnWizard();
    const { HighlightWord } = useSmartWord();

    useEffect(() => {
        if (!primarySense?.cl?.length || primarySense?.cl[0] === '') {
            goToNext();
        }
    }, [primarySense, goToNext]);

    // Return null if no cl data to prevent flash of empty content
    if (!primarySense?.cl?.length) return null;

    return (
        <div className={styles.textContentWrapper}>
            <div className={styles.textContent} >
                <div className={styles.textContentContainer}>
                    <h1 className={styles.clareTitle}>Often appears as</h1>
                    <div className={styles.clareItems}>
                        {primarySense?.cl.map((cl, index) =>
                            <div key={index} className={styles.clareItem}>
                                <div className={styles.rectangle}></div>
                                <div className={styles.clareDescription}>
                                    {HighlightWord(cl, word.Text)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThirdWizardTextContent;