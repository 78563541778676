export const enum LanguageLevel {
    Basic = "Basic",
    Beginner = "Beginner",
    Intermediate = "Intermediate",
    Advanced = "Advanced",
    Fluent = "Fluent",
    Native = "Native"
}

export const enum Http {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export const enum Period {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
}

export const enum CardBackgroundOption {
    VideoOrImage = "VideoOrImage",
    Image = "Image",
    Nothing = "Nothing",
}

export const enum Voice {
    gb = "gb",
    us = "us",
}

export const enum ContentType {
    Image = "Image",
    Video = "Video",
}

export const enum AnimationType {
    SlideRight = "slideRight",
    SlideUp = "slideUp",
    SlideDown = "slideDown",
    SlideLeft = "slideLeft",
    RotateRight = "rotateRight",
    RotateLeft = "rotateLeft",
    RotateIn = "rotateIn",
    RotateOut = "rotateOut",
}

export const enum MenuPosition {
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    TopLeft = "top-left",
    TopRight = "top-right",
}

export const PathName = {
    learn: "/learn",
    practice: "/practice",
    progress: "/progress",
    wordlists: "/wordlists",
    lessons: "/lessons",
}

export const enum ResponseType {
    Json = "json",
    Text = "text",
}

export const enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export const enum LearnWizardStep {
    First = "FirstWizard",
    Second = "SecondWizard",
    Third = "ThirdWizard",
    Final = "FinalWizard",
}

export enum LearningArray {
    KnownWordIds = "KnownWordIds",
    UnknownWordIds = "UnknownWordIds",
    ToLearn = "ToLearn",
}

export enum Actions {
    Learn = "learn",
    Knew = "knew",
    Remembered = "remembered",
    Forgot = "forgot",
    AddSeconds = "add-seconds",
    ScheduleReview = "schedule-review",

    LessonCompleted = "lesson-completed",
    LessonQuestionAdded = "lesson-question-add",
    LessonAnswerAdded = "lesson-answer-add",

    NewWordList = "new-word-list",
    RenameWordList = "rename-word-list",
    RemoveWordList = "remove-word-list",
    InsertWordToList = "insert-word-to-list",
    RemoveWordFromList = "remove-word-from-list",
    SeenWordList = "seen-word-list",



}

export enum Direction {
    Prev = 'prev',
    Next = 'next'
}

export enum WordListType {
    Custom = 'custom',
    Subject = 'subject',
    All = 'all'
}

export enum LoadingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}

export enum Examples {
    Quotes = "Quotes",
    News = "News",
    WordsOfWisdom = "WordsOfWisdom",
    InterestingFacts = "InterestingFacts",
    YouTube = "YouTube",
}

export enum LessonArrays {
    CompletedContext = "CompletedContext",
    Conversations = "Conversations",
    Answers = "Answers",
}

export enum DarkMode {
    Dark = "dark",
    Light = "light",
}

export enum NoteSQSType {
    Add = 0,
    Edit = 1,
    Delete = 2,
}

export enum DefinitionSource {
    Learn = "Learn",
    Search = "Search",
    Discover = "Discover",
}

export enum DiscoverView {
    Cards = "cards",
    Tags = "tags",
    List = "list",
}

export enum ChevronType {
    Left = "left",
    Right = "right",
    Up = "up",
    Down = "down",
}

export enum SortType {
    Alphabetic = "Alphabetic",
    HardestFirst = "Hardest First",
    EasiestFirst = "Easiest First",
}


