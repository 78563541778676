import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNote } from '../../hook/components/useNote';
import { useLearning } from '../../hook/data/useLearning';
import useProgress from '../../hook/data/useProgress';
import { useSocial } from '../../hook/data/useSocial';
import useUser from '../../hook/data/useUser';
import { GetStartPageUrl } from '../../utils/URLUtils';

export const LogOut = () => {
    const navigate = useNavigate();
    const { handleLogout: userLogout } = useUser();
    const { handleLogout: learningLogout } = useLearning();
    const { handleLogout: notesLogout } = useNote();
    const { handleLogout: socialLogout } = useSocial();
    const { uploadProgressAtomic } = useProgress();

    useEffect(() => {



        const performLogout = async () => {

            // Logout from all contexts
            await Promise.all([
                uploadProgressAtomic(),
                userLogout(),
                learningLogout(),
                notesLogout(),
                socialLogout(),
            ]);

            // Redirect to start page
            window.location.href = GetStartPageUrl();
        };

        performLogout();
    }, []);

    return null; // This component doesn't render anything
};

export default LogOut;
