import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PopUpState {
    isOpen: boolean;
    title: string;
    message: string;
    primaryButtonText?: string;
    primaryButtonClick?: () => void;
    secondaryButtonText?: string;
    secondaryButtonClick?: () => void;
    showSecondaryButton?: boolean;

}

const initialState: PopUpState = {
    isOpen: false,
    title: '',
    message: '',
    primaryButtonText: undefined,
    primaryButtonClick: undefined,
    secondaryButtonText: undefined,
    secondaryButtonClick: undefined,
    showSecondaryButton: false,
};


const popUpSlice = createSlice({
    name: 'popUp',
    initialState,
    reducers: {
        showPopUp: (state, action: PayloadAction<Omit<PopUpState, 'isOpen'>>) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.primaryButtonText = action.payload.primaryButtonText;
            state.primaryButtonClick = action.payload.primaryButtonClick;
            state.secondaryButtonText = action.payload.secondaryButtonText;
            state.secondaryButtonClick = action.payload.secondaryButtonClick;
            state.showSecondaryButton = action.payload.showSecondaryButton;
        },
        hidePopUp: (state) => {
            state.isOpen = false;
        },

    },
});

export const { showPopUp, hidePopUp } = popUpSlice.actions;
export default popUpSlice.reducer;
