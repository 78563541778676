import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageHeader } from 'src/components/ui/GlobalComponents/PageHeader/PageHeader';
import { useLearning } from 'src/hook/data/useLearning';
import { WordList } from 'src/model/WordList';
import styles from './ImportWords.module.scss';
import { ImportWordsForm } from './ImportWordsForm/ImportWordsForm';


export const ImportWords = () => {
    const [searchParams] = useSearchParams();
    const listId = searchParams.get('id');
    const { learningInfo } = useLearning();
    const [currentList, setCurrentList] = useState<WordList | undefined>(undefined);



    useEffect(() => {
        if (listId) {
            const list = learningInfo?.WordLists
                .find(list => list.ID === listId);
            setCurrentList(list);
        }
    }, [listId, learningInfo]);


    return (
        <section className={`fullPage ${styles.importWords}`}>
            <PageHeader
                title='Import Words'
                backUrl={`/wordlists/wordlist?id=${listId}`} />
            <ImportWordsForm currentList={currentList} />
        </section>



    );
};
